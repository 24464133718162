const BUTTON_VARIANTS = {
	filled: 'filled',
	outlined: 'outlined',
	text: 'text',
	icon: 'icon',
} as const;
export type ButtonVariantsType = keyof typeof BUTTON_VARIANTS;

const COLOR_MAP = {
	primary: 'primary',
	grey: 'grey',
	secondary: 'secondary',
	info: 'info',
	error: 'error',
	success: 'success',
	warning: 'warning',
} as const;

export type ColorMapType = keyof typeof COLOR_MAP;

const BUTTON_SIZES = {
	sm: 'sm',
	md: 'md',
	lg: 'lg',
	// xl: 'xl',
	// '2xl': '2xl',
} as const;

const ICON_BUTTON_SIZES = {
	sm: 'sm',
	md: 'md',
	lg: 'lg',
	xl: 'xl',
	'2xl': '2xl',
} as const;

const MODAL_SIZES = {
	sm: 'sm',
	md: 'md',
	lg: 'lg',
} as const;

export type ButtonSizeType = keyof typeof BUTTON_SIZES;
export type IconButtonSizeType = keyof typeof ICON_BUTTON_SIZES;

export {
	BUTTON_VARIANTS,
	COLOR_MAP,
	BUTTON_SIZES,
	ICON_BUTTON_SIZES,
	MODAL_SIZES,
};
