import { endpoints } from 'constants/endpoints';
import { useState } from 'react';
import { addQueryParams, orgAxios } from 'utils/axios';

export function useENS() {
	const [isResolvingEns, setIsResolvingEns] = useState(false);

	const resolveENS = async (address: string) => {
		try {
			setIsResolvingEns(true);
			const { data } = await orgAxios().get(
				addQueryParams(endpoints.users.ens, {
					ens: address,
				})
			);
			return data.data.address;
		} catch (error) {
			throw new Error('Invalid ENS domain');
		} finally {
			setIsResolvingEns(false);
		}
	};

	return { isResolvingEns, resolveENS };
}
