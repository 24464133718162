import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tippy/dist/tippy.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { createGlobalStyle } from 'styled-components';
import { spacings } from './constants/spacings';
import { theme } from './theme';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    font-family: ${theme.fonts.primary};
  }

  #root {
    margin:0 auto;
  }

  body {
    overflow-x: hidden;
  }

  li {
    padding: ${spacings[6]} 0;
  }

  ul {
    padding: 0 ${spacings[20]};
  }
  
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  
  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  textarea {
    resize: none;
  }
`;

export default GlobalStyle;
