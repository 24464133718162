import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import { rem } from 'styles';
import {
	ButtonSizeType,
	ButtonVariantsType,
	BUTTON_SIZES,
	BUTTON_VARIANTS,
	ColorMapType,
	IconButtonSizeType,
	ICON_BUTTON_SIZES,
} from 'styles/tokens';
import { ButtonBaseProps } from './ButtonBase';

const spin = keyframes`
	from {
		transform: rotate(0deg);
	} 
	to {
		transform: rotate(360deg);
	}
`;

function getButtonStyle(
	theme: DefaultTheme,
	color: ColorMapType,
	variant?: ButtonVariantsType,
	disabled?: boolean,
	size: ButtonSizeType | IconButtonSizeType = BUTTON_SIZES.md
) {
	const { spacings, colors, shadows, fontSizes, fontWeights } = theme;

	const getButtonStyle = (size: ButtonSizeType) => {
		switch (size) {
			case BUTTON_SIZES.sm:
				return css({
					height: spacings[30],
					fontSize: fontSizes.sm,
					fontWeight: fontWeights.medium,
				});
			case BUTTON_SIZES.md:
				return css({
					height: spacings[40],
					fontSize: fontSizes.md,
					fontWeight: fontWeights.semibold,
				});
			case BUTTON_SIZES.lg:
			default:
				return css({
					height: spacings[50],
					fontSize: fontSizes.md,
					fontWeight: fontWeights.semibold,
				});
		}
	};
	const getIconButtonIconSize = (size: IconButtonSizeType) => {
		switch (size) {
			case ICON_BUTTON_SIZES.sm:
				return spacings[14];
			case ICON_BUTTON_SIZES.md:
				return spacings[16];
			case ICON_BUTTON_SIZES.lg:
				return spacings[18];
			case ICON_BUTTON_SIZES.xl:
				return spacings[20];
			default:
				return spacings[20];
		}
	};

	//TODO: change pading based on size
	const style = css`
		padding: ${rem(15)} ${spacings[20]} ${spacings[16]};
		box-sizing: border-box;
		transition: all 0.1s ease-in;
		min-width: ${size === BUTTON_SIZES.md || size === BUTTON_SIZES.sm
			? 'auto'
			: spacings[160]};
		border: ${spacings[1]} solid
			${disabled ? colors.grey[500] : colors[color][500]};
		${getButtonStyle(size as ButtonSizeType)};
	`;

	switch (variant) {
		case BUTTON_VARIANTS.filled:
			return css`
				${style}
				color:${colors.white[100]};
				background-color: ${disabled ? colors.grey[500] : colors[color][500]};
				${!disabled &&
				css`
					box-shadow: ${shadows[color][100]};
					&:hover,
					&:focus {
						background-color: ${colors[color][900]};
						box-shadow: none;
					}
				`};
			`;

		case BUTTON_VARIANTS.outlined:
			return css`
				${style}
				color:  ${disabled ? colors.grey[500] : colors[color][500]};
				background-color: ${disabled ? colors.white[100] : colors.white[100]};
				border: ${spacings[1]} solid
					${disabled ? colors.grey[500] : colors[color][500]};
			`;

		case BUTTON_VARIANTS.text:
			return css`
				${style}
				border: none;
				padding: 0;
				min-width: auto;
				height: auto;
				color: ${disabled ? colors.grey[100] : colors[color][500]};
				text-transform: none;
			`;
		case BUTTON_VARIANTS.icon: {
			const _size = getIconButtonIconSize(size);
			return css`
				${style}
				padding: 0;
				min-width: auto;
				border: ${spacings[1]} solid transparent;
				width: ${_size};
				height: ${_size};
				color: ${disabled ? colors.grey[500] : colors[color][500]};
				&:hover {
					opacity: 0.8;
				}
			`;
		}
		default:
			return style;
	}
}

const IconContainer = styled.span`
	width: ${({ theme }) => theme.spacings[20]};
	height: ${({ theme }) => theme.spacings[20]};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: ${({ theme }) => theme.spacings[4]};
`;

export const LoadingIconContainer = styled(IconContainer)``;

const StyledButtonBase = styled.button.withConfig({
	shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})<ButtonBaseProps>(
	({
		theme,
		disabled = false,
		variant,
		color = 'primary',
		size = BUTTON_SIZES.md,
		isLoading,
	}) => {
		const { spacings, fontSizes } = theme;
		return css`
			background-color: transparent;
			border-radius: ${spacings[4]};
			border: 0;
			font-size: ${fontSizes.md};
			font-weight: 600;
			min-width: ${spacings[160]};
			outline: none;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			${getButtonStyle(theme, color, variant, disabled, size)}
			&:hover {
				cursor: ${disabled ? 'not-allowed' : 'pointer'};
			}

			${isLoading &&
			css`
				${LoadingIconContainer} {
					animation-name: ${spin};
					animation-duration: 1000ms;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
				}
			`};
		`;
	}
);

export { StyledButtonBase, IconContainer };
