import Big from 'big.js';
import { formatRelative } from 'date-fns';

export function getInitials(name: string | undefined, initialsLength: number) {
	let initials = '';
	if (name) {
		const parts = name
			.replaceAll(/[^a-zA-Z0-9\w\s]+/g, ' ')
			.trim()
			.split(' ');
		initials += parts[0].charAt(0);
		if (parts.length > 1 && initialsLength > 1)
			initials += parts[parts.length - 1].charAt(0);
	}
	return initials;
}

export function minifyAddress(address: string) {
	if (!address) return '';

	return `${address.substring(0, 6)}...${address.substring(
		address.length - 4
	)}`;
}

export function getFormattedAddress(address: string, short?: boolean) {
	if (short) return minifyAddress(address);
	return address;
}

export function shortenText(text: string, maxLength: number) {
	if (!text) return '';
	if (text.length > maxLength) return `${text.substring(0, maxLength)}...`;
	return text;
}

export function capitalizeFirstLetter(text?: string) {
	if (!text) return '';
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function formatBigNumberToString(
	value: string | number,
	decimals = 18,
	decimalsToFixed = 6
) {
	if (value != 0 && !value) return '0';
	try {
		const bigValue = new Big(value);
		return bigValue
			.div(Math.pow(10, decimals))
			.toFixed(decimalsToFixed)
			.toString();
	} catch (err) {
		console.error(err);
		return '0';
	}
}

export function formatNumber(
	value: string | number,
	decimals = 2,
	fixed = false
) {
	if (value != 0 && !value) return '0';
	try {
		let formattedNumber;

		if (fixed) formattedNumber = Big(value).toFixed(decimals);
		else formattedNumber = Big(value).round(decimals);

		formattedNumber = formattedNumber
			.toString()
			.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
		return formattedNumber;
	} catch (err) {
		console.error(err);
		return '0';
	}
}
export function generateRandomColor(opacity?: string) {
	const letters = '0123456789ABCDEF';
	let color = '#';
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return `${color}${opacity || ''}`;
}

export function getRelativeTime(timestamp: string) {
	try {
		return formatRelative(new Date(timestamp), new Date());
	} catch (e) {
		console.warn('error parsing timestamp');
	}
	return '';
}

export function singularOrPluralText(
	text: string,
	length: number,
	es?: boolean
) {
	if (length === 1) return text;
	return `${text}${es ? 'es' : 's'}`;
}
