import { useEffect } from 'react';
import { BeamerCallbackType } from 'types/beamer.types';
import { loadBeamer, unloadBeamer } from 'utils/beamer';

const useBeamer = (callback: BeamerCallbackType) => {
	useEffect(() => {
		loadBeamer(callback);

		return () => {
			unloadBeamer();
		};
	}, [callback]);
};

export default useBeamer;
