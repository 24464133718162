import { NotificationIntegrationProviderEnum } from 'screens/organisations/dashboard/settings/notifications/NotificationSettings.types';

export const BE_URL_V1 = `${process.env.NEXT_PUBLIC_ROOT_BE_URL}/api/V1`;
export const MOCK_V1_URL = `${process.env.NEXT_PUBLIC_POSTMAN_MOCK_API_URL}/api/V1`;

export const getSocketUrl = () => {
	const socketUrl = process.env.NEXT_PUBLIC_SOCKET_URL;

	if (!socketUrl) {
		throw new Error('socket url is not defined');
	}

	return socketUrl;
};

function url(endpoint: string, mock?: boolean) {
	return `${mock ? MOCK_V1_URL : BE_URL_V1}${endpoint}`;
}

export const endpoints = {
	stats: {
		get: url('/stats'),
	},
	waitlist: {
		get: url('/whitelist'),
		join: url('/whitelist'),
	},
	users: {
		ens: url('/users/ens/address'),
		bulkEns: url('/users/ens/bulk-resolver'),
		generateSigninKey: url('/users/generate/signin-key'),
		login: url('/users/login'),
		register: url('/users/register'),
		safeDetails: url('/users/safes/details'),
		safes: url('/users/safes'),
		safesBalance: url('/users/safes/balance'),
		validateSafe: url('/users/safes/addresses/validate'),
		verify: url('/users/verify'),
		update: url('/users'),
		exist: url('/users/organisations/exist'),
	},
	userManagement: {
		list: url('/organisations/users'),
		sendInvite: url('/users/organisations/invitation/send'),
		confirmInvite: (slug: string) =>
			url(`/users/organisations/${slug}/invitation/confirmation`),
		addUser: url('/users/user_organisation'),
		editRole: url('/users/organisations/roles'),
		deactivateUser: url('/users/organisation/deactivate'),
	},
	organisations: {
		accounts: {
			import: url('/users/organisations/accounts/import'),
			calldata: url('/users/organisations/accounts/calldata-new'),
			create_invite: url('/users/organisations/accounts/create-invite'),
			import_invite: url('/users/organisations/accounts/import-invite'),
			get: url('/organisations/accounts'),
		},
		balance: url('/organisations/balance'),
		nfts: url('/organisations/balance/nft'),
		create: url('/users/organisations'),
		delete: url('/organisations'),
		directionBalance: url('/organisations/direction/balance'),
		get: url('/users/organisations'),
		getBySlug: (slug: string) => url(`/users/organisations/${slug}/details`),
		transactions: {
			get: url('/users/organisations/transactions'),
			filters: url('/transactions/export/filter'),
			export: url('/transactions/export'),
		},
		update: url('/organisations'),
		syncTransactions: url('/organisations/transaction/history'),
	},
	contacts: {
		createBulk: url('/contacts/bulk'),
		createSingle: url('/contacts'),
		delete: (contactID: string) => url(`/contacts/${contactID}`),
		get: url('/contacts'),
		update: (contactID: string) => url(`/contacts/${contactID}`),
	},
	tags: {
		assign: url('/tags/entity/assign'),
		create: url('/tags/bulk'),
		createSingle: url('/tags'),
		get: url('/tags'),
		list: url('/tags/list'),
		remove: url('/tags/entity/remove'),
		patch: (tagId: string) => url(`/tags/${tagId}`),
	},
	automatedPayments: {
		streams: {
			get: url('/users/organisations/accounts/streams'),
			validate: url('/transactions/validate/streams'),
		},
	},
	transactions: {
		captureUserActionByTxnId: (txnId: string) =>
			url(`/transactions/${txnId}/user/actions`),
		getTransactionById: (txnId: string) => url(`/transactions/${txnId}`),
		updateTxnNotes: (txnId: string) => url(`/transactions/${txnId}/notes`),
		updateTxnLabels: (txnId: string) => url(`/transactions/${txnId}/tags`),
		proposalConfirmation: (txnId: string) =>
			url(`/transactions/${txnId}/proposal/confirmation`),
		safe: url('/transactions/safe'),
		root: url('/transactions'),
		executable: (txnId: string) => url(`/transactions/${txnId}/executable`),
		decode: url(`/transactions/decode`),
	},
	networkFee: {
		get: url('/networkFee'),
	},
	organisationAccount: {
		put: url('/organisationAccount'),
		unlink: url('/organisations/accounts/unlink'),
	},
	notificationSettings: {
		listOrganisationConnectionPreferences: url(
			'/postcard/channel/organisation'
		),
		listAccountConnectionPreferences: url('/postcard/channel/accounts'),
		getNewConnectionUrl: url(
			'/postcard/onboarding/bot-authorization/request-url'
		),
		approveRequestRedirection: url(
			'/postcard/onboarding/bot-authorization/approve-coinshift-bot'
		),
		orgDisconnectConnection: (provider: NotificationIntegrationProviderEnum) =>
			url(`/postcard/channel/organisation/${provider}`),
		accountDisconnectConnection: (
			provider: NotificationIntegrationProviderEnum,
			accountId: string
		) => url(`/postcard/channel/accounts/${accountId}/${provider}`),
	},
	notifications: {
		get: url('/notifications'),
		markSingleRead: (id: string) => url(`/notifications/${id}/read`),
		markAllAsRead: url('/notifications/mark-all-as-read'),
	},
	tokens: {
		validate: url('/tokens/validate'),
		addToken: url('/tokens/organizationToken'),
		superTokens: (networkId: string | number) =>
			url(`/tokens/superTokens/${networkId}/underlyingTokens`),
	},
	migration: {
		eligible: url('/migration/eligible'),
		sync: url('/migration/sync'),
	},
	reporting: {
		portfolioHistory: {
			root: url('/reporting/portfolio-history'),
			list: url('/reporting/portfolio-history/list'),
			byId: (jobId: string) => url(`/reporting/portfolio-history/${jobId}`),
		},
		cashflow: {
			get: url('/transactions/cashflow'),
		},
	},
	enrichment: {
		decodeEnrich: url('/enrichment/decode-enrich'),
		decode: url('/enrichment/decode'),
	},
};
