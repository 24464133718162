import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { CashflowAPIDataType } from './CashFlow.types';
import { getToAndFromInternalAccount } from './CashFlow.utils';
import { UNTAGGED_TXS } from './sections/CashFlowSections.utils';

export type FilterAssetOption = {
	symbol: string;
	address: string;
	networkId: number;
	logoUrl: string;
};

export type CashFlowFiltersFormData = {
	assets: FilterAssetOption[] | null;
	labels: FilterLabelOption[] | null;
	showInternalTransfers: boolean;
	showExternalTransfers: boolean;
	showContractInteractions: boolean;
};
export type FilterLabelOption = {
	label: string;
	value: string;
};

type DateRange = { startDate?: number; endDate?: number; key?: string };

export type CashFlowState = {
	hasAppliedFilters: boolean;
	filteredCashFlowData?: CashflowAPIDataType[];
	dateRangeFilter?: DateRange[];
	appliedFiltersData?: CashFlowFiltersFormData;
};

export const initialState: CashFlowState = {
	hasAppliedFilters: false,
	filteredCashFlowData: undefined,
	dateRangeFilter: undefined,
	appliedFiltersData: undefined,
};

const slice = createSlice({
	name: 'reporting/cash-flow',
	initialState,
	reducers: {
		setFilters(
			state,
			action: PayloadAction<{
				formData: CashFlowFiltersFormData;
				ranges?: DateRange[];
				cashFlowData: CashflowAPIDataType[];
				accounts?: string[];
			}>
		) {
			state.appliedFiltersData = action.payload.formData;
			state.dateRangeFilter = action.payload.ranges;

			state.hasAppliedFilters = true;
			const { cashFlowData, formData, accounts } = action.payload;
			const {
				labels,
				assets,
				showExternalTransfers,
				showInternalTransfers,
				showContractInteractions,
			} = formData;

			// filter by date range
			let dateRangeFilteredData = cashFlowData;
			if (action.payload.ranges) {
				const { startDate, endDate } = action.payload.ranges[0];
				dateRangeFilteredData = cashFlowData.filter((item) => {
					if (item.timestamp && startDate && endDate) {
						return (
							new Date(item.timestamp).getTime() >= startDate &&
							new Date(item.timestamp).getTime() <= endDate
						);
					}
					return item;
				});
			}

			// filter out internal transfers
			if (!showInternalTransfers) {
				dateRangeFilteredData = dateRangeFilteredData.filter((item) => {
					const { isFromInternalAccount, isToInternalAccount } =
						getToAndFromInternalAccount(item, accounts);

					if (
						isFromInternalAccount &&
						isToInternalAccount &&
						isFromInternalAccount !== isToInternalAccount
					)
						return false;

					return item;
				});
			}

			// filter out external transfers
			if (!showExternalTransfers) {
				dateRangeFilteredData = dateRangeFilteredData.filter((item) => {
					const { isFromInternalAccount, isToInternalAccount } =
						getToAndFromInternalAccount(item, accounts);

					if (
						(isFromInternalAccount && !isToInternalAccount) ||
						(isToInternalAccount && !isFromInternalAccount)
					)
						return false;

					return item;
				});
			}

			// filter out contract interactions
			if (!showContractInteractions) {
				dateRangeFilteredData = dateRangeFilteredData.filter((item) => {
					const { isFromInternalAccount, isToInternalAccount } =
						getToAndFromInternalAccount(item, accounts);

					if (
						isFromInternalAccount &&
						isToInternalAccount &&
						isFromInternalAccount === isToInternalAccount
					)
						return false;

					return item;
				});
			}

			// filter by labels, and only keep the filtered txnLabels in the array
			const labelsFilteredData = dateRangeFilteredData
				.filter((item) => {
					if (labels?.length) {
						// case where untagged txs is selected
						if (
							labels.some(({ value }) => value === UNTAGGED_TXS) &&
							item.txnLabels.length === 0
						)
							return item;
						// case where labels are selected
						return item.txnLabels.some((label) =>
							labels.some((l) => l.value === label.id)
						);
					}
					return item;
				})
				.map(({ txnLabels, ...rest }) => ({
					txnLabels: txnLabels.filter((label) => {
						if (labels?.length) {
							return labels.find((l) => l.value === label.id);
						}
						return label;
					}),
					...rest,
				}));

			// filter by assets
			const finalFilteredData = labelsFilteredData.filter((item) => {
				if (assets?.length && item.tokenAddress) {
					return assets.some(
						({ address, networkId }) =>
							`${networkId}_${address.toLowerCase()}` ===
							`${item.networkId}_${item.tokenAddress.toLowerCase()}`
					);
				}
				return item;
			});

			state.filteredCashFlowData = finalFilteredData;
		},
		reset() {
			return initialState;
		},
	},
});

export const { actions: cashFlowActions, reducer: cashFlowReducer } = slice;

export const selectCashFlow = (state: AppState) => state.cashFlow;

export const selectCashFlowDateRangeFilter = (state: AppState) =>
	state.cashFlow.dateRangeFilter
		? state.cashFlow.dateRangeFilter.map(({ startDate, endDate, key }) => ({
				startDate: startDate ? new Date(startDate) : undefined,
				endDate: endDate ? new Date(endDate) : undefined,
				key,
		  }))
		: [
				{
					startDate: undefined,
					endDate: new Date(),
					key: 'selection',
				},
		  ];
