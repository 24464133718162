const testnetIds = {
	GOERLI: '5',
};

export const networkIds = {
	ETHEREUM_MAINNET: '1',
	OPTIMISM: '10',
	BSC: '56',
	GNOSIS_CHAIN: '100',
	POLYGON: '137',
	ARBITRUM: '42161',
	AVALANCHE: '43114',
	BASE: '8453',
	POLYGON_ZK: '1101',
	CELO: '42220',
	...(process.env.NEXT_PUBLIC_ENABLE_TESTNET === 'true' ? testnetIds : {}),
};

export const streamingSupportedNetworks = [
	networkIds.POLYGON,
	...(process.env.NEXT_PUBLIC_ENABLE_TESTNET === 'true'
		? [networkIds.GOERLI]
		: []),
];

export const networkNameById = {
	[networkIds.ETHEREUM_MAINNET]: 'Ethereum',
	[networkIds.OPTIMISM]: 'Optimism',
	[networkIds.BSC]: 'BNB Chain',
	[networkIds.GNOSIS_CHAIN]: 'Gnosis Chain',
	[networkIds.POLYGON]: 'Polygon',
	[networkIds.ARBITRUM]: ' Arbitrum',
	[networkIds.AVALANCHE]: ' Avalanche',
	[networkIds.BASE]: 'Base',
	[networkIds.POLYGON_ZK]: 'Polygon zkEVM',
	[networkIds.CELO]: 'Celo',
	...(process.env.NEXT_PUBLIC_ENABLE_TESTNET === 'true'
		? { [testnetIds.GOERLI]: 'Görli' }
		: {}),
};

export const networkIdToIconSrc = {
	[networkIds.ETHEREUM_MAINNET]: '/assets/networks/ethereum.svg',
	[testnetIds.GOERLI]: '/assets/networks/ethereum.svg',
	[networkIds.OPTIMISM]: '/assets/networks/optimism.svg',
	[networkIds.BSC]: '/assets/networks/bsc.svg',
	[networkIds.GNOSIS_CHAIN]: '/assets/networks/gnosischain.svg',
	[networkIds.POLYGON]: ' /assets/networks/polygon.svg',
	[networkIds.ARBITRUM]: ' /assets/networks/arbitrum.svg',
	[networkIds.AVALANCHE]: ' /assets/networks/avalanche.svg',
	[networkIds.BASE]: ' /assets/networks/base.svg',
	[networkIds.POLYGON_ZK]: ' /assets/networks/polygon_zkevm.svg',
	[networkIds.CELO]: ' /assets/networks/celo.svg',
};

export const networkOptions = Object.entries(networkNameById).map(
	([value, label]) => ({ label, value })
);

const INFURA_API_KEY = process.env.NEXT_PUBLIC_INFURA_API_KEY;
const ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY;

// source: https://chainid.network/chains.json;
export const NETWORK_DETAILS_BY_ID = {
	[networkIds.ETHEREUM_MAINNET]: {
		name: 'Ethereum',
		chain: 'ETH',
		icon: 'ethereum',
		rpc: [
			`https://mainnet.infura.io/v3/${INFURA_API_KEY}`,
			`wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}`,
			'https://api.mycryptoapi.com/eth',
			'https://cloudflare-eth.com',
		],
		faucets: [],
		nativeCurrency: {
			name: 'Ether',
			symbol: 'ETH',
			decimals: 18,
		},
		infoURL: 'https://ethereum.org',
		shortName: 'eth',
		chainId: 1,
		networkId: 1,
		slip44: 60,
		ens: {
			registry: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
		},
		explorers: [
			{
				name: 'etherscan',
				url: 'https://etherscan.io',
				standard: 'EIP3091',
			},
		],
	},
	[testnetIds.GOERLI]: {
		name: 'Görli',
		title: 'Ethereum Testnet Görli',
		chain: 'ETH',
		rpc: [
			`https://goerli.infura.io/v3/${INFURA_API_KEY}`,
			`wss://goerli.infura.io/v3/${INFURA_API_KEY}`,
			'https://rpc.goerli.mudgit.blog/',
		],
		faucets: [
			'http://fauceth.komputing.org?chain=5&address=${ADDRESS}',
			'https://goerli-faucet.slock.it?address=${ADDRESS}',
			'https://faucet.goerli.mudit.blog',
		],
		nativeCurrency: {
			name: 'Görli Ether',
			symbol: 'ETH',
			decimals: 18,
		},
		infoURL: 'https://goerli.net/#about',
		shortName: 'gor',
		chainId: 5,
		networkId: 5,
		ens: {
			registry: '0x112234455c3a32fd11230c42e7bccd4a84e02010',
		},
		explorers: [
			{
				name: 'etherscan-goerli',
				url: 'https://goerli.etherscan.io',
				standard: 'EIP3091',
			},
		],
	},
	[networkIds.OPTIMISM]: {
		name: 'Optimism',
		chain: 'ETH',
		rpc: ['https://mainnet.optimism.io/'],
		faucets: [],
		nativeCurrency: {
			name: 'Ether',
			symbol: 'ETH',
			decimals: 18,
		},
		infoURL: 'https://optimism.io',
		shortName: 'oeth',
		chainId: 10,
		networkId: 10,
		explorers: [
			{
				name: 'etherscan',
				url: 'https://optimistic.etherscan.io',
				standard: 'none',
			},
		],
	},
	[networkIds.BSC]: {
		name: 'BNB Chain',
		chain: 'BSC',
		rpc: [
			'https://bsc-dataseed1.binance.org',
			'https://bsc-dataseed2.binance.org',
			'https://bsc-dataseed3.binance.org',
			'https://bsc-dataseed4.binance.org',
			'https://bsc-dataseed1.defibit.io',
			'https://bsc-dataseed2.defibit.io',
			'https://bsc-dataseed3.defibit.io',
			'https://bsc-dataseed4.defibit.io',
			'https://bsc-dataseed1.ninicoin.io',
			'https://bsc-dataseed2.ninicoin.io',
			'https://bsc-dataseed3.ninicoin.io',
			'https://bsc-dataseed4.ninicoin.io',
			'wss://bsc-ws-node.nariox.org',
		],
		faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
		nativeCurrency: {
			name: 'Binance Chain Native Token',
			symbol: 'BNB',
			decimals: 18,
		},
		infoURL: 'https://www.binance.org',
		shortName: 'bnb',
		chainId: 56,
		networkId: 56,
		slip44: 714,
		explorers: [
			{
				name: 'bscscan',
				url: 'https://bscscan.com',
				standard: 'EIP3091',
			},
		],
	},
	[networkIds.GNOSIS_CHAIN]: {
		name: 'Gnosis Chain',
		chain: 'Gnosis',
		icon: 'gnosis',
		rpc: [
			'https://rpc.gnosischain.com',
			'https://xdai.poanetwork.dev',
			'wss://rpc.gnosischain.com/wss',
			'wss://xdai.poanetwork.dev/wss',
			'http://xdai.poanetwork.dev',
			'https://dai.poa.network',
			'ws://xdai.poanetwork.dev:8546',
		],
		faucets: [
			'https://faucet.gimlu.com/gnosis',
			'https://stakely.io/faucet/gnosis-chain-xdai',
			'https://faucet.prussia.dev/xdai',
		],
		nativeCurrency: {
			name: 'xDAI',
			symbol: 'xDAI',
			decimals: 18,
		},
		infoURL: 'https://www.xdaichain.com/',
		shortName: 'gno',
		chainId: 100,
		networkId: 100,
		slip44: 700,
		explorers: [
			{
				name: 'gnosisscan',
				url: 'https://gnosisscan.io',
				standard: 'EIP3091',
			},
			{
				name: 'blockscout',
				url: 'https://blockscout.com/xdai/mainnet',
				icon: 'blockscout',
				standard: 'EIP3091',
			},
		],
	},
	[networkIds.POLYGON]: {
		name: 'Polygon Mainnet',
		chain: 'Polygon',
		rpc: [
			'https://polygon-rpc.com/',
			'https://rpc-mainnet.matic.network',
			'https://matic-mainnet.chainstacklabs.com',
			'https://rpc-mainnet.maticvigil.com',
			'https://rpc-mainnet.matic.quiknode.pro',
			'https://matic-mainnet-full-rpc.bwarelabs.com',
		],
		faucets: [],
		nativeCurrency: {
			name: 'MATIC',
			symbol: 'MATIC',
			decimals: 18,
		},
		infoURL: 'https://polygon.technology/',
		shortName: 'matic',
		chainId: 137,
		networkId: 137,
		slip44: 966,
		explorers: [
			{
				name: 'polygonscan',
				url: 'https://polygonscan.com',
				standard: 'EIP3091',
			},
		],
	},
	[networkIds.ARBITRUM]: {
		name: 'Arbitrum One',
		chainId: 42161,
		shortName: 'arb1',
		chain: 'ETH',
		networkId: 42161,
		nativeCurrency: {
			name: 'Ether',
			symbol: 'ETH',
			decimals: 18,
		},
		rpc: [
			`https://arbitrum-mainnet.infura.io/v3/${INFURA_API_KEY}`,
			`https://arb-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
			'https://arb1.arbitrum.io/rpc',
			'wss://arb1.arbitrum.io/ws',
		],
		faucets: [],
		explorers: [
			{
				name: 'Arbiscan',
				url: 'https://arbiscan.io',
				standard: 'EIP3091',
			},
			{
				name: 'Arbitrum Explorer',
				url: 'https://explorer.arbitrum.io',
				standard: 'EIP3091',
			},
		],
		infoURL: 'https://arbitrum.io',
		parent: {
			type: 'L2',
			chain: 'eip155-1',
			bridges: [
				{
					url: 'https://bridge.arbitrum.io',
				},
			],
		},
	},
	[networkIds.AVALANCHE]: {
		name: 'Avalanche C-Chain',
		chain: 'AVAX',
		rpc: ['https://api.avax.network/ext/bc/C/rpc'],
		faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
		nativeCurrency: {
			name: 'Avalanche',
			symbol: 'AVAX',
			decimals: 18,
		},
		infoURL: 'https://www.avax.network/',
		shortName: 'avax',
		chainId: 43114,
		networkId: 43114,
		slip44: 9005,
		explorers: [
			{
				name: 'snowtrace',
				url: 'https://snowtrace.io',
				standard: 'EIP3091',
			},
		],
	},
	[networkIds.BASE]: {
		name: 'Base',
		chain: 'ETH',
		rpc: [
			'https://mainnet.base.org/',
			'https://developer-access-mainnet.base.org/',
			'https://base.gateway.tenderly.co',
			'wss://base.gateway.tenderly.co',
			'https://base.publicnode.com',
			'wss://base.publicnode.com',
		],
		faucets: [],
		nativeCurrency: {
			name: 'Ether',
			symbol: 'ETH',
			decimals: 18,
		},
		infoURL: 'https://base.org',
		shortName: 'base',
		chainId: 8453,
		networkId: 8453,
		icon: 'base',
		explorers: [
			{
				name: 'basescan',
				url: 'https://basescan.org',
				standard: 'none',
			},
			{
				name: 'basescout',
				url: 'https://base.blockscout.com',
				icon: 'blockscout',
				standard: 'EIP3091',
			},
		],
		status: 'active',
	},
	[networkIds.POLYGON_ZK]: {
		name: 'Polygon zkEVM',
		title: 'Polygon zkEVM',
		chain: 'Polygon',
		rpc: ['https://zkevm-rpc.com'],
		faucets: [],
		nativeCurrency: {
			name: 'Ether',
			symbol: 'ETH',
			decimals: 18,
		},
		infoURL: 'https://polygon.technology/polygon-zkevm',
		shortName: 'zkevm',
		chainId: 1101,
		networkId: 1101,
		icon: 'zkevm',
		explorers: [
			{
				name: 'polygonscan',
				url: 'https://zkevm.polygonscan.com',
				icon: 'zkevm',
				standard: 'EIP3091',
			},
		],
		parent: {
			type: 'L2',
			chain: 'eip155-1',
			bridges: [
				{
					url: 'https://bridge.zkevm-rpc.com',
				},
			],
		},
	},
	[networkIds.CELO]: {
		name: 'Celo Mainnet',
		chainId: 42220,
		shortName: 'celo',
		chain: 'CELO',
		networkId: 42220,
		nativeCurrency: {
			name: 'CELO',
			symbol: 'CELO',
			decimals: 18,
		},
		rpc: ['https://forno.celo.org', 'wss://forno.celo.org/ws'],
		faucets: [],
		infoURL: 'https://docs.celo.org/',
		explorers: [
			{
				name: 'Celoscan',
				url: 'https://celoscan.io',
				standard: 'EIP3091',
			},
			{
				name: 'blockscout',
				url: 'https://explorer.celo.org',
				standard: 'none',
			},
		],
	},
};
