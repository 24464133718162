import { colors } from './colors';
import { spacings } from './spacings';

export const shadows = {
	primary: {
		100: `0 ${spacings[8]} ${spacings[16]} 0 rgba(88, 130, 255, 0.24)`,
		500: `0 ${spacings[8]} ${spacings[16]} 0 ${colors.primary[500]}`,
		900: `0 ${spacings[8]} ${spacings[16]} 0 ${colors.primary[900]}`,
	},
	secondary: {
		100: `0 ${spacings[8]} ${spacings[16]} 0 rgb(245, 133,32, 0.24)`,
		500: `0 ${spacings[8]} ${spacings[16]} 0 ${colors.secondary[500]}`,
		900: `0 ${spacings[8]} ${spacings[16]} 0 ${colors.secondary[900]}`,
	},
	grey: {
		100: `0 ${spacings[8]} ${spacings[16]} 0 ${colors.grey[100]}`,
		500: `0 ${spacings[8]} ${spacings[16]} 0 ${colors.grey[500]}`,
		900: `0 ${spacings[8]} ${spacings[16]} 0 ${colors.grey[900]}`,
	},
	yellow: {
		100: '',
		500: '',
		900: '',
	},
	green: {
		100: '',
		500: '',
		900: '',
	},
	white: {
		100: '',
		500: '',
		900: '',
	},
	info: {
		100: '',
		500: '',
		900: '',
	},
	success: {
		100: '',
		500: '',
		900: '',
	},
	warning: {
		100: '',
		500: '',
		900: '',
	},
	error: {
		100: '',
		500: '',
		900: '',
	},
};

export type ThemeShadows = typeof shadows;
