import Img from 'components/Img';
import Row from 'components/Row';
import Select from 'components/Select';
import { TooltipWrapper } from 'components/TooltipWrapper';
import Typography from 'components/Typography';
import CheckMarkIcon from 'icons/CheckMarkIcon';
import React from 'react';
import {
	ActionMeta,
	components,
	OptionProps,
	SingleValueProps,
} from 'react-select';
import { Account } from 'screens/organisations/dashboard/Dashboard.types';
import { useTheme } from 'styled-components';
import { rem } from 'styles';
import { formatNumber } from 'utils/strings';
import { TokenOption } from '.';

const SelectOption = (props: OptionProps<unknown>) => {
	const { spacings, colors } = useTheme();
	const { data: optionData, isSelected, ...otherProps } = props;
	const {
		balance,
		tokenInfo: { logoUrl, symbol },
		disabled = false,
		disabledMessage,
	} = optionData as TokenOption;
	const OptionComponent = () => (
		<components.Option
			data={optionData}
			isSelected={isSelected}
			{...otherProps}
			isDisabled={disabled}
			css={{
				pointerEvents: disabled ? 'none' : 'all',
			}}
		>
			<Row
				justifyContent={'space-between'}
				style={{
					position: 'relative',
					cursor: disabled ? 'not-allowed' : 'pointer',
				}}
				alignItems='flex-start'
				width={'100%'}
			>
				<Row rowGap={spacings[10]}>
					<Img
						src={logoUrl}
						style={{
							width: spacings[20],
							height: spacings[20],
							...(disabled ? { filter: 'grayscale(1)' } : {}),
						}}
					/>
					<Typography
						variant='subtitle1'
						size='md'
						color={disabled ? 'textSecondary' : 'textPrimary'}
					>
						{formatNumber(Number(balance), 5)} {symbol}
					</Typography>
				</Row>
				{!disabled && isSelected && (
					<CheckMarkIcon color={colors.primary[900]} />
				)}
			</Row>
		</components.Option>
	);

	if (disabled && disabledMessage) {
		return (
			<TooltipWrapper title={disabledMessage} position='top'>
				<OptionComponent />
			</TooltipWrapper>
		);
	}

	return <OptionComponent />;
};

const SingleValueRenderer = ({
	data,
	...otherProps
}: SingleValueProps<unknown>) => {
	const {
		balance,
		tokenInfo: { logoUrl, symbol },
	} = data as TokenOption;
	const { spacings } = useTheme();
	return (
		<components.SingleValue data={data} {...otherProps}>
			<Row rowGap={spacings[10]}>
				<Img
					src={logoUrl}
					style={{ width: spacings[20], height: spacings[20] }}
				/>
				<Typography variant='subtitle1' size='md'>
					{formatNumber(Number(balance), 5)} {symbol}
				</Typography>
			</Row>
		</components.SingleValue>
	);
};

type Props = {
	options?: Array<Account>;
	onChange?:
		| ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void)
		| undefined;
};

const SelectTokenDropdown = React.forwardRef<any, Props>((props, ref) => {
	const { options, ...otherProps } = props;
	return (
		<Select
			options={options}
			width={rem(440)}
			ref={ref}
			components={{
				Option: SelectOption,
				SingleValue: SingleValueRenderer,
			}}
			{...otherProps}
		/>
	);
});

export { SelectTokenDropdown };
