import styled, { css, CSSObject } from 'styled-components';
import { fonts } from 'styles/constants/typography';
import { TypographyProps } from '.';

const StyledTypographyRoot = styled.span<TypographyProps>(
	({
		theme,
		variant,
		color,
		gutterBottom,
		size,
		fontWeight,
		align = 'inherit',
		width,
	}) => {
		const { fontSizes, fontWeights, colors } = theme;

		const _style: CSSObject = {
			fontFamily: fonts.primary,
			color: colors.grey[900],
			fontSize: fontSizes[size || 'md'],
			fontWeight: fontWeights[fontWeight || 'normal'],
			textAlign: align,
			padding: 0,
			margin: 0,
			marginBottom: gutterBottom || 0,
			width,
		};

		// get matching color from theme
		switch (color) {
			case 'primary':
				_style.color = colors.primary[900];
				break;
			case 'secondary':
				_style.color = colors.secondary[900];
				break;
			case 'error':
				_style.color = colors.error[900];
				break;
			case 'white':
				_style.color = colors.white[900];
				break;
			case 'textPrimary':
				_style.color = colors.grey[900];
				break;
			case 'textSecondary':
				_style.color = colors.grey[500];
				break;
			default:
				_style.color = colors[color || 'grey'][900];
		}

		// get matching styles by variant
		switch (variant) {
			case 'h1':
				_style.fontSize = size ? fontSizes[size] : fontSizes['6xl'];
				_style.fontWeight = fontWeight
					? fontWeights[fontWeight]
					: fontWeights.semibold;
				break;

			case 'h2':
				_style.fontSize = size ? fontSizes[size] : fontSizes['2xl'];
				_style.fontWeight = fontWeight
					? fontWeights[fontWeight]
					: fontWeights.semibold;
				break;

			case 'h3':
				_style.fontSize = size ? fontSizes[size] : fontSizes['xl'];
				_style.fontWeight = fontWeight
					? fontWeights[fontWeight]
					: fontWeights.semibold;
				break;

			case 'h4':
				_style.fontSize = size ? fontSizes[size] : fontSizes['xl'];
				_style.fontWeight = fontWeight
					? fontWeights[fontWeight]
					: fontWeights.bold;
				break;

			case 'h5':
				_style.fontSize = size ? fontSizes[size] : fontSizes.lg;
				_style.fontWeight = fontWeight
					? fontWeights[fontWeight]
					: fontWeights.medium;
				break;

			case 'h6':
				_style.fontSize = size ? fontSizes[size] : fontSizes.md;
				_style.fontWeight = fontWeight
					? fontWeights[fontWeight]
					: fontWeights.normal;
				break;

			case 'button':
				_style.fontSize = size ? fontSizes[size] : fontSizes.md;
				_style.fontWeight = fontWeight
					? fontWeights[fontWeight]
					: fontWeights.semibold;
				_style.textTransform = 'uppercase';
				break;

			case 'subtitle2':
				_style.fontSize = size ? fontSizes[size] : fontSizes.xs;
			// eslint-disable-next-line no-fallthrough
			case 'subtitle1':
			default:
				_style.fontWeight = fontWeight
					? fontWeights[fontWeight]
					: fontWeights.normal;
				_style.display = 'inline';
				break;
		}
		return css(_style);
	}
);

export { StyledTypographyRoot };
