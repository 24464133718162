import React from 'react';
import Typography, { StyledTypographyProps } from '.';

const Title = (props: StyledTypographyProps) => {
	return (
		<Typography
			variant='h2'
			size='3xl'
			fontWeight='semibold'
			color='primary'
			{...props}
		/>
	);
};

export default Title;
