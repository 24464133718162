import { Popover } from '@headlessui/react';
import { modalActions } from 'common/modal';
import Address from 'components/Address';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Img from 'components/Img';
import { ExternalLink } from 'components/Link';
import Row from 'components/Row';
import Stack from 'components/Stack';
import { TooltipWrapper } from 'components/TooltipWrapper';
import Typography from 'components/Typography';
import { getExplorerForChain } from 'constants/explorers';
import paths from 'constants/paths';
import { useAppDispatch, useAppSelector, useWeb3Onboard } from 'hooks';
import ChevronDownIcon from 'icons/ChevronDownIcon';
import CopyIcon from 'icons/CopyIcon';
import DisconnectIcon from 'icons/DisconnectIcon';
import LinkIcon from 'icons/LinkIcon';
import LogoutIcon from 'icons/LogoutIcon';
import ProfileIcon from 'icons/ProfileIcon';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import {
	dashboardActions,
	selectDashboard,
} from 'screens/organisations/dashboard/Dashboard.slice';
import { useTheme } from 'styled-components';
import { colors } from 'styles';
import { useCopyText } from 'utils/copy';
import { shortenText } from 'utils/strings';
import {
	DetailsRow,
	StyledWalletPicker,
	StyledWalletPickerPanel,
} from './ConnectedWallet.styles';
import { EDIT_PROFILE_DRAWER } from './EditProfileDrawer';

type Alignment = 'start' | 'end';
type Side = 'top' | 'right' | 'bottom' | 'left';
type AlignedPlacement = `${Side}-${Alignment}`;
type Placement = Side | AlignedPlacement;
export interface ConnectedWalletProps {
	placement?: Placement;
}

const WalletIcon = ({ walletName }: { walletName: string }) => {
	let icon = 'metamask';
	let width = '40';

	switch (walletName) {
		case 'MetaMask':
			icon = 'metamask';
			break;
		case 'Ledger':
			icon = 'ledger';
			break;
		case 'Trezor':
			icon = 'trezor';
			width = '16';
			break;
		case 'WalletConnect':
			icon = 'walletconnect';
			width = '30';
			break;
	}

	return (
		<Img src={`/assets/logos/${icon}.svg`} width={width} alt={walletName} />
	);
};

const ConnectedWallet = ({
	placement = 'bottom-end',
}: ConnectedWalletProps) => {
	const { spacings, fontWeights } = useTheme();
	const [refEl, setRefEl] = useState();
	const [popperEl, setPopperEl] = useState();
	const router = useRouter();
	const dispatch = useAppDispatch();
	const { disconnectWallet, connectedWallet, account, chainId } =
		useWeb3Onboard();

	const { styles, attributes } = usePopper(refEl, popperEl, {
		placement,
		modifiers: [
			{
				name: 'offset',
				options: { offset: [0, 10] },
			},
		],
	});

	const { isCopied, copyFn } = useCopyText();
	const { user } = useAppSelector(selectDashboard);
	const openEditProfileDrawer = () => {
		dispatch(modalActions.show(EDIT_PROFILE_DRAWER));
	};

	return account && connectedWallet ? (
		<Popover style={{ position: 'relative' }}>
			{({ close }) => (
				<>
					<StyledWalletPicker ref={setRefEl}>
						<DetailsRow>
							<WalletIcon walletName={connectedWallet.label} />
							<Stack alignItems='flex-start' gutter={spacings[2]} flex={1}>
								{user ? (
									<>
										<Typography
											size='md'
											color='textPrimary'
											fontWeight='medium'
										>
											{shortenText(user?.name, 15)}
										</Typography>

										<Row rowGap={spacings[2]}>
											<Address
												typographyProps={{ size: 'xs', color: 'textSecondary' }}
												withCopy={false}
												withExplorer={false}
												address={account}
												chainId={chainId}
												short
												withPrefix
												prefixSize='xs'
											/>
										</Row>
									</>
								) : (
									<>
										<Address
											withCopy={false}
											withExplorer={false}
											address={account}
											chainId={chainId}
											withPrefix
											short
										/>
										<Row rowGap={spacings[2]}>
											<Typography size='xs' color='textSecondary'>
												{connectedWallet.label}
											</Typography>
										</Row>
									</>
								)}
							</Stack>
							<ChevronDownIcon />
						</DetailsRow>
					</StyledWalletPicker>
					<StyledWalletPickerPanel
						ref={setPopperEl}
						style={styles.popper}
						{...attributes.popper}
					>
						<Typography
							variant='subtitle2'
							size='sm'
							color='textSecondary'
							style={{ padding: `${spacings[10]} ${spacings[20]}` }}
						>
							Connected
						</Typography>
						<DetailsRow
							background={colors.primary[50]}
							style={{ margin: `0 ${spacings[10]}`, padding: spacings[10] }}
						>
							<WalletIcon walletName={connectedWallet.label} />
							<Stack alignItems='flex-start' gutter={spacings[2]} flex={1}>
								<Address
									withCopy={false}
									withExplorer={false}
									address={account}
									short
									typographyProps={{ fontWeight: 'normal', size: 'xs' }}
								/>
								<Typography variant='subtitle2' size='xs' color='textSecondary'>
									{connectedWallet.label}
								</Typography>
							</Stack>
							<TooltipWrapper title={isCopied ? 'Copied' : 'Copy address'}>
								<IconButton
									color='info'
									onClick={(e) => {
										e.stopPropagation();
										copyFn(account);
									}}
									icon={<CopyIcon />}
									size='sm'
								/>
							</TooltipWrapper>
							{chainId && (
								<ExternalLink
									href={`${getExplorerForChain(chainId)}/address/${account}`}
								>
									<TooltipWrapper title='View on explorer'>
										<IconButton color='info' icon={<LinkIcon />} size='sm' />
									</TooltipWrapper>
								</ExternalLink>
							)}
							{/* <IconButton color='info' icon={<QRCodeIcon />} size='sm' /> */}
						</DetailsRow>
						<Divider style={{ margin: `${spacings[10]} 0 ` }} />
						{router.pathname !== paths.root &&
							router.pathname !== paths.signup && (
								<>
									<DetailsRow>
										<Button
											variant='text'
											onClick={openEditProfileDrawer}
											leftIcon={<ProfileIcon />}
											style={{
												fontWeight: fontWeights.normal,
												width: '100%',
												padding: `${spacings[10]} ${spacings[20]}`,
												justifyContent: 'flex-start',
												gap: spacings[10],
											}}
										>
											<Typography size='sm'>Manage Profile</Typography>
										</Button>
									</DetailsRow>
								</>
							)}
						<DetailsRow>
							<Button
								variant='text'
								color='error'
								onClick={async () => {
									if (connectedWallet)
										await disconnectWallet({ label: connectedWallet.label });

									close();
								}}
								leftIcon={<DisconnectIcon size={spacings[14]} />}
								style={{
									fontWeight: fontWeights.normal,
									width: '100%',
									padding: `${spacings[10]} ${spacings[20]}`,
									justifyContent: 'flex-start',
									gap: spacings[10],
								}}
							>
								<Typography size='sm' color='error'>
									Disconnect Wallet
								</Typography>
							</Button>
						</DetailsRow>
						<DetailsRow
							style={{
								marginBottom: spacings[10],
							}}
						>
							<Button
								variant='text'
								color='error'
								onClick={async () => {
									if (connectedWallet) {
										await disconnectWallet({ label: connectedWallet.label });
										localStorage.removeItem('connectedWallet');
										dispatch(dashboardActions.reset());
										localStorage.removeItem('token');
										router.push(paths.root);
									}

									close();
								}}
								leftIcon={<LogoutIcon size={spacings[14]} />}
								style={{
									fontWeight: fontWeights.normal,
									width: '100%',
									padding: `${spacings[10]} ${spacings[20]}`,
									justifyContent: 'flex-start',
									gap: spacings[10],
								}}
							>
								<Typography size='sm' color='error' style={{ padding: 0 }}>
									Logout
								</Typography>
							</Button>
						</DetailsRow>
					</StyledWalletPickerPanel>
				</>
			)}
		</Popover>
	) : null;
};

export { ConnectedWallet };
