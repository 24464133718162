import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClientMeta } from '@walletconnect/types';
import { AppState } from 'store';
import { Account } from '../Dashboard.types';

export type WalletConnectFormData = {
	safe: Account;
	uri: string;
};

export type AppMeta = IClientMeta | null;

export type WalletConnectState = {
	formData?: WalletConnectFormData;
	appMeta?: AppMeta;
};

export const initialState: WalletConnectState = {
	formData: undefined,
	appMeta: null,
};

const slice = createSlice({
	name: 'walletconnect',
	initialState,
	reducers: {
		setFormData(state, action: PayloadAction<WalletConnectFormData>) {
			state.formData = action.payload;
		},
		setAppMeta(state, action: PayloadAction<AppMeta | null>) {
			state.appMeta = action.payload;
		},
	},
});

export const { actions: walletConnectActions, reducer: walletConnectReducer } =
	slice;

export const selectWalletConnect = (state: AppState) => state.walletConnect;
