import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { AllReportsData } from '../PortfolioHistory.types';

export type FilterAssetOption = {
	address: string;
	decimals: number;
	id: string;
	label: string;
	logoUrl: string;
	name: string;
	networkId: string;
	symbol: string;
	type: string;
	value: string;
};

export type FilterAccountType = {
	accountName: string;
	address: string;
	networkId: number;
};

export type PortfolioHistoryByIdFiltersFormData = {
	assets?: FilterAssetOption[] | null;
};

export type PortfolioHistoryByIdState = {
	hasAppliedFilters: boolean;
	filteredReportsData?: AllReportsData[];
	accountsFilter?: FilterAccountType[];
};

export const initialState: PortfolioHistoryByIdState = {
	hasAppliedFilters: false,
	filteredReportsData: undefined,
	accountsFilter: undefined,
};

const slice = createSlice({
	name: 'reporting/portfolio-history/by-id',
	initialState,
	reducers: {
		setFilters(
			state,
			action: PayloadAction<{
				formData: PortfolioHistoryByIdFiltersFormData;
				allReportsData: AllReportsData[];
			}>
		) {
			state.hasAppliedFilters = true;

			const accountsFilteredData: AllReportsData[] = [];
			const finalFilteredData: AllReportsData[] = [];
			const { allReportsData, formData } = action.payload;

			// filter by accounts
			for (let i = 0; i < allReportsData.length; i++) {
				const { networkId, accountAddress } = allReportsData[i];
				if (state.accountsFilter?.length) {
					const key = `${networkId}_${accountAddress.toLowerCase()}`;
					for (let k = 0; k < state.accountsFilter.length; k++) {
						if (
							key ===
							`${state.accountsFilter[k].networkId}_${state.accountsFilter[
								k
							].address.toLowerCase()}`
						) {
							accountsFilteredData.push(allReportsData[i]);
						}
					}
				} else accountsFilteredData.push(allReportsData[i]);
			}

			// filter by assets
			for (let i = 0; i < accountsFilteredData.length; i++) {
				if (formData.assets?.length) {
					const { tokenDetails } = accountsFilteredData[i];
					const { assets } = formData;
					if (tokenDetails) {
						const key = `${tokenDetails.networkId}_${tokenDetails.address}`;
						for (let j = 0; j < assets.length; j++) {
							if (key === assets[j].value) {
								finalFilteredData.push(accountsFilteredData[i]);
							}
						}
					}
				} else {
					finalFilteredData.push(accountsFilteredData[i]);
				}
			}
			state.filteredReportsData = finalFilteredData;
		},
		setAccountsFilter(state, action: PayloadAction<FilterAccountType[]>) {
			state.accountsFilter = action.payload;
		},
		reset() {
			return initialState;
		},
	},
});

export const {
	actions: portfolioHistoryByIdActions,
	reducer: portfolioHistoryByIdReducer,
} = slice;

export const selectPortfolioHistoryById = (state: AppState) =>
	state.portfolioHistoryById;
