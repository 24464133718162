import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { networkIds } from 'constants/networks';
import { AppState } from 'store';

type ChainState = {
	networkId: string;
	isChainReady: boolean;
};
export const initialState: ChainState = {
	networkId: networkIds.ETHEREUM_MAINNET,
	isChainReady: false,
};

const slice = createSlice({
	name: 'network',
	initialState,
	reducers: {
		changeNetwork(state, action: PayloadAction<string>) {
			state.networkId = action.payload;
		},
		setChainReady(state, action: PayloadAction<boolean>) {
			state.isChainReady = action.payload;
		},
	},
});

export const { actions: chainActions, reducer: chainReducer } = slice;

export const selectChain = (state: AppState) => state.chain;
