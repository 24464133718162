import Typography from 'components/Typography';
import React from 'react';

type FieldHintProps = {
	hint: string;
};

const FieldHint = ({ hint }: FieldHintProps) => {
	return (
		<Typography color='textSecondary' size='xs' variant='subtitle2'>
			{hint}
		</Typography>
	);
};

export default FieldHint;
