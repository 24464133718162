import React from 'react';
import { StyledCheckbox } from './Checkbox.styles';

export type CheckboxProps = React.ComponentPropsWithoutRef<'input'>;

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
	(props, ref) => {
		return <StyledCheckbox ref={ref} {...props} />;
	}
);

export default Checkbox;
