import { DefaultFormFieldProps } from 'components/Form/types';
import React from 'react';
import { ActionMeta, Props } from 'react-select';
import { Account } from 'screens/organisations/dashboard/Dashboard.types';
import FormElementContainer from '../FormElementContainer';
import { SelectSafeDropdown } from './SelectSafeDropdown';

export type SelectSafeValue = Partial<Account> & {
	label: string;
	value: string;
};

export interface SelectSafeProps extends DefaultFormFieldProps {
	onChange?:
		| ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void)
		| undefined;
	options?: Array<SelectSafeValue>;
	value?: SelectSafeValue;
	width?: string;
}

const SelectSafeInput = React.forwardRef(
	(props: SelectSafeProps & Props, ref) => {
		const {
			label,
			width,
			suggestion,
			name,
			hint,
			error,
			options,
			onChange,
			...rest
		} = props;
		return (
			<FormElementContainer
				label={label}
				error={error}
				suggestion={suggestion}
				name={name}
				hint={hint}
				width={width}
			>
				<SelectSafeDropdown
					options={options}
					onChange={onChange}
					ref={ref}
					{...rest}
				/>
			</FormElementContainer>
		);
	}
);

export default SelectSafeInput;
