import styled, { css } from 'styled-components';
import { Theme } from 'styles/theme';
import { AvatarProps, avatarSizes } from './Avatar';

function getFontSize(theme: Theme, size: keyof typeof avatarSizes) {
	if (['sm', 'xs'].includes(size)) return theme.fontSizes['3xs'];
	return theme.fontSizes.md;
}

type StyledAvatarProps = Omit<AvatarProps, 'title'>;

const StyledAvatar = styled.span.withConfig({
	shouldForwardProp: (prop, defaultValidatorFn) =>
		!['color'].includes(prop) && defaultValidatorFn(prop),
})<StyledAvatarProps>(({ theme, color, size }) => {
	return css`
		width: ${avatarSizes[size]};
		height: ${avatarSizes[size]};
		color: ${theme.colors?.[color]?.[900] || color};
		background-color: ${theme.colors?.[color]?.[100] || color};
		border-radius: ${avatarSizes[size]};
		font-size: ${getFontSize(theme, size)};
		font-weight: ${theme.fontWeights.semibold};
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		user-select: none;
		text-transform: uppercase;
		flex-shrink: 0;
	`;
});

export { StyledAvatar };
