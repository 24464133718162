import { DefaultFormFieldProps } from 'components/Form/types';
import React from 'react';
import { ActionMeta, Props } from 'react-select';
import { AccountBalanceObject } from 'screens/organisations/dashboard/Dashboard.types';
import FormElementContainer from '../FormElementContainer';
import { SelectTokenDropdown } from './SelectTokenDropdown';

export type TokenOption = AccountBalanceObject & {
	value: string;
	label: string;

	disabled?: boolean;
	disabledMessage?: string;
};

export interface SelectSafeProps extends DefaultFormFieldProps {
	onChange?:
		| ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void)
		| undefined;
	options: Array<any>;
	value?: any;
	width?: string;
}

const SelectToken = React.forwardRef<any, SelectSafeProps & Props>(
	(props, ref) => {
		const {
			label,
			width,
			suggestion,
			name,
			hint,
			error,
			options,
			onChange,
			...rest
		} = props;

		return (
			<FormElementContainer
				label={label}
				error={error}
				suggestion={suggestion}
				name={name}
				hint={hint}
				width={width}
			>
				<SelectTokenDropdown
					ref={ref}
					options={options}
					onChange={onChange}
					{...rest}
				/>
			</FormElementContainer>
		);
	}
);

export default SelectToken;
