import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'store';

type DashboardState = {
	user: {
		name: string;
		email?: string;
	} | null;

	hasNewNotifications: boolean;
	token: string | null;
};

const initialState: DashboardState = {
	user: null,
	hasNewNotifications: false,
	token: null,
};

const slice = createSlice({
	name: 'organisation/dashboard',
	initialState,
	reducers: {
		setUser(state, action: PayloadAction<{ name: string; email?: string }>) {
			state.user = action.payload;
		},

		setHasNewNotifications(state, action: PayloadAction<boolean>) {
			state.hasNewNotifications = action.payload;
		},

		setToken(state, action: PayloadAction<string | null>) {
			state.token = action.payload;
		},

		reset() {
			return initialState;
		},
	},
});

export const { actions: dashboardActions, reducer: dashboardReducer } = slice;
export const selectDashboard = (state: AppState) => state.dashboard;
