import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'store';

type ModalState = {
	[name: string]: { show: boolean; props?: any };
};
export const initialState: ModalState = {};

const slice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		show: {
			reducer(state, action: PayloadAction<string, string, { props?: any }>) {
				state[action.payload] = {
					show: true,
					props: action.meta,
				};
			},
			prepare(payload: string, props?: any) {
				return { payload, meta: props };
			},
		},
		hide(state, action: PayloadAction<string>) {
			state[action.payload] = { show: false };
		},
	},
});

export const { actions: modalActions, reducer: modalReducer } = slice;

export const selectModal = (state: AppState) => state.modal;
