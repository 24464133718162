import paths from 'constants/paths';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import Router from 'next/router';
import { ERROR_CODES, getErrorCode, getErrorMessage } from './error';
import { showError } from './toast';

const isJWTTokenValid = (jwtToken: string): boolean => {
	if (!jwtToken) return false;
	const { exp = 0 } = jwtDecode<JwtPayload>(jwtToken);
	if (exp <= Date.now() / 1000) return false;
	return true;
};

const getAddressFromToken = (jwtToken: string | null) => {
	if (!jwtToken) return '';
	const { walletAddress } = jwtDecode<{ walletAddress: string }>(jwtToken);
	return walletAddress;
};

const decodeJWT = (jwtToken: string | null) => {
	if (!jwtToken) return '';
	return jwtDecode(jwtToken);
};

const getUIDFromToken = (): string => {
	try {
		const token = window.localStorage.getItem('token');
		if (token) {
			const tokenData = jwtDecode<JwtPayload & { id: string }>(token);
			return tokenData.id;
		}
	} catch (e) {
		console.warn(getErrorMessage(e));
	}
	return '';
};

const handleUnauthorized = (error: unknown) => {
	if (getErrorCode(error) === ERROR_CODES.UNAUTHORIZED) {
		if (getErrorMessage(error).includes('expired')) {
			showError('Session expired. Please login.', {
				toastId: 'session-expired',
			});
		} else {
			showError('Unauthorized', { toastId: 'unauthorized' });
		}
		window.localStorage.removeItem('token');
		return Router.push(paths.root);
	}
};
const isUnauthorized = (error: unknown) => {
	return getErrorCode(error) === ERROR_CODES.UNAUTHORIZED;
};

export {
	isJWTTokenValid,
	getUIDFromToken,
	handleUnauthorized,
	isUnauthorized,
	getAddressFromToken,
	decodeJWT,
};
