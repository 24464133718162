import {
	Account,
	NetworkTokenInfo,
	SimpleTokenType,
	TransactionOrigins,
	UserInfo,
} from '../../Dashboard.types';
import {
	DenominationEnum,
	FlowRateDurationTypes,
} from '../../new-transfer/NewTransfer.constants';
import {
	IntervalDataObjectType,
	StreamReceiverObject,
	StreamValidationErrorCodes,
} from '../../new-transfer/stream-payout/StreamPayout.types';
import { StreamTransferFormData } from '../../new-transfer/stream-payout/StreamPayout.utils';
import { ExecutionStatusEnum } from '../../transactions/by-id/ViewTransactionById.types';

export enum AutomatedPaymentsTypesEnum {
	STREAMS = 'STREAMS',
	// SCHEDULE_PROPOSAL = 'SCHEDULE_PROPOSAL',
}
export type EachStreamType = {
	amount: string;
	denomination?: DenominationEnum;
	flowRate: number;
	interval: FlowRateDurationTypes;
	intervalData?: IntervalDataObjectType;
	receiver: string;
	sender: string;
	startDate: string;
	streamedAmount: number;
	superToken: NetworkTokenInfo;
	token: NetworkTokenInfo;
	metaInfo: StreamReceiverObject;
	transaction: {
		id: string;
		feMetaData: {
			formData: StreamTransferFormData;
		};
		transactionHash: string;
		createdAt?: string;
		executedAt?: string;
		createdBy?: UserInfo;
		executedBy?: UserInfo;
		transactionFee: string;
		executionStatus?: ExecutionStatusEnum;
		origin: TransactionOrigins;
	};
};

export interface StreamsType extends Account {
	streams: EachStreamType[];
	tokens: { token: SimpleTokenType; hasTopUp: boolean }[];
}

export type StreamsResponseType = {
	count: number;
	rows: StreamsType[];
};

export type StreamSelectionType = {
	networkId: number;
	account: Account;
	streams: EachStreamType[];
};

export type ValidatedStream = {
	sender: string;
	receiver: string;
	tokenAddress: string;
	exist: boolean;
	errorCode: StreamValidationErrorCodes;
};

export type StreamReceiver = {
	tokenAddress: string;
	receiver: string;
};
