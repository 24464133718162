import Address from 'components/Address';
import Avatar from 'components/Avatar';
import Row from 'components/Row';
import Select from 'components/Select';
import Stack from 'components/Stack';
import Typography from 'components/Typography';
import CheckMarkIcon from 'icons/CheckMarkIcon';
import React from 'react';
import {
	ActionMeta,
	components,
	OptionProps,
	SingleValueProps,
} from 'react-select';
import { Account } from 'screens/organisations/dashboard/Dashboard.types';
import { useTheme } from 'styled-components';
import { rem } from 'styles';
import { SelectSafeValue } from '.';

const SelectOption = (props: OptionProps<unknown>) => {
	const { spacings, colors } = useTheme();
	const { data: optionData, isSelected, ...otherProps } = props;
	const { accountAddress, accountName, networkId } = optionData as Account;
	return (
		<components.Option
			data={optionData}
			isSelected={isSelected}
			{...otherProps}
		>
			<Row
				justifyContent={'space-between'}
				style={{
					position: 'relative',
				}}
				alignItems='center'
				width='100%'
			>
				<Row rowGap={spacings[10]}>
					<Avatar
						title={accountName}
						initialsLength={1}
						color='primary'
						size='md'
					/>
					<Stack gutter={spacings[2]}>
						{/** TODO: get name for safe/account */}
						<Typography size='md'>{accountName}</Typography>
						<Address
							address={accountAddress}
							withCopy={false}
							withExplorer={false}
							typographyProps={{
								size: 'xs',
								fontWeight: 'normal',
								color: 'textSecondary',
							}}
							chainId={networkId}
							withPrefix
						/>
					</Stack>
				</Row>
				{isSelected && <CheckMarkIcon color={colors.primary[900]} />}
			</Row>
		</components.Option>
	);
};

const SingleValueRenderer = (props: SingleValueProps<unknown>) => {
	const { spacings } = useTheme();
	const { data, ...otherProps } = props;
	const { accountAddress, accountName, networkId } = data as Account;
	return (
		<components.SingleValue data={data} {...otherProps}>
			{accountAddress && accountName && networkId ? (
				<Row rowGap={spacings[10]}>
					<Avatar
						title={accountName}
						initialsLength={1}
						color='primary'
						size='md'
					/>

					<Stack gutter={spacings[2]}>
						<Typography size='md'>{accountName}</Typography>
						<Address
							address={accountAddress}
							withCopy={false}
							withExplorer={false}
							typographyProps={{
								size: 'xs',
								fontWeight: 'normal',
								color: 'textSecondary',
							}}
							chainId={networkId}
							withPrefix
						/>
					</Stack>
				</Row>
			) : (
				<Typography color='textSecondary'>
					Select or type a Safe address
				</Typography>
			)}
		</components.SingleValue>
	);
};

type Props = {
	options?: Array<SelectSafeValue>;
	onChange?:
		| ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void)
		| undefined;
};

const SelectSafeDropdown = React.forwardRef((props: Props, ref) => {
	const { options, ...otherProps } = props;
	return (
		<Select
			options={options}
			width={rem(440)}
			ref={ref}
			components={{
				Option: SelectOption,
				SingleValue: SingleValueRenderer,
			}}
			{...otherProps}
		/>
	);
});

export { SelectSafeDropdown };
