export function rem(px: number) {
	return `${px / 16}rem`;
}

// Mental model: If you need a spacing of 40px, use spacings[40]
export const spacings = {
	0: rem(0),
	1: rem(1),
	2: rem(2),
	4: rem(4),
	6: rem(6),
	8: rem(8),
	10: rem(10),
	12: rem(12),
	14: rem(14),
	16: rem(16),
	18: rem(18),
	20: rem(20),
	24: rem(24),
	28: rem(28),
	30: rem(30),
	32: rem(32),
	36: rem(36),
	40: rem(40),
	48: rem(48),
	50: rem(50),
	56: rem(56),
	60: rem(60),
	64: rem(64),
	80: rem(80),
	96: rem(96),
	100: rem(100),
	112: rem(112),
	128: rem(128),
	144: rem(144),
	160: rem(160),
	max: rem(9999),
};

export type ThemeSpacings = typeof spacings;
