import { yupResolver } from '@hookform/resolvers/yup';
import { connectModal, modalActions, ModalControlProps } from 'common/modal';
import PrimaryButton from 'components/Button/PrimaryButton';
import { TextInput } from 'components/Form';
import Row from 'components/Row';
import {
	SideDrawer,
	SideDrawerBody,
	SideDrawerFooter,
	SideDrawerHeader,
} from 'components/SideDrawer';
import Stack from 'components/Stack';
import Typography from 'components/Typography';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { selectDashboard } from 'screens/organisations/dashboard/Dashboard.slice';
import {
	UpdateUserRequest,
	useUpdateUserMutation,
} from 'screens/signup/Signup.queries';
import schema from 'screens/signup/SignupForm.schema';
import { useTheme } from 'styled-components';

export const EDIT_PROFILE_DRAWER = 'EDIT_PROFILE_DRAWER';

const EditProfileDrawer = ({ isOpen, hideModal }: ModalControlProps) => {
	const { spacings } = useTheme();
	const { user } = useAppSelector(selectDashboard);

	const { mutate: updateUser, isLoading: isUpdatingUser } =
		useUpdateUserMutation();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isDirty },
	} = useForm<UpdateUserRequest>({
		defaultValues: { name: user?.name, email: user?.email },
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		reset({ name: user?.name, email: user?.email });
	}, [reset, user]);

	const dispatch = useAppDispatch();

	const onSubmit: SubmitHandler<UpdateUserRequest> = (values) => {
		const { name, email } = values;
		updateUser(
			{
				name,
				email,
			},
			{ onSuccess: () => dispatch(modalActions.hide(EDIT_PROFILE_DRAWER)) }
		);
	};

	const hideDrawer = () => {
		hideModal();
		reset();
	};

	return (
		<SideDrawer size='sm' isOpen={isOpen} onClose={hideDrawer}>
			<SideDrawerHeader onClose={hideDrawer}>
				<Stack gutter={spacings[10]}>
					<Typography fontWeight='semibold'>Manage User Profile</Typography>
					<Typography color='textSecondary' size='sm'>
						View and edit your profile here
					</Typography>
				</Stack>
			</SideDrawerHeader>
			<form onSubmit={handleSubmit(onSubmit)}>
				<SideDrawerBody>
					<Stack gutter={spacings[40]}>
						<TextInput
							label='Name'
							{...register('name')}
							error={errors.name?.message}
						/>
						<TextInput
							label='Email'
							suggestion='Optional'
							hint='To receive email notifications, enter your email address.'
							{...register('email')}
							error={errors.email?.message}
						/>
					</Stack>
				</SideDrawerBody>
				<SideDrawerFooter>
					<Row rowGap={spacings[30]}>
						<PrimaryButton
							variant='outlined'
							onClick={() => dispatch(modalActions.hide(EDIT_PROFILE_DRAWER))}
						>
							Discard
						</PrimaryButton>

						<PrimaryButton
							type='submit'
							isLoading={isUpdatingUser}
							disabled={isUpdatingUser || !isDirty}
						>
							Save Changes
						</PrimaryButton>
					</Row>
				</SideDrawerFooter>
			</form>
		</SideDrawer>
	);
};

export default connectModal(EDIT_PROFILE_DRAWER)(EditProfileDrawer);
