import axios from 'axios';
import { BE_URL_V1 } from 'constants/endpoints';

export const authAxios = () => {
	return axios.create({
		baseURL: BE_URL_V1,
		timeout: 30000,
		headers: {
			Authorization: 'Bearer ' + window.localStorage.getItem('token'),
		},
	});
};

export const getOrganisationSlug = () => {
	if (typeof window === undefined) return '';
	// this fn will get the slug from the URL,
	// so that we don't need to pass it manually in every request
	const { pathname } = window.location;
	return pathname.split('/')[2] || '';
};

export const orgAxios = () => {
	const slug = getOrganisationSlug();

	const customAxios = axios.create({
		baseURL: BE_URL_V1,
		timeout: 60000,
		headers: {
			Authorization: 'Bearer ' + window.localStorage.getItem('token'),
			'organisation-slug': slug,
			// add Postman mock API key in development
			...(process.env.NODE_ENV === 'development' && {
				'x-api-key': process.env.NEXT_PUBLIC_POSTMAN_MOCK_API_KEY,
			}),
		},
	});

	// customAxios.interceptors.response.use((response) => {
	// 	if (response.status === 401) {
	// 		return null;
	// 	}
	// 	return response;
	// });

	return customAxios;
};

export type APIResponse<T = any> = {
	data: T;
	date: string;
	success: boolean;
	version: string;
	error?: { message: string; stack: string; type: string };
};

export function addQueryParams(endpoint: string, params?: Record<string, any>) {
	const urlParams = serializeQuery(params);
	const requestUrl = `${endpoint}?${urlParams}`;
	return requestUrl;
}

export function serializeQuery(
	obj: Record<string, any> | undefined,
	prefix?: string
): string {
	if (!obj) return '';

	const str = [];

	for (const prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop)) {
			const k = prefix ? prefix + '[' + prop + ']' : prop;
			const value = obj[prop];

			if (value) {
				str.push(
					typeof value === 'object'
						? serializeQuery(value, k)
						: encodeURIComponent(k) + '=' + encodeURIComponent(value)
				);
			}
		}
	}
	return str.join('&');
}
