import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { endpoints } from 'constants/endpoints';
import paths from 'constants/paths';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { dashboardActions } from 'screens/organisations/dashboard/Dashboard.slice';
import { authAxios } from 'utils/axios';
import {
	ERROR_CODES,
	getErrorCode,
	getErrorMessage,
	handleErrorWithAuth,
} from 'utils/error';
import { showError, showSuccess } from 'utils/toast';

const signupAPI = (payload: {
	walletAddress: string;
	signature: string;
	signinKey: string;
	name: string;
	email?: string;
	title?: string;
	funnel: string;
	referralCode?: string | string[];
}) => axios.post(endpoints.users.register, payload);

const useSignupMutation = () => {
	const router = useRouter();

	return useMutation(signupAPI, {
		onSuccess: (data) => {
			window.localStorage.setItem('token', data.data.data);
			router.push(paths.organisations.root);
		},

		onError: (error) => {
			const errorCode = getErrorCode(error);

			if (
				[
					ERROR_CODES.USER_ALREADY_CREATED,
					ERROR_CODES.REFERRAL_CODE_INVALID,
				].includes(errorCode)
			)
				router.push(paths.root);

			showError(getErrorMessage(error));
		},
	});
};

export type UpdateUserRequest = {
	name: string;
	email?: string;
};

const updateUserAPI = (payload: UpdateUserRequest) =>
	authAxios().put(endpoints.users.update, payload);

const useUpdateUserMutation = () => {
	const dispatch = useDispatch();

	return useMutation(updateUserAPI, {
		onSuccess: (data) => {
			const { name, email } = data.data.data;
			dispatch(dashboardActions.setUser({ name, email }));

			showSuccess('Profile updated successfully');
		},

		onError: handleErrorWithAuth,
	});
};

export { useSignupMutation, useUpdateUserMutation };
