import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenOption } from 'components/Form/SelectToken';
import { cloneDeep } from 'lodash';
import { AppState } from 'store';
import { ReceiverObject } from './MassPayout.types';

export type TransferSummary = {
	id: number;
	tokenSymbol: string;
	tokenAddress: string;
	receivers?: ReceiverObject[];
	count: number;
	tokenTotal: number;
	fiatTotal: number;
	isInsufficientBalance: boolean;
	currentTokenBalance: number;
	currentFiatBalance: number;
	tokenBalanceAfterPayment: number;
	fiatBalanceAfterPayment: number;
	token: TokenOption;
	batchName?: string;
};

type MassPayoutState = {
	transferSummary: TransferSummary[];
	isResolving: boolean;
};

const initialState: MassPayoutState = {
	transferSummary: [],
	isResolving: false,
};

const slice = createSlice({
	name: 'organisation/create-new-transfer/mass-payout',
	initialState,
	reducers: {
		setSummary(state, action: PayloadAction<TransferSummary[]>) {
			const newTransferSummary = cloneDeep(action.payload);
			state.transferSummary = newTransferSummary;
		},
		setIsResolving(state, action: PayloadAction<boolean>) {
			state.isResolving = action.payload;
		},
		reset() {
			return initialState;
		},
	},
});

export const { actions: massPayoutActions, reducer: massPayoutReducer } = slice;

export const selectMassPayout = (state: AppState) => state.massPayout;
