// Combine all reducers in this file and export the combined reducers

import { chainReducer } from 'common/chain/SelectChain.slice';
import { globalFilterReducer } from 'common/global-filter/GlobalFilter.slice';
import { modalReducer } from 'common/modal';
import { routerReducer } from 'connected-next-router';
import { combineReducers } from 'redux';
import { dashboardReducer } from 'screens/organisations/dashboard/Dashboard.slice';
import { automatedPaymentsReducer } from 'screens/organisations/dashboard/automated-payments/all/AutomatedPayments.slice';
import { createNewTransferReducer } from 'screens/organisations/dashboard/new-transfer/NewTransfer.slice';
import { massPayoutReducer } from 'screens/organisations/dashboard/new-transfer/mass-payout/MassPayout.slice';
import { streamPayoutReducer } from 'screens/organisations/dashboard/new-transfer/stream-payout/StreamPayout.slice';
import { cashFlowReducer } from 'screens/organisations/dashboard/reporting/cash-flow/CashFlow.slice';
import { portfolioHistoryByIdReducer } from 'screens/organisations/dashboard/reporting/portfolio-history/by-id/PortfolioHistoryById.slice';
import { transactionsReducer } from 'screens/organisations/dashboard/transactions/all/Transactions.slice';
import { walletConnectReducer } from 'screens/organisations/dashboard/wallet-connect/WalletConnect.slice';

const reducers = {
	automatedPayments: automatedPaymentsReducer,
	chain: chainReducer,
	createNewTransfer: createNewTransferReducer,
	dashboard: dashboardReducer,
	globalFilter: globalFilterReducer,
	modal: modalReducer,
	massPayout: massPayoutReducer,
	router: routerReducer,
	streamPayout: streamPayoutReducer,
	transactions: transactionsReducer,
	walletConnect: walletConnectReducer,
	portfolioHistoryById: portfolioHistoryByIdReducer,
	cashFlow: cashFlowReducer,
};

export default function createReducer() {
	const rootReducer = combineReducers(reducers);

	return rootReducer;
}
