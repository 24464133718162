import { ethers } from 'ethers';
import * as yup from 'yup';

yup.addMethod<yup.StringSchema>(
	yup.string,
	'addressType',
	function (errorMessage: string) {
		return this.test(`test-address-type`, errorMessage, function (value) {
			const { path, createError } = this;

			if (!value) {
				return createError({
					path,
					message: errorMessage || 'Address is required',
				});
			}

			if (!ethers.utils.isAddress(value)) {
				return createError({
					path,
					message: errorMessage || 'Invalid address',
				});
			}

			return true;
		});
	}
);

// Safe address
yup.addMethod<yup.StringSchema>(
	yup.string,
	'optionalSafeAddressType',
	function (errorMessage: string) {
		return this.test(`test-safe-address-type`, errorMessage, function (value) {
			const { path, createError } = this;

			if (!value) return true;
			else if (ethers.utils.isAddress(value)) {
				return true;
			}

			return createError({
				path,
				message: errorMessage || 'Invalid Safe address',
			});
		});
	}
);

export default yup;
