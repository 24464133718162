import React, { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';
import { ThemeFontSizes, ThemeFontWeights } from 'styles/constants/typography';
import { StyledTypographyRoot } from './Typography.styles';

type TypographyVariantOptions =
	| 'a'
	| 'body'
	| 'button'
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'span'
	| 'subtitle1'
	| 'subtitle2';

export type TypographyColorOptions =
	| 'primary'
	| 'secondary'
	| 'textPrimary'
	| 'textSecondary'
	| 'error'
	| 'success'
	| 'white';

const defaultVariantMapping = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',
	subtitle1: 'h6',
	subtitle2: 'p',
	body: 'p',
	inherit: 'p',
	button: 'button',
	a: 'a',
	span: 'span',
} as const;

export interface TypographyProps {
	variant?: TypographyVariantOptions;
	gutterBottom?: string;
	size?: keyof ThemeFontSizes;
	fontWeight?: keyof ThemeFontWeights;
	align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	children?: ReactNode | string;
	color?: TypographyColorOptions;
	width?: string;
	style?: CSSProperties;
	title?: string;
	onClick?: any;
}

export type StyledTypographyProps = Omit<TypographyProps, 'variant'>;

const Typography = React.forwardRef(
	(props: TypographyProps, ref: React.Ref<any>) => {
		const { variant, size, ...otherProps } = props;
		return (
			<StyledTypographyRoot
				ref={ref}
				as={defaultVariantMapping[variant ?? 'span']}
				variant={variant}
				size={size}
				{...otherProps}
			/>
		);
	}
);

export default Typography;
