const ProfileIcon = () => {
	return (
		<svg
			width='14'
			height='14'
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_316_6784)'>
				<path
					d='M10.9883 11.4833C10.5225 10.8665 9.91983 10.3664 9.22779 10.0222C8.53574 9.67802 7.77324 9.49924 7.00033 9.49994C6.22743 9.49924 5.46492 9.67802 4.77288 10.0222C4.08083 10.3664 3.47816 10.8665 3.01233 11.4833M10.9877 11.4833C11.8967 10.6748 12.5384 9.60897 12.8278 8.42731C13.1172 7.24565 13.0405 6.00394 12.608 4.86685C12.1754 3.72976 11.4075 2.75102 10.4059 2.06042C9.40438 1.36982 8.21656 1 7 1C5.78344 1 4.59562 1.36982 3.59407 2.06042C2.59252 2.75102 1.82455 3.72976 1.39202 4.86685C0.959494 6.00394 0.882835 7.24565 1.17221 8.42731C1.46159 9.60897 2.10333 10.6748 3.01233 11.4833M10.9877 11.4833C9.89035 12.4621 8.47075 13.0021 7.00033 13C5.52969 13.0023 4.10982 12.4623 3.01233 11.4833M9.00033 5.49987C9.00033 6.03032 8.78962 6.53903 8.41455 6.91411C8.03947 7.28919 7.53077 7.49991 7.00033 7.49991C6.4699 7.49991 5.96119 7.28919 5.58612 6.91411C5.21105 6.53903 5.00033 6.03032 5.00033 5.49987C5.00033 4.96943 5.21105 4.46072 5.58612 4.08564C5.96119 3.71056 6.4699 3.49984 7.00033 3.49984C7.53077 3.49984 8.03947 3.71056 8.41455 4.08564C8.78962 4.46072 9.00033 4.96943 9.00033 5.49987V5.49987Z'
					stroke='#373737'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
			<defs>
				<clipPath id='clip0_316_6784'>
					<rect width='14' height='14' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default ProfileIcon;
