import type { BeamerCallbackType } from 'types/beamer.types';

export const BEAMER_SELECTOR = 'whats-new-menu-item';

const BEAMER_ID = process.env.NEXT_PUBLIC_BEAMER_PRODUCT_ID;

// Beamer script tag singleton
let scriptRef: HTMLScriptElement | null = null;

const isBeamerLoaded = (): boolean => !!scriptRef;

export const loadBeamer = async (
	callback?: BeamerCallbackType
): Promise<void> => {
	if (isBeamerLoaded() || process.env.NODE_ENV === 'development') return;

	const BEAMER_URL = 'https://app.getbeamer.com/js/beamer-embed.js';

	if (!BEAMER_ID) {
		console.warn(
			'[Beamer] In order to use Beamer you need to add a `product_id`'
		);
		return;
	}

	window.beamer_config = {
		product_id: BEAMER_ID,
		selector: BEAMER_SELECTOR,
		display: 'right',
		bounce: false,
		button: false,
		lazy: true,
		callback,
	};

	scriptRef = document.createElement('script');
	scriptRef.type = 'text/javascript';
	scriptRef.defer = true;
	scriptRef.src = BEAMER_URL;

	const firstScript = document.getElementsByTagName('script')[0];
	firstScript?.parentNode?.insertBefore(scriptRef, firstScript);

	scriptRef.addEventListener('load', () => window.Beamer?.init(), {
		once: true,
	});
};

export const updateBeamer = async (): Promise<void> => {
	if (!isBeamerLoaded() || !window?.Beamer) {
		return;
	}
};

export const unloadBeamer = (): void => {
	if (!window?.Beamer || !scriptRef) {
		return;
	}

	window.Beamer.destroy();
	scriptRef.remove();
	scriptRef = null;
};
