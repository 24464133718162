import Row from 'components/Row';
import React, { InputHTMLAttributes } from 'react';
import FormElementContainer from '../FormElementContainer';
import { DefaultFormFieldProps, FieldSizes } from '../types';
import { StyledInputAddition, StyledInputRoot } from './TextInput.styles';

export interface TextInputRootProps
	extends InputHTMLAttributes<HTMLInputElement> {
	error?: string;
	fieldSize?: FieldSizes;
	inputStyle?: React.CSSProperties;
	prefixer?: React.ReactNode;
	suffix?: React.ReactNode;
	autoFocus?: boolean;
}

export type TextInputProps = TextInputRootProps & DefaultFormFieldProps;

const TextInput = React.forwardRef<any, TextInputProps>((props, ref) => {
	const {
		name,
		error,
		label,
		suggestion,
		hint,
		fieldSize = 'lg',
		style,
		inputStyle,
		width,
		prefixer,
		suffix,
		...otherProps
	} = props;
	return (
		<FormElementContainer
			label={label}
			error={error}
			suggestion={suggestion}
			name={name}
			hint={hint}
			width={width}
			size={fieldSize}
			style={style}
		>
			<Row style={{ position: 'relative' }}>
				<StyledInputAddition style={{ left: 0 }}>
					{prefixer}
				</StyledInputAddition>

				<StyledInputRoot
					ref={ref}
					name={name}
					error={error}
					fieldSize={fieldSize}
					width={width}
					withPrefixer={!!prefixer}
					withSuffix={!!suffix}
					style={inputStyle}
					{...otherProps}
				/>

				<StyledInputAddition style={{ right: 0 }}>{suffix}</StyledInputAddition>
			</Row>
		</FormElementContainer>
	);
});

export default TextInput;
