import Checkbox from 'components/Checkbox';
import { DefaultFormFieldProps } from 'components/Form/types';
import Row from 'components/Row';
import { Option } from 'components/Select';
import Typography from 'components/Typography';
import {
	ActionMeta,
	components,
	MenuPlacement,
	OptionProps,
} from 'react-select';
import { useTheme } from 'styled-components';

export interface CustomSelectProps extends DefaultFormFieldProps {
	onChange?: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
	options?: Option[];
	width?: string;
	menuPlacement?: MenuPlacement;
}

export const UNTAGGED_TRANSACTIONS = 'untaggedTransactions';
const TagOption = ({
	isSelected,
	data,
	...otherProps
}: OptionProps<unknown>) => {
	const { spacings } = useTheme();
	const { label, value } = data as Option;
	const isUntaggedTransaction = value === UNTAGGED_TRANSACTIONS;
	return (
		<components.Option data={data} isSelected={isSelected} {...otherProps}>
			<Row width={'100%'} rowGap={spacings[10]} alignItems='center'>
				<Checkbox checked={isSelected} onChange={() => null} />
				<Typography
					style={{ fontStyle: isUntaggedTransaction ? 'italic' : 'normal' }}
				>
					{label}
				</Typography>
			</Row>
		</components.Option>
	);
};

export { TagOption };
