import {
	addDays,
	addMonths,
	endOfDay,
	endOfMonth,
	endOfWeek,
	format,
	isSameDay,
	startOfDay,
	startOfMonth,
	startOfWeek,
	startOfYear,
} from 'date-fns';
import { Range } from 'react-date-range';

export function formatInUTC(date: Date, fmt: string) {
	return format(new Date(date.toISOString().slice(0, -1)), fmt);
}

export function getDateFormat(ms: string | number) {
	if (!ms) return '';
	return format(new Date(ms), 'MMM-dd-yyyy');
}

export function getTimeFormat(ms: string | number) {
	if (!ms) return '';
	return format(new Date(ms), 'HH:mm:ss');
}

export function getDateFormatInUTC(ms: string | number) {
	if (!ms) return '';
	return formatInUTC(new Date(ms), 'MMM-dd-yyyy');
}

export function getTimeFormatInUTC(ms: string | number) {
	if (!ms) return '';
	return formatInUTC(new Date(ms), 'HH:mm:ss');
}

export function getStartOfDayInUTC(date: Date) {
	return new Date(
		Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDay())
	).setUTCHours(0, 0, 0, 0);
}

export function getEndOfDayInUTC(date: Date) {
	const endOfToday = new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		23,
		59,
		59,
		999
	);

	return endOfToday;
}

export function getStartOfMonthInUTC(date: Date, diff = 0) {
	return new Date(
		Date.UTC(date.getUTCFullYear(), date.getUTCMonth() - diff, 1)
	).setUTCHours(0, 0, 0, 0);
}

export function getEndOfMonthInUTC(date: Date, diff = 0) {
	const startOfNextMonth = new Date(
		Date.UTC(date.getUTCFullYear(), date.getUTCMonth() - diff + 1, 1)
	).setUTCHours(0, 0, 0, 0);
	const endOfMonth = new Date(startOfNextMonth - 1);
	return endOfMonth.getTime();
}

export function getStartOfQuarterInUTC(date: Date) {
	const month = date.getUTCMonth();
	const startOfQuarterMonth = Math.floor(month / 3) * 3;
	return new Date(
		Date.UTC(date.getUTCFullYear(), startOfQuarterMonth, 1)
	).setUTCHours(0, 0, 0, 0);
}

export function getEndOfQuarterInUTC(date: Date) {
	const month = date.getUTCMonth();
	const startOfNextQuarterMonth = Math.floor(month / 3) * 3 + 3;
	const startOfNextQuarter = new Date(
		Date.UTC(date.getUTCFullYear(), startOfNextQuarterMonth, 1)
	).setUTCHours(0, 0, 0, 0);
	const endOfMonth = new Date(startOfNextQuarter - 1);
	return endOfMonth.getTime();
}

export function getUTCTime(localDate: Date) {
	return new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
}

export const definedDateRangeOptions = {
	startOfWeek: startOfWeek(new Date()),
	endOfWeek: endOfWeek(new Date()),
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOfMonth: startOfMonth(new Date()),
	endOfMonth: endOfMonth(new Date()),
	startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
	startOfLast7Days: startOfDay(addDays(new Date(), -6)),
	endOfLast7Days: endOfDay(new Date()),
	startOfLast30Days: startOfDay(addDays(new Date(), -29)),
	endOfLast30Days: endOfDay(new Date()),
	startOfLast60Days: startOfDay(addDays(new Date(), -59)),
	endOfLast60Days: endOfDay(new Date()),
	startOfLast90Days: startOfDay(addDays(new Date(), -89)),
	endOfLast90Days: endOfDay(new Date()),
	startOfLast3Months: startOfDay(startOfMonth(addMonths(new Date(), -3))),
	endOfLast3Months: endOfDay(endOfMonth(addMonths(new Date(), -1))),
	startOfLast6Months: startOfDay(startOfMonth(addMonths(new Date(), -6))),
	endOfLast6Months: endOfDay(endOfMonth(addMonths(new Date(), -1))),
	startOfThisYear: startOfDay(startOfYear(new Date())),
	endOfThisYear: endOfDay(new Date()),
	startOfAllTime: startOfDay(new Date('July 30 2015')),
};

export const defaultDateRange = [
	{
		startDate: definedDateRangeOptions.startOfLast30Days,
		endDate: definedDateRangeOptions.endOfLast30Days,
		key: 'selection',
	},
];

const staticRangeHandler = {
	range: {},
	isSelected(range: Range) {
		const definedRange = (this.range as any)();
		return (
			range.startDate &&
			range.endDate &&
			isSameDay(range.startDate, definedRange.startDate) &&
			isSameDay(range.endDate, definedRange.endDate)
		);
	},
};

export function createStaticRanges(ranges: any) {
	return ranges.map((range: any) => ({ ...staticRangeHandler, ...range }));
}

const defaultStaticRangesTill3MonthsOptions = [
	{
		label: 'This Month',
		range: () => ({
			startDate: definedDateRangeOptions.startOfMonth,
			endDate: definedDateRangeOptions.endOfMonth,
		}),
	},
	{
		label: 'Previous Month',
		range: () => ({
			startDate: definedDateRangeOptions.startOfLastMonth,
			endDate: definedDateRangeOptions.endOfLastMonth,
		}),
	},
	{
		label: 'Last 30 days',
		range: () => ({
			startDate: definedDateRangeOptions.startOfLast30Days,
			endDate: definedDateRangeOptions.endOfLast30Days,
		}),
	},
	{
		label: 'Last 3 months',
		range: () => ({
			startDate: definedDateRangeOptions.startOfLast3Months,
			endDate: definedDateRangeOptions.endOfLast3Months,
		}),
	},
];

export const defaultStaticRangesTill3Months = createStaticRanges(
	defaultStaticRangesTill3MonthsOptions
);

export const defaultStaticRanges = createStaticRanges([
	...defaultStaticRangesTill3MonthsOptions,
	{
		label: 'Last 6 months',
		range: () => ({
			startDate: definedDateRangeOptions.startOfLast6Months,
			endDate: definedDateRangeOptions.endOfLast6Months,
		}),
	},
	{
		label: 'This year',
		range: () => ({
			startDate: definedDateRangeOptions.startOfThisYear,
			endDate: definedDateRangeOptions.endOfThisYear,
		}),
	},
	{
		label: 'All time',
		range: () => ({
			startDate: definedDateRangeOptions.startOfAllTime,
			endDate: definedDateRangeOptions.endOfToday,
		}),
	},
]);
