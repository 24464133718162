import React, { ReactNode } from 'react';
import ButtonBase, { ButtonBaseProps } from './ButtonBase';

type IIconButtonProps = Omit<
	ButtonBaseProps,
	'children' | 'variant' | 'leftIcon' | 'rightIcon' | 'isLoading'
>;
export interface IconButtonProps extends IIconButtonProps {
	icon: ReactNode;
}

const IconButton = React.forwardRef<
	HTMLButtonElement,
	IconButtonProps & React.ComponentPropsWithoutRef<'button'>
>((props, ref) => {
	const { icon, color, size, type, ...otherProps } = props;
	return (
		<ButtonBase
			variant='icon'
			type={type || 'button'}
			color={color}
			size={size}
			isLoading={false}
			ref={ref}
			{...otherProps}
		>
			{icon}
		</ButtonBase>
	);
});

export default IconButton;
