import Big from 'big.js';
import { palette } from 'common/charts/Charts.constants';
import { format } from 'date-fns';
import { colors } from 'styles';
import { getEndOfMonthInUTC, getStartOfMonthInUTC } from 'utils/date';
import {
	CashflowAPIDataType,
	CashflowDirectionEnum,
	CashflowLabelType,
	IntervalType,
} from '../CashFlow.types';
import {
	CashFlowBreakdownDataGenericType,
	CashFlowBreakdownDataType,
	CashFlowFiatDataGenericType,
	CashFlowFiatDataType,
} from '../charts/Charts.types';

// ------------------------------------------
// Date Helpers
// ------------------------------------------

function getStartOfNextDayInUTC(date: Date) {
	const nextDay = new Date(date.getTime() + 86400000); // Add 24 hours in milliseconds to get the next day
	nextDay.setUTCHours(0, 0, 0, 0); // Set the time to 00:00:00 UTC
	return nextDay;
}

function getStartOfNextWeekInUTC(date: Date) {
	const nextWeekStart = new Date(
		date.getTime() + (7 - date.getUTCDay() + 1) * 24 * 60 * 60 * 1000
	); // Add the remaining days until the next Sunday
	nextWeekStart.setUTCHours(0, 0, 0, 0); // Set the time to 00:00:00 UTC
	return nextWeekStart;
}

function getStartOfNextMonthInUTC(date: Date) {
	return new Date(
		Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 1, 0, 0, 0, 0)
	);
}

function getStartOfNextQuarterInUTC(date: Date) {
	const currentQuarter = Math.floor(date.getUTCMonth() / 3);
	const nextQuarterMonth = ((currentQuarter + 1) * 3) % 12;
	const year =
		nextQuarterMonth === 0 ? date.getUTCFullYear() + 1 : date.getUTCFullYear();
	const nextQuarterStart = new Date(
		Date.UTC(year, nextQuarterMonth, 1, 0, 0, 0, 0)
	);
	return nextQuarterStart;
}

function getStartOfThisQuarterInUTC(date: Date) {
	const currentQuarter = Math.floor(date.getUTCMonth() / 3);
	const thisQuarterStart = new Date(
		Date.UTC(date.getUTCFullYear(), currentQuarter * 3, 1, 0, 0, 0, 0)
	);
	return thisQuarterStart;
}

function getStartOfThisYearInUTC(date: Date) {
	const thisYearStart = new Date(
		Date.UTC(date.getUTCFullYear(), 0, 1, 0, 0, 0, 0)
	);
	return thisYearStart;
}

function getStartOfNextYearInUTC(date: Date) {
	const nextYearStart = new Date(
		Date.UTC(date.getUTCFullYear() + 1, 0, 1, 0, 0, 0, 0)
	);
	return nextYearStart;
}

function isSameDay(date1: Date, date2: Date) {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
}

function isSameWeek(date1: Date, date2: Date) {
	const weekStart1 = new Date(date1);
	weekStart1.setUTCDate(date1.getUTCDate() - date1.getUTCDay());

	const weekStart2 = new Date(date2);
	weekStart2.setUTCDate(date2.getUTCDate() - date2.getUTCDay());

	return (
		weekStart1.getUTCFullYear() === weekStart2.getUTCFullYear() &&
		getWeekNumber(weekStart1) === getWeekNumber(weekStart2)
	);
}

function getWeekNumber(date: Date) {
	const startOfYear = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
	const weekNumber = Math.ceil(
		((date.getTime() - startOfYear.getTime()) / 86400000 +
			startOfYear.getUTCDay() +
			1) /
			7
	);
	return weekNumber;
}

function isSameMonth(date1: Date, date2: Date) {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth()
	);
}

function getQuarter(date: Date) {
	const month = date.getMonth();
	return Math.floor(month / 3) + 1;
}

function isSameQuarter(date1: Date, date2: Date) {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		getQuarter(date1) === getQuarter(date2)
	);
}

function isSameYear(date1: Date, date2: Date) {
	return date1.getFullYear() === date2.getFullYear();
}

// ------------------------------------------
// End of Date Helpers
// ------------------------------------------

const sortDataByTimestamp = (data: CashflowAPIDataType[]) => {
	return data.sort((a, b) =>
		new Date(a.timestamp).getTime() > new Date(b.timestamp).getTime() ? 1 : -1
	);
};

// get each day between the start of month of initial date & end of month of final date
const getDatesByInterval = (
	initialDate: string,
	finalDate: string,
	interval: IntervalType
) => {
	const startDate = new Date(getStartOfMonthInUTC(new Date(initialDate)));
	const endDate = new Date(getEndOfMonthInUTC(new Date(finalDate)));
	const dates: Date[] = [];
	if (interval === 'DAILY') {
		let day = startDate;
		while (day < endDate) {
			dates.push(day);
			day = getStartOfNextDayInUTC(day);
		}
	}

	if (interval === 'WEEKLY') {
		let week = startDate;
		while (week < endDate) {
			dates.push(week);
			week = getStartOfNextWeekInUTC(week);
		}
	}
	if (interval === 'MONTHLY') {
		let month = startDate;
		while (month < endDate) {
			dates.push(month);
			month = getStartOfNextMonthInUTC(month);
		}
	}
	if (interval === 'QUARTERLY') {
		let quarter = getStartOfThisQuarterInUTC(startDate);
		while (quarter < getStartOfNextQuarterInUTC(endDate) && dates.length < 5) {
			dates.push(quarter);
			quarter = getStartOfNextQuarterInUTC(quarter);
		}
	}
	if (interval === 'YEARLY') {
		let year = getStartOfThisYearInUTC(startDate);
		const endYear = getStartOfNextYearInUTC(endDate);
		while (year < endYear) {
			dates.push(year);
			year = getStartOfNextYearInUTC(year);
		}
	}
	return dates;
};

function checkInterval({
	timestamp,
	date,
	interval,
}: {
	timestamp: Date;
	date: Date;
	interval: IntervalType;
}) {
	if (interval === 'DAILY') return isSameDay(timestamp, date);
	if (interval === 'WEEKLY') return isSameWeek(timestamp, date);
	if (interval === 'MONTHLY') return isSameMonth(timestamp, date);
	if (interval === 'QUARTERLY') return isSameQuarter(timestamp, date);
	if (interval === 'YEARLY') return isSameYear(timestamp, date);
	return false;
}

// fiat incoming/outgoing
export const prepareCashFlowFiatData = (
	cashFlowData: CashflowAPIDataType[],
	interval: IntervalType
): CashFlowFiatDataType[] => {
	const sortedCashflowData = sortDataByTimestamp(cashFlowData);
	const dates = getDatesByInterval(
		cashFlowData[0].timestamp,
		cashFlowData[cashFlowData.length - 1].timestamp,
		interval
	);

	const data: CashFlowFiatDataGenericType[] = [];
	// for each day, add incoming and outgoing usd values and store in data
	for (let i = 0; i < dates.length; i++) {
		const { incomingAtDate, outgoingAtDate } = sortedCashflowData.reduce(
			(
				{ incomingAtDate, outgoingAtDate },
				{ timestamp, fiatAmount, direction }
			) => {
				if (
					checkInterval({
						timestamp: new Date(timestamp),
						date: dates[i],
						interval,
					})
				) {
					if (direction === CashflowDirectionEnum.ERC20_INCOMING) {
						incomingAtDate = Big(incomingAtDate)
							.add(Big(fiatAmount || 0))
							.toNumber();
					}
					if (direction === CashflowDirectionEnum.ERC20_OUTGOING) {
						outgoingAtDate = Big(outgoingAtDate)
							.add(Big(fiatAmount || 0))
							.toNumber();
					}
				}

				return { incomingAtDate, outgoingAtDate };
			},
			{ incomingAtDate: 0, outgoingAtDate: 0 }
		);

		data.push({
			time: dates[i],
			incomingFiat: incomingAtDate,
			outgoingFiat: outgoingAtDate,
		});
	}

	if (interval === 'DAILY' || interval === 'WEEKLY')
		return data.map(({ time, ...rest }) => ({
			time: format(time, 'dd MMM yyyy'),
			...rest,
		}));

	if (interval === 'MONTHLY')
		return data.map(({ time, ...rest }) => ({
			time: format(time, 'MMM yyyy'),
			...rest,
		}));

	if (interval === 'QUARTERLY')
		return data.map(({ time, ...rest }) => ({
			time: format(time, 'QQQ yyyy'),
			...rest,
		}));

	// if (interval === 'YEARLY')
	return data.map(({ time, ...rest }) => ({
		time: format(time, 'yyyy'),
		...rest,
	}));
};

// token wise incoming/outgoing
export const prepareCashFlowTokensData = (
	cashFlowData: CashflowAPIDataType[],
	interval: IntervalType
): CashFlowBreakdownDataType[] => {
	const sortedCashflowData = sortDataByTimestamp(cashFlowData);
	const dates = getDatesByInterval(
		sortedCashflowData[0].timestamp,
		sortedCashflowData[sortedCashflowData.length - 1].timestamp,
		interval
	);

	const data: CashFlowBreakdownDataGenericType[] = [];

	// { 1_DAI: 0, 137_USDC: 0, ...} for all unique tokens
	const initialTokensMap = sortedCashflowData.reduce(
		(hashmap, { tokenSymbol, networkId }) => {
			if (networkId && tokenSymbol) {
				const key = `${networkId}_${tokenSymbol}`;
				if (!hashmap[key]) hashmap[key] = 0;
			}
			return hashmap;
		},
		{} as { [key: string]: number }
	);

	for (let i = 0; i < dates.length; i++) {
		const {
			incomingTokensMap,
			outgoingTokensMap,
			incomingFiatMap,
			outgoingFiatMap,
		} = sortedCashflowData.reduce(
			(
				{
					incomingTokensMap,
					outgoingTokensMap,
					incomingFiatMap,
					outgoingFiatMap,
				},
				{
					timestamp,
					tokenAmount,
					fiatAmount,
					tokenSymbol,
					networkId,
					direction,
				}
			) => {
				if (tokenSymbol) {
					const key = `${networkId}_${tokenSymbol}`;
					if (
						checkInterval({
							timestamp: new Date(timestamp),
							date: dates[i],
							interval,
						})
					) {
						if (direction === CashflowDirectionEnum.ERC20_INCOMING) {
							// incoming token
							if (incomingTokensMap[key]) {
								incomingTokensMap[key] = Big(incomingTokensMap[key])
									.add(Big(tokenAmount || 0))
									.toNumber();
							} else {
								incomingTokensMap[key] = Big(tokenAmount || 0).toNumber();
							}

							// incoming fiat
							if (incomingFiatMap[key]) {
								incomingFiatMap[key] = Big(incomingFiatMap[key])
									.add(Big(fiatAmount || 0))
									.toNumber();
							} else {
								incomingFiatMap[key] = Big(fiatAmount || 0).toNumber();
							}
						}

						if (direction === CashflowDirectionEnum.ERC20_OUTGOING) {
							// outgoing token
							if (outgoingTokensMap[key]) {
								outgoingTokensMap[key] = Big(outgoingTokensMap[key])
									.add(Big(tokenAmount || 0))
									.toNumber();
							} else {
								outgoingTokensMap[key] = Big(tokenAmount || 0).toNumber();
							}
							// outgoing fiat
							if (outgoingFiatMap[key]) {
								outgoingFiatMap[key] = Big(outgoingFiatMap[key])
									.add(Big(fiatAmount || 0))
									.toNumber();
							} else {
								outgoingFiatMap[key] = Big(fiatAmount || 0).toNumber();
							}
						}
					}
				}

				return {
					incomingTokensMap,
					outgoingTokensMap,
					incomingFiatMap,
					outgoingFiatMap,
				};
			},
			{
				incomingTokensMap: {},
				outgoingTokensMap: {},
				incomingFiatMap: {},
				outgoingFiatMap: {},
			} as {
				incomingTokensMap: Record<string, number>;
				outgoingTokensMap: Record<string, number>;
				incomingFiatMap: Record<string, number>;
				outgoingFiatMap: Record<string, number>;
			}
		);

		data.push({
			time: dates[i],
			incomingTokens: {
				...initialTokensMap,
				...incomingTokensMap,
			},
			outgoingTokens: {
				...initialTokensMap,
				...outgoingTokensMap,
			},
			incomingFiat: {
				...initialTokensMap,
				...incomingFiatMap,
			},
			outgoingFiat: {
				...initialTokensMap,
				...outgoingFiatMap,
			},
		});
	}

	if (interval === 'DAILY' || interval === 'WEEKLY')
		return data.map(({ time, ...rest }) => ({
			time: format(time, 'dd MMM yyyy'),
			...rest,
		}));

	if (interval === 'MONTHLY')
		return data.map(({ time, ...rest }) => ({
			time: format(time, 'MMM yyyy'),
			...rest,
		}));

	if (interval === 'QUARTERLY')
		return data.map(({ time, ...rest }) => ({
			time: format(time, 'QQQ yyyy'),
			...rest,
		}));

	// if (interval === 'YEARLY')
	return data.map(({ time, ...rest }) => ({
		time: format(time, 'yyyy'),
		...rest,
	}));
};

export const UNTAGGED_TXS = 'Untagged Transactions';

const aggregateByLabel = (
	labels: CashflowLabelType[],
	lMap: Record<string, number>,
	value: number
) => {
	labels.forEach((label) => {
		if (lMap[label.name]) {
			lMap[label.name] = Big(lMap[label.name])
				.add(Big(value || 0))
				.toNumber();
		} else {
			lMap[label.name] = Big(value || 0).toNumber();
		}
	});
};

// label wise incoming/outgoing
export const prepareCashFlowLabelsData = (
	cashFlowData: CashflowAPIDataType[],
	interval: IntervalType
): CashFlowBreakdownDataType[] => {
	const sortedCashflowData = sortDataByTimestamp(cashFlowData);
	const dates = getDatesByInterval(
		sortedCashflowData[0].timestamp,
		sortedCashflowData[sortedCashflowData.length - 1].timestamp,
		interval
	);

	const data: CashFlowBreakdownDataGenericType[] = [];

	// { Salary: 0, Expense: 0, ...} for all unique labels
	const initialLabelsMap = sortedCashflowData.reduce(
		(hashmap, { txnLabels }) => {
			if (txnLabels) {
				txnLabels.forEach((label) => {
					if (!hashmap[label.name]) {
						hashmap[label.name] = 0;
					}
				});
			}
			return hashmap;
		},
		{} as Record<string, number>
	);
	initialLabelsMap[UNTAGGED_TXS] = 0;

	for (let i = 0; i < dates.length; i++) {
		const {
			incomingLabelsMap,
			incomingUntaggedTxsMap,
			outgoingLabelsMap,
			outgoingUntaggedTxsMap,
		} = sortedCashflowData.reduce(
			(
				{
					incomingLabelsMap,
					incomingUntaggedTxsMap,
					outgoingLabelsMap,
					outgoingUntaggedTxsMap,
				},
				{ timestamp, fiatAmount, txnLabels, direction }
			) => {
				if (
					checkInterval({
						timestamp: new Date(timestamp),
						date: dates[i],
						interval,
					})
				) {
					// incoming
					if (direction === CashflowDirectionEnum.ERC20_INCOMING) {
						if (txnLabels?.length) {
							aggregateByLabel(txnLabels, incomingLabelsMap, fiatAmount);
						} else {
							if (incomingUntaggedTxsMap[UNTAGGED_TXS]) {
								incomingUntaggedTxsMap[UNTAGGED_TXS] = Big(
									incomingUntaggedTxsMap[UNTAGGED_TXS]
								)
									.add(Big(fiatAmount || 0))
									.toNumber();
							} else {
								incomingUntaggedTxsMap[UNTAGGED_TXS] = Big(
									fiatAmount || 0
								).toNumber();
							}
						}
					}
					// outgoing
					if (direction === CashflowDirectionEnum.ERC20_OUTGOING) {
						if (txnLabels?.length) {
							aggregateByLabel(txnLabels, outgoingLabelsMap, fiatAmount);
						} else {
							if (outgoingUntaggedTxsMap[UNTAGGED_TXS]) {
								outgoingUntaggedTxsMap[UNTAGGED_TXS] = Big(
									outgoingUntaggedTxsMap[UNTAGGED_TXS]
								)
									.add(Big(fiatAmount || 0))
									.toNumber();
							} else {
								outgoingUntaggedTxsMap[UNTAGGED_TXS] = Big(
									fiatAmount || 0
								).toNumber();
							}
						}
					}
				}

				return {
					incomingLabelsMap,
					incomingUntaggedTxsMap,
					outgoingLabelsMap,
					outgoingUntaggedTxsMap,
				};
			},
			{
				incomingLabelsMap: {},
				incomingUntaggedTxsMap: {},
				outgoingLabelsMap: {},
				outgoingUntaggedTxsMap: {},
			} as {
				incomingLabelsMap: Record<string, number>;
				incomingUntaggedTxsMap: Record<string, number>;
				outgoingLabelsMap: Record<string, number>;
				outgoingUntaggedTxsMap: Record<string, number>;
			}
		);

		data.push({
			time: dates[i],
			incomingFiat: {
				...initialLabelsMap,
				...incomingUntaggedTxsMap,
				...incomingLabelsMap,
			},
			outgoingFiat: {
				...initialLabelsMap,
				...outgoingUntaggedTxsMap,
				...outgoingLabelsMap,
			},
			incomingTokens: {},
			outgoingTokens: {},
		});
	}

	if (interval === 'DAILY' || interval === 'WEEKLY')
		return data.map(({ time, ...rest }) => ({
			time: format(time, 'dd MMM yyyy'),
			...rest,
		}));

	if (interval === 'MONTHLY')
		return data.map(({ time, ...rest }) => ({
			time: format(time, 'MMM yyyy'),
			...rest,
		}));
	if (interval === 'QUARTERLY')
		return data.map(({ time, ...rest }) => ({
			time: format(time, 'QQQ yyyy'),
			...rest,
		}));

	// if (interval === 'YEARLY')
	return data.map(({ time, ...rest }) => ({
		time: format(time, 'yyyy'),
		...rest,
	}));
};

export const prepareCashFlowLabelsPieChartData = (
	cashFlowData: CashflowAPIDataType[],
	txDirection: CashflowDirectionEnum
) => {
	const pieData = cashFlowData.reduce(
		(lMap, { fiatAmount, txnLabels, direction }) => {
			if (direction === txDirection) {
				if (txnLabels.length) {
					txnLabels.forEach((label) => {
						if (lMap[label.name]) {
							lMap[label.name] = Big(lMap[label.name])
								.add(Big(fiatAmount || 0))
								.toNumber();
						} else {
							lMap[label.name] = Big(fiatAmount || 0).toNumber();
						}
					});
				} else {
					if (lMap[UNTAGGED_TXS]) {
						lMap[UNTAGGED_TXS] = Big(lMap[UNTAGGED_TXS])
							.add(Big(fiatAmount || 0))
							.toNumber();
					} else {
						lMap[UNTAGGED_TXS] = Big(fiatAmount || 0).toNumber();
					}
				}
			}

			return lMap;
		},
		{} as Record<string, number>
	);

	return Object.keys(pieData).map((txnLabels, index) => ({
		name: txnLabels,
		value: pieData[txnLabels],
		fill:
			txnLabels === UNTAGGED_TXS
				? colors.primary[100]
				: palette[index % palette.length],
	}));
};

export const getCashflowStats = (cashFlowData: CashflowAPIDataType[]) => {
	const {
		incomingTotal,
		incomingTxCount,
		outgoingTotal,
		outgoingTxCount,
		netTxCount,
	} = cashFlowData.reduce(
		(
			{
				incomingTotal,
				incomingTxCount,
				outgoingTotal,
				outgoingTxCount,
				netTxCount,
			},
			{ fiatAmount, direction }
		) => {
			if (fiatAmount) {
				if (direction === CashflowDirectionEnum.ERC20_INCOMING) {
					incomingTotal = Big(incomingTotal).add(Big(fiatAmount)).toNumber();
					incomingTxCount += 1;
				} else if (direction === CashflowDirectionEnum.ERC20_OUTGOING) {
					outgoingTotal = Big(outgoingTotal).add(Big(fiatAmount)).toNumber();
					outgoingTxCount += 1;
				}

				netTxCount += 1;
			}
			return {
				incomingTotal,
				incomingTxCount,
				outgoingTotal,
				outgoingTxCount,
				netTxCount,
			};
		},
		{
			incomingTotal: 0,
			incomingTxCount: 0,
			outgoingTotal: 0,
			outgoingTxCount: 0,
			netTxCount: 0,
		}
	);
	return {
		incomingTotal,
		incomingTxCount,
		outgoingTotal,
		outgoingTxCount,
		netflow: Big(incomingTotal).sub(Big(outgoingTotal)).toNumber(),
		netTxCount,
	};
};
