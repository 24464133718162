import Row from 'components/Row';
import styled from 'styled-components';
import { breakpoints } from 'styles';

export const PlainHeaderContainer = styled(Row)`
	justify-content: center;

	@media ${breakpoints.tablet} {
		justify-content: space-between;
	}
`;
