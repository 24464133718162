import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn: SENTRY_DSN,
	environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
	tracesSampleRate: 1.0,

	integrations: [
		new BrowserTracing({
			tracingOrigins: ['*'],
		}),
	],

	tunnel: '/api/tunnel',

	// debug: true
});
