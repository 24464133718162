import { TypographyProps } from 'components/Typography';
import Link, { LinkProps } from 'next/link';
import React, { CSSProperties, ReactNode } from 'react';

export type LinkTypographyProps = {
	typographyProps?: Omit<TypographyProps, 'children'>;
	underline?: boolean;
};

export interface InternalLinkProps extends LinkProps, LinkTypographyProps {
	children?: ReactNode;
	style?: CSSProperties;
}
export interface ExternalLinkProps extends React.ComponentProps<'a'> {
	children?: ReactNode;
}

export const InternalLink = ({
	children,
	href,
	style,
	...otherLinkProps
}: InternalLinkProps) => {
	return (
		<Link {...otherLinkProps} href={href}>
			<a style={style}>{children}</a>
		</Link>
	);
};

export const ExternalLink = ({
	children,
	href,
	...otherProps
}: ExternalLinkProps) => {
	return (
		<a href={href} target='_blank' rel='noreferrer noopener' {...otherProps}>
			{children}
		</a>
	);
};
