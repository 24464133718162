import Row from 'components/Row';
import styled, { css } from 'styled-components';
import { rem } from 'styles';
import { FieldSizes } from './types';

/**
 * To compose a field, use the following pattern:
 *
 * <Container>
 * 		<Row>
 * 			<Label/>
 * 			<Suggestion/>
 * 		</Row>
 * 		<FieldComponent />   <--- The actual input component
 * 		<Error />
 * 		<Hint />
 * </Container>
 *
 * */

const StyledFieldContainer = styled.div<{
	size?: FieldSizes;
	width?: string | number;
}>`
	display: flex;
	flex-direction: column;
	min-width: ${({ width }) => (width ? 'auto' : rem(140))};
	${({ size = 'lg', width = rem(440) }) =>
		size != 'md' &&
		`max-width: ${width};
	width: 100%;`}
`;

const StyledFieldRow = styled(Row)`
	justify-content: space-between;
`;

const StyledFieldLabel = styled.label<{ size?: FieldSizes }>(
	({ theme, size = 'lg' }) => {
		const { colors, fontWeights, fontSizes } = theme;
		return css`
			color: ${colors.grey[900]};
			font-weight: ${fontWeights.normal};
			font-size: ${size === 'lg'
				? fontSizes.md
				: size === 'md' && fontSizes.sm};
		`;
	}
);

const FormContainer = styled.form<{ width?: string }>`
	max-width: ${({ width }) => `${width || rem(440)}`};
	margin: auto;
	width: 100%;
`;

export {
	StyledFieldContainer,
	StyledFieldLabel,
	StyledFieldRow,
	FormContainer,
};
