import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	AutomatedPaymentsTypesEnum,
	StreamSelectionType,
} from './AutomatedPayments.types';

type AutomatedStreamDataType = {
	selected: StreamSelectionType | null;
	selectedByAccount: { [key: string]: boolean };
};

const initialStreamState = {
	selected: null,
	selectedByAccount: {},
};

type AutomatedPaymentsState = {
	[key in AutomatedPaymentsTypesEnum]: AutomatedStreamDataType;
};

const initialState: AutomatedPaymentsState = {
	[AutomatedPaymentsTypesEnum.STREAMS]: initialStreamState,
};

const slice = createSlice({
	name: 'transactions/view-automated',
	initialState,
	reducers: {
		setSelectedStreams(
			state,
			action: PayloadAction<StreamSelectionType | null>
		) {
			state.STREAMS.selected = action.payload;

			if (action.payload) {
				if (action.payload?.account?.accountAddress)
					state.STREAMS.selectedByAccount[
						action.payload.account.accountAddress
					] = true;
			} else {
				state.STREAMS.selectedByAccount = {};
			}
		},

		reset() {
			return initialState;
		},
	},
});

export const {
	actions: automatedPaymentsActions,
	reducer: automatedPaymentsReducer,
} = slice;
