import { useEffect, useState } from 'react';

type CopiedValue = string;
type CopyFn = (text: CopiedValue) => Promise<boolean>;

const useCopyText = () => {
	const [isCopied, setIsCopied] = useState(false);

	useEffect(() => {
		const to = setTimeout(() => {
			if (isCopied) {
				setIsCopied(false);
			}
		}, 1000);

		return () => clearTimeout(to);
	}, [isCopied]);

	const copyFn: CopyFn = async (text: CopiedValue) => {
		if (isCopied) return false;
		if (!navigator.clipboard) {
			console.warn('Clipboard not supported');
			return false;
		}
		try {
			await navigator.clipboard.writeText(text);
			setIsCopied(true);
			return true;
		} catch (e) {
			console.warn('Copy failed', e);
			return false;
		}
	};

	return { isCopied, copyFn };
};

export { useCopyText };
