import { ReactElement, ReactNode } from 'react';
import { Position, Theme, Tooltip } from 'react-tippy';

interface TooltipWrapperProps {
	title?: string;
	position?: Position;
	children: ReactNode;
	hideOnClick?: boolean;
	arrow?: boolean;
	onHide?: VoidFunction | undefined;
	interactive?: boolean;
	useContext?: boolean;
	theme?: Theme;
	html?: ReactElement;
}

function TooltipWrapper(props: TooltipWrapperProps) {
	const {
		children,
		title,
		position = 'top',
		hideOnClick = false,
		arrow = true,
		onHide = () => {
			return;
		},
		interactive = false,
		useContext = false,
		theme = 'dark',
		html,
	} = props;
	return (
		<Tooltip
			position={position}
			title={title}
			hideOnClick={hideOnClick}
			arrow={arrow}
			onRequestClose={onHide}
			tabIndex={0}
			interactive={interactive}
			useContext={useContext}
			theme={theme}
			html={html}
			style={{ display: 'flex' }}
		>
			{children}
		</Tooltip>
	);
}

export { TooltipWrapper };
export type { TooltipWrapperProps };
