import ConnectedWallet from 'common/connected-wallet';
import EditProfileDrawer from 'common/connected-wallet/EditProfileDrawer';
import Img from 'components/Img';
import { InternalLink } from 'components/Link';
import paths from 'constants/paths';
import { spacings } from 'styles/constants/spacings';
import { PlainHeaderContainer } from './Header.styles';

export const CoinshiftLogo = () => (
	<InternalLink href={paths.root}>
		<Img src={'/assets/logos/coinshift.svg'} alt='coinshift' />
	</InternalLink>
);

const Header = () => {
	return (
		<>
			<PlainHeaderContainer padding={spacings[30]}>
				<CoinshiftLogo />
				<ConnectedWallet />
			</PlainHeaderContainer>
			<EditProfileDrawer />
		</>
	);
};

export default Header;
