import { IEntities } from 'constants/entities';
import {
	DisplayType,
	Transaction,
} from 'screens/organisations/dashboard/apps/Apps.types';
import { AutomatedPaymentsTypesEnum } from 'screens/organisations/dashboard/automated-payments/all/AutomatedPayments.types';
import { PortfolioHistoryQueryParams } from 'screens/organisations/dashboard/reporting/portfolio-history/PortfolioHistory.types';
import { TxQueryParams } from 'screens/organisations/dashboard/transactions/all/Transactions.queries';
import { ViewTxStatusEnum } from 'screens/organisations/dashboard/transactions/all/Transactions.types';
import { AddressAndNetworkId } from 'screens/organisations/dashboard/user-management/manage-signers/ManageAccount.queries';

const contacts = {
	all: ['contacts'] as const,
};

const tags = {
	all: ['tags'] as const,
	byEntityType: (entityType: IEntities) => [...tags.all, entityType] as const,
	byEntityTypeAndStatus: (entityType: IEntities, status: string) =>
		[...tags.byEntityType(entityType), status] as const,
};

const transactions = {
	all: ['transactions'] as const,
	byStatus: (status: ViewTxStatusEnum) =>
		[...transactions.all, status] as const,
	byStatusParams: (status: ViewTxStatusEnum, params: TxQueryParams) =>
		[...transactions.byStatus(status), params] as const,
	filters: () => [...transactions.all, 'filters'] as const,
	byId: (id: string) => [...transactions.all, id] as const,
	executableById: (id: string) =>
		[...transactions.all, 'executable', id] as const,
};

const accounts = {
	all: ['accounts'] as const,
	filtered: (addresses: string[]) => [...accounts.all, addresses] as const,
};

const tokens = {
	all: ['tokens'] as const,
	byAccount: (account: AddressAndNetworkId) =>
		[...tokens.all, account] as const,
	byAccounts: (accounts: AddressAndNetworkId[]) =>
		[...tokens.all, accounts] as const,
	superTokens: (networkId: number) =>
		[...tokens.all, 'superTokens', networkId] as const,
};

const safes = {
	all: ['safes'] as const,
};

const networks = {
	all: ['networks'] as const,
	byId: (id = '') => [...networks.all, id] as const,
	gasPrice: (id = '') => [...networks.byId(id), 'gasPrice'] as const,
};

const users = {
	all: ['users'] as const,
	byAddress: (address = '') => [...users.all, address] as const,
	addressAuthToken: (address = '') =>
		[...users.byAddress(address), 'authToken'] as const,
};

const notifications = {
	all: ['notifications'] as const,
};

const orgUsers = {
	all: ['orgUsers'] as const,
	byStatus: (status = '') => [...orgUsers.all, status] as const,
};

const coinshift = {
	all: ['coinshift'] as const,
	stats: () => [...coinshift.all, 'stats'] as const,
};

const organisations = {
	all: ['organisations'] as const,
	bySlug: () => [...organisations.all, 'bySlug'] as const,
	balance: () => [...organisations.all, 'balance'] as const,
	nfts: () => [...organisations.all, 'nfts'] as const,
	nftsFiltered: (filteredAccounts?: string[]) =>
		[...organisations.nfts(), filteredAccounts] as const,
	balanceFiltered: (filteredAccounts?: string[]) =>
		[...organisations.balance(), filteredAccounts] as const,
	directionBalance: () => [...organisations.all, 'directionBalance'] as const,
	directionBalanceFiltered: (accounts?: string[]) =>
		[...organisations.directionBalance(), accounts] as const,
};

const migration = {
	all: ['migration'] as const,
	eligible: () => [...migration.all, 'eligible'] as const,
};

const waitlist = {
	all: ['waitlist'] as const,
};

const automatedPayments = {
	all: ['automatedPayments'] as const,
	byType: (type: AutomatedPaymentsTypesEnum) =>
		[...automatedPayments.all, type] as const,
};

const portfolioHistory = {
	all: (params?: PortfolioHistoryQueryParams) =>
		['portfolioHistory', params] as const,
	byId: (id: string) => [...portfolioHistory.all(), id] as const,
};

const cashflow = {
	get: (filteredAccounts?: string[]) => ['cashflow', filteredAccounts] as const,
};

const enriched = {
	all: ['enriched'] as const,
	byDisplay: (display: DisplayType) => [...enriched.all, display] as const,
	byDisplayTransactions: (display: DisplayType, transactions: Transaction[]) =>
		[...enriched.byDisplay(display), transactions] as const,
};

const notificationSettings = {
	getOrgConnections: (slug: string) =>
		['notificationSettings', 'organisationConnections', slug] as const,
	getAllAccountConnections: (slug: string) =>
		['notificationSettings', 'accountConnections', slug] as const,
	connectUrl: ['connectUrl'] as const,
};

const queryKeys = {
	accounts,
	automatedPayments,
	cashflow,
	coinshift,
	contacts,
	enriched,
	migration,
	networks,
	notifications,
	notificationSettings,
	orgUsers,
	organisations,
	portfolioHistory,
	safes,
	tags,
	tokens,
	transactions,
	users,
	waitlist,
};

export { queryKeys };
