import axios from 'axios';
import { handleUnauthorized, isUnauthorized } from './auth';
import { showError } from './toast';

export const ERROR_CODES = {
	UNKNOWN_ERROR: 'UNKNOWN_ERROR',
	NOT_FOUND: 'NOT_FOUND',
	USER_NOT_EXIST: 'USER_NOT_EXIST',
	SIGNUP_PROCESS_INCOMPLETE: 'SIGNUP_PROCESS_INCOMPLETE',
	USER_ALREADY_CREATED: 'USER_ALREADY_CREATED',
	UNAUTHORIZED: 'UNAUTHORIZED',
	REFERRAL_CODE_INVALID: 'REFERRAL_CODE_INVALID',
};

export const WALLET_ERROR_CODES = {
	USER_DENIED_MESSAGE_SIGNATURE: 4001,
};

type ErrorWithMessage = {
	message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
	return (
		typeof error === 'object' &&
		error !== null &&
		'message' in error &&
		typeof (error as Record<string, unknown>).message === 'string'
	);
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
	if (isErrorWithMessage(maybeError)) return maybeError;
	else if (typeof maybeError === 'string') return { message: maybeError };

	try {
		return new Error(JSON.stringify(maybeError));
	} catch {
		// error stringifying maybeError
		return new Error(String(maybeError));
	}
}

export function getErrorMessage(error: unknown) {
	if (axios.isAxiosError(error)) {
		const { response, message } = error;
		const errorMessage =
			response?.data?.error?.data ||
			response?.data?.error?.description ||
			message;
		return errorMessage;
	}
	return toErrorWithMessage(error).message;
}

export function getErrorCode(error: unknown) {
	if (axios.isAxiosError(error)) {
		switch (error.response?.status) {
			case 404:
				return ERROR_CODES.NOT_FOUND;
			case 401:
				return ERROR_CODES.UNAUTHORIZED;
			case 400: {
				const data = error.response?.data;
				return data?.error.code;
			}

			default:
				return ERROR_CODES.UNKNOWN_ERROR;
		}
	}

	return ERROR_CODES.UNKNOWN_ERROR;
}

export function handleErrorWithAuth(error: unknown) {
	if (isUnauthorized(error)) handleUnauthorized(error);
	else showError(getErrorMessage(error));
}

export function handleGenericError(error: unknown) {
	showError(getErrorMessage(error));
}
