import React from 'react';
import styled from 'styled-components';

const Container = styled.div<React.CSSProperties>`
	max-width: ${({ maxWidth }) => maxWidth || '100%'};
	width: 100%;
	height: ${({ height }) => height || '100%'};
`;

export const FullPageContainer = styled(Container)`
	width: 100vw;
	min-height: 100vh;
	background: ${({ theme }) => theme.gradients.primary};
	overflow: hidden;
`;

export default Container;
