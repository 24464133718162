import { IconProps } from '../utils/types';

const CheckMarkIcon = ({
	color = 'currentColor',
	height = '20',
	width = '20',
	size,
}: IconProps) => {
	return (
		<svg
			width={size || width}
			height={size || height}
			viewBox='0 0 20 20'
			fill={color}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<path
					d='M5.16612 13.7751C4.71195 13.7753 4.27638 13.5948 3.9555 13.2733L0.295374 9.61458C-0.0984579 9.22062 -0.0984579 8.58202 0.295374 8.18806C0.689331 7.79423 1.32794 7.79423 1.72189 8.18806L5.16612 11.6323L14.2781 2.52032C14.6721 2.12649 15.3107 2.12649 15.7046 2.52032C16.0984 2.91428 16.0984 3.55288 15.7046 3.94684L6.37675 13.2733C6.05587 13.5948 5.6203 13.7753 5.16612 13.7751Z'
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default CheckMarkIcon;
