import React from 'react';
import styled, { css } from 'styled-components';

const Divider = styled.div<React.CSSProperties>(
	({ theme: { spacings, colors } }) =>
		css`
			height: ${spacings[1]};
			background-color: ${colors.primary[100]};
		`
);

export default Divider;
