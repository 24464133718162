import styled, { css } from 'styled-components';

const StyledCheckbox = styled.input.attrs((props) => ({
	type: props.type || 'checkbox',
}))(({ theme: { spacings, colors } }) => {
	return css`
		/* Add if not using autoprefixer */
		-webkit-appearance: none;
		/* Remove most all native input styles */
		appearance: none;
		/* Not removed via appearance */
		margin: 0;

		font: inherit;
		color: currentColor;
		width: ${spacings[20]};
		height: ${spacings[20]};
		border: ${spacings[2]} solid ${colors.grey[500]};
		border-radius: 50%;
		transform: translateY(-0.075em);

		display: grid;
		place-content: center;

		&:before {
			content: '';
			width: ${spacings[10]};
			height: ${spacings[10]};
			clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
			transform: scale(0);
			transform-origin: bottom left;
			transition: 120ms transform ease-in-out;
			background-color: ${colors.white[500]};
		}

		&:checked::before {
			transform: scale(1);
		}

		&:indeterminate {
			color: ${colors.white[500]};
			border: ${spacings[2]} solid ${colors.primary[500]};
		}

		&:checked {
			background-color: ${colors.primary[500]};
			border: none;
		}

		&:focus {
			outline: max(${spacings[2]}, ${spacings[2]}) solid ${colors.grey[100]};
			outline-offset: max(${spacings[2]}, ${spacings[2]});
		}

		&:disabled {
			color: ${colors.grey[100]};
			cursor: not-allowed;
			border: ${spacings[2]} solid ${colors.grey[100]};
		}

		&:checked:disabled {
			border: ${spacings[2]} solid ${colors.grey[500]};
			background-color: ${colors.grey[500]};
		}
	`;
});

export { StyledCheckbox };
