import Big from 'big.js';
import { formatNumber } from 'utils/strings';
import { UNTAGGED_TRANSACTIONS } from '../../transactions/all/history/filters/TagComponents';
import { CashflowAPIDataType, LabelBreakdownData } from './CashFlow.types';
import { LabelChartData } from './charts/Charts.types';

export const getTagOptions = (
	tags:
		| {
				id: string;
				name: string;
		  }[]
) => {
	const untaggedOption = {
		label: 'Untagged transactions',
		value: UNTAGGED_TRANSACTIONS,
	};
	const tagOptions = tags.map(({ id, name }) => ({
		label: name,
		value: id,
	}));

	return tagOptions ? [untaggedOption, ...tagOptions] : [untaggedOption];
};

export const makeRowData = (data: LabelChartData[]) => {
	const totalValue = data.reduce((sum, { value }) => {
		sum += value;
		return sum;
	}, 0);

	return data
		.sort((a, b) => (a.value < b.value ? 1 : -1))
		.reduce((acc, { name, value, fill }) => {
			acc.push({
				name,
				fill,
				value: formatNumber(value, 2, true),
				valueInPercent:
					totalValue > 0
						? formatNumber(
								Big(value).div(Big(totalValue)).mul(Big(100)).toString(),
								2,
								true
						  )
						: '0',
			});
			return acc;
		}, [] as LabelBreakdownData[]);
};

export const getAssetOptions = (inputData: CashflowAPIDataType[]) => {
	const tokensHashMap = new Map();
	inputData.forEach((tx) => {
		const { tokenSymbol, tokenAddress, tokenLogoUrl, networkId } = tx;
		const hashKey = [networkId, tokenAddress].join('_');
		if (!tokensHashMap.has(hashKey)) {
			tokensHashMap.set(hashKey, {
				address: tokenAddress,
				id: hashKey,
				logoUrl: tokenLogoUrl,
				networkId,
				symbol: tokenSymbol ?? 'Unknown',
				type: 'ERC_20',
			});
		}
	});

	return Array.from(tokensHashMap.values());
};

export const getLabelOptions = (inputData: CashflowAPIDataType[]) => {
	const labelsHashMap = new Map();
	inputData.forEach((tx) => {
		tx.txnLabels.forEach((label) => {
			if (!labelsHashMap.get(label.id)) {
				labelsHashMap.set(label.id, label);
			}
		});
	});
	return Array.from(labelsHashMap.values());
};

// check if the `to` and `from` address is internal i.e. belong to the organisation	or not.
// return the `networkId_accountAddress` if it is internal and undefined otherwise
export const getToAndFromInternalAccount = (
	item: CashflowAPIDataType,
	accounts?: string[]
) => {
	const isFromInternalAccount = accounts?.find((account) => {
		const networkId = account.split('_')[0];
		const accountAddress = account.split('_')[1];
		if (
			item.networkId === Number(networkId) &&
			item.from?.toLowerCase() === accountAddress.toLowerCase()
		)
			return account;
	});
	const isToInternalAccount = accounts?.find((account) => {
		const networkId = account.split('_')[0];
		const accountAddress = account.split('_')[1];
		if (
			item.networkId === Number(networkId) &&
			item.to?.toLowerCase() === accountAddress.toLowerCase()
		)
			return account;
	});
	return { isFromInternalAccount, isToInternalAccount };
};
