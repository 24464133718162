import yup from 'utils/yup';

const schema = yup.object().shape({
	name: yup
		.string()
		.min(3, 'Name must be at least 3 characters')
		.max(256, 'Name is too long')
		.required('Name is required'),
	email: yup.string().email('Invalid email address'),
	role: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.number(),
		})
		.optional(),
});

export default schema;
