import React from 'react';
import styled from 'styled-components';

const Row = styled.div<React.CSSProperties>`
	display: ${({ display }) => display || 'flex'};
	flex-direction: row;
	justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
	align-items: ${({ alignItems }) => alignItems || 'center'};
	gap: ${({ rowGap }) => rowGap || '0'};
	flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
	width: ${({ width }) => width || 'auto'};
	height: ${({ height }) => height || 'auto'};
	min-height: ${({ minHeight }) => minHeight || 'auto'};
	padding: ${({ padding }) => padding || 0};
	margin: ${({ margin }) => margin || 0};
	max-width: ${({ maxWidth }) => maxWidth};
	flex: ${({ flex }) => flex};
`;

export default Row;
