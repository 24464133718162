import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FullPageContainer } from 'components/Container';
import { SidebarContextProvider } from 'components/Sidebar/Sidebar.context';
import ToastContainer from 'components/ToastContainer';
import { ConnectedRouter } from 'connected-next-router';
import { PENDING_BEAMER_POSTS } from 'constants/beamer';
import { AnalyticsContextProvider } from 'hooks/useAnalytics';
import useBeamer from 'hooks/useBeamer';
import { IntercomProvider } from 'hooks/useIntercom';
import { SentryController } from 'hooks/useSentry';
import { Web3OnboardProvider } from 'hooks/useWeb3Onboard';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ReactElement, ReactNode, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { hotjar } from 'react-hotjar';
import { Provider } from 'react-redux';
import ErrorScreen from 'screens/ErrorScreen';
import store from 'store';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles';
import GlobalStyle from 'styles/globalStyle';

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};
const HOTJAR_ID = Number(process.env.NEXT_PUBLIC_HOTJAR_ID || 0);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { refetchOnWindowFocus: false },
	},
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
	const getLayout = Component.getLayout ?? ((page) => page);
	const router = useRouter();

	const beamerCallback = (unreadCount: number) => {
		localStorage.setItem(PENDING_BEAMER_POSTS, '' + unreadCount);
	};

	useBeamer(beamerCallback);

	useEffect(() => {
		const isDev = process.env.NODE_ENV === 'development';
		if (!isDev && HOTJAR_ID !== undefined) {
			hotjar.initialize(HOTJAR_ID, 6);
		}
	}, []);

	return (
		<Provider store={store}>
			<IntercomProvider>
				<ConnectedRouter>
					<QueryClientProvider client={queryClient}>
						<ThemeProvider theme={theme}>
							<ErrorBoundary
								fallback={
									<FullPageContainer>
										<ErrorScreen />
									</FullPageContainer>
								}
								resetKeys={[router.pathname]}
							>
								<Web3OnboardProvider>
									<SentryController />

									<GlobalStyle />

									<SidebarContextProvider>
										<ToastContainer />
										<AnalyticsContextProvider>
											{getLayout(<Component {...pageProps} />)}
										</AnalyticsContextProvider>
									</SidebarContextProvider>
								</Web3OnboardProvider>
							</ErrorBoundary>
						</ThemeProvider>
						<ReactQueryDevtools initialIsOpen={false} />
					</QueryClientProvider>
				</ConnectedRouter>
			</IntercomProvider>
			<Script
				type='text/javascript'
				id='hs-script-loader'
				async
				defer
				src='//js.hs-scripts.com/23173042.js'
			/>
		</Provider>
	);
}

export default MyApp;
