export default {
	root: '/',
	signup: '/signup',
	organisations: {
		root: '/organisations',
		create: '/organisations/create',
		dashboard: ({ slug }: { slug: string }) => {
			const dashboardRoot = `/organisations/${slug}`;
			return {
				import: `${dashboardRoot}/import`,
				reports: {
					root: `${dashboardRoot}/reports`,
					portfolioHistoryById: ({ id }: { id: string }) =>
						`${dashboardRoot}/reports/portfolio-history/${id}`,
					_portfolioHistoryById: `/organisations/[slug]/reports/portfolio-history/[id]`,
				},
				paymentRequests: `${dashboardRoot}/paymentRequests`,
				root: dashboardRoot,
				_root: `/organisations/[slug]`,
				portfolio: `${dashboardRoot}/portfolio`,
				automatedPayments: `${dashboardRoot}/automated-payments`,
				transactions: {
					root: `${dashboardRoot}/transactions`,
					byId: (id: string) => `${dashboardRoot}/transactions/${id}`,
					_byId: `/organisations/[slug]/transactions/[txnId]`,
				},
				accounts: `${dashboardRoot}/accounts`,
				contacts: `${dashboardRoot}/contacts`,
				settings: `${dashboardRoot}/settings`,
				apps: `${dashboardRoot}/apps`,
			};
		},
		authRedirect: '/auth-redirect',
		integrationsRedirect: '/integrations-redirect',
	},
};
