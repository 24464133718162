import Typography from 'components/Typography';
import React from 'react';

type FieldErrorProps = {
	error: string;
};

const FieldError = ({ error }: FieldErrorProps) => {
	return (
		<Typography color='error' size='xs' variant='subtitle2'>
			{error}
		</Typography>
	);
};

export default FieldError;
