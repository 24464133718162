import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from 'screens/organisations/dashboard/Dashboard.types';

import { AppState } from 'store';

export type RowId = Record<string, boolean>;
export type RowIdsMap = Record<string, RowId>;

export type GlobalFilterState = {
	selectedAccounts: Account[];
	filteredAccounts?: string[];
};

export const initialState: GlobalFilterState = {
	selectedAccounts: [],
	filteredAccounts: undefined,
};

const slice = createSlice({
	name: 'global-filters',
	initialState,
	reducers: {
		setSelectedAccounts(state, action: PayloadAction<{ accounts: Account[] }>) {
			state.selectedAccounts = action.payload.accounts;
		},

		applyFilters(state) {
			const filteredAccounts = state.selectedAccounts?.map(
				({ accountAddress, networkId }) => `${networkId}_${accountAddress}`
			);

			state.filteredAccounts = filteredAccounts;
		},

		reset() {
			return initialState;
		},
	},
});

export const { actions: globalFilterActions, reducer: globalFilterReducer } =
	slice;

export const selectGlobalFilter = (state: AppState) => state.globalFilter;
