import { AnalyticsBrowser } from '@segment/analytics-next';
import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { Organisation } from 'screens/organisations/Organisations.queries';

type TrackType = (
	event: string,
	properties?: any,
	options?: SegmentAnalytics.SegmentOpts,
	callback?: () => void
) => void;

type IdentifyType = (
	userId: string,
	traits?: any,
	options?: SegmentAnalytics.SegmentOpts,
	callback?: () => void
) => void;

type GroupType = (
	groupId: string,
	traits?: any,
	options?: SegmentAnalytics.SegmentOpts,
	callback?: () => void
) => void;

type AnalyticsContext = {
	setAnalyticsOrganisation: (organisation?: Organisation) => void;
	track: TrackType;
	identify: IdentifyType;
	group: GroupType;
};

export const AnalyticsContext = createContext({} as AnalyticsContext);

type Props = {
	children: ReactNode;
};

export const AnalyticsContextProvider = ({ children }: Props) => {
	const [organisation, setAnalyticsOrganisation] = useState<Organisation>();

	const analytics = useMemo(() => {
		return AnalyticsBrowser.load(
			{
				writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || '',
				cdnURL: process.env.NEXT_PUBLIC_SEGMENT_HOST,
			},
			{
				integrations: {
					'Segment.io': {
						apiHost: process.env.NEXT_PUBLIC_SEGMENT_API || '',
						protocol: 'https',
					},
				},
			}
		);
	}, []);

	// This track method will pass the groupId context as org id in every call
	// groupId is required to track on June dashboard
	const track: TrackType = useCallback(
		(
			event,
			properties,
			options = {
				context: { groupId: organisation?.id },
			},
			callback
		) => {
			analytics.track(event, properties, options, callback);
		},
		[analytics, organisation]
	);

	const identify: IdentifyType = (
		userId: string,
		traits?: any,
		options?: SegmentAnalytics.SegmentOpts,
		callback?: () => void
	) => {
		analytics.identify(userId, traits, options, callback);
	};

	const group: GroupType = (
		groupId: string,
		traits?: any,
		options?: SegmentAnalytics.SegmentOpts,
		callback?: () => void
	) => {
		analytics.group(groupId, traits, options, callback);
	};

	return (
		<AnalyticsContext.Provider
			value={{ setAnalyticsOrganisation, track, identify, group }}
		>
			{children}
		</AnalyticsContext.Provider>
	);
};

export const useAnalytics = () => {
	return useContext(AnalyticsContext);
};
