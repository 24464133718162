import { colors } from './constants/colors';
import { gradients } from './constants/gradients';
import { shadows } from './constants/shadows';
import { spacings } from './constants/spacings';
import { fonts, fontSizes, fontWeights } from './constants/typography';

export const theme = {
	colors,
	fonts,
	fontSizes,
	spacings,
	fontWeights,
	shadows,
	gradients,
};

export type Theme = typeof theme;
