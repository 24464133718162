import React from 'react';
import { IconProps } from 'utils/types';

const LogoutIcon = ({
	color = 'currentColor',
	height = '20',
	width = '20',
	size,
}: IconProps) => {
	return (
		<svg
			width={size || width}
			height={size || height}
			viewBox='0 0 20 20'
			fill={color}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M9.56333 12.5C9.34232 12.5 9.13036 12.5878 8.97408 12.7441C8.8178 12.9004 8.73 13.1123 8.73 13.3333V15.8333C8.73 16.4964 8.46661 17.1323 7.99777 17.6011C7.52893 18.0699 6.89304 18.3333 6.23 18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V4.16667C1.66667 3.50363 1.93006 2.86774 2.3989 2.3989C2.86774 1.93006 3.50363 1.66667 4.16667 1.66667H6.23C6.89304 1.66667 7.52893 1.93006 7.99777 2.3989C8.46661 2.86774 8.73 3.50363 8.73 4.16667V6.66667C8.73 6.88768 8.8178 7.09964 8.97408 7.25592C9.13036 7.4122 9.34232 7.5 9.56333 7.5C9.78435 7.5 9.99631 7.4122 10.1526 7.25592C10.3089 7.09964 10.3967 6.88768 10.3967 6.66667V4.16667C10.3953 3.062 9.95593 2.00296 9.17482 1.22185C8.3937 0.440735 7.33466 0.00132321 6.23 0H4.16667C3.062 0.00132321 2.00296 0.440735 1.22185 1.22185C0.440735 2.00296 0.00132321 3.062 0 4.16667L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H6.23C7.33466 19.9987 8.3937 19.5593 9.17482 18.7782C9.95593 17.997 10.3953 16.938 10.3967 15.8333V13.3333C10.3967 13.1123 10.3089 12.9004 10.1526 12.7441C9.99631 12.5878 9.78435 12.5 9.56333 12.5Z'
				fill={color}
			/>
			<path
				d='M19.0558 8.23251L15.2342 4.41084C15.1573 4.33125 15.0653 4.26776 14.9637 4.22409C14.862 4.18041 14.7526 4.15743 14.642 4.15646C14.5313 4.1555 14.4216 4.17659 14.3192 4.21849C14.2168 4.26039 14.1237 4.32227 14.0455 4.40051C13.9673 4.47875 13.9054 4.5718 13.8635 4.67421C13.8216 4.77663 13.8005 4.88636 13.8015 4.99701C13.8024 5.10766 13.8254 5.21701 13.8691 5.31868C13.9128 5.42035 13.9762 5.5123 14.0558 5.58917L17.6075 9.14168L4.99999 9.16668C4.77898 9.16668 4.56701 9.25448 4.41073 9.41076C4.25445 9.56704 4.16666 9.779 4.16666 10C4.16666 10.221 4.25445 10.433 4.41073 10.5893C4.56701 10.7455 4.77898 10.8333 4.99999 10.8333L17.6567 10.8075L14.0542 14.4108C13.9746 14.4877 13.9111 14.5797 13.8674 14.6813C13.8237 14.783 13.8007 14.8924 13.7998 15.003C13.7988 15.1137 13.8199 15.2234 13.8618 15.3258C13.9037 15.4282 13.9656 15.5213 14.0438 15.5995C14.1221 15.6778 14.2151 15.7396 14.3175 15.7815C14.4199 15.8234 14.5297 15.8445 14.6403 15.8436C14.751 15.8426 14.8603 15.8196 14.962 15.7759C15.0637 15.7323 15.1556 15.6688 15.2325 15.5892L19.0542 11.7675C19.5231 11.2989 19.7867 10.6633 19.787 10.0004C19.7873 9.33745 19.5243 8.70155 19.0558 8.23251Z'
				fill={color}
			/>
		</svg>
	);
};

export default LogoutIcon;
