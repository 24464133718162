import IconButton from 'components/Button/IconButton';
import FieldHint from 'components/Form/FieldHint';
import { ExternalLink } from 'components/Link';
import Row from 'components/Row';
import { TooltipWrapper } from 'components/TooltipWrapper';
import Typography, { TypographyProps } from 'components/Typography';
import { getExplorerForChain } from 'constants/explorers';
import { NETWORK_DETAILS_BY_ID } from 'constants/networks';
import CopyIcon from 'icons/CopyIcon';
import LinkIcon from 'icons/LinkIcon';
import { ReactChild } from 'react';
import { spacings } from 'styles';
import { useCopyText } from 'utils/copy';
import { getFormattedAddress } from 'utils/strings';

export interface AddressProps {
	address?: string;
	short?: boolean;
	hint?: string;
	withCopy?: boolean;
	withExplorer?: boolean;
	chainId?: number | string;
	children?: ReactChild;
	typographyProps?: Omit<TypographyProps, 'children' | 'variant'>;
	withPrefix?: boolean;
	prefixSize?: TypographyProps['size'];
	rowGap?: string;
}

const Address = ({
	address = '',
	short = false,
	hint,
	withCopy = true,
	withExplorer = true,
	children = <></>,
	chainId,
	typographyProps = {},
	withPrefix = false,
	prefixSize = 'sm',
	rowGap = spacings[10],
}: AddressProps) => {
	const { isCopied, copyFn } = useCopyText();

	if (!address) return null;

	return (
		<>
			<Row rowGap={rowGap} justifyContent='flex-start' width='fit-content'>
				<Typography
					variant='body'
					{...(short && { title: address })}
					{...typographyProps}
				>
					{withPrefix && chainId && NETWORK_DETAILS_BY_ID[chainId]?.shortName && (
						<Typography
							fontWeight='semibold'
							size={prefixSize}
							color='textSecondary'
						>
							{NETWORK_DETAILS_BY_ID[chainId].shortName}:
						</Typography>
					)}
					{getFormattedAddress(address, short)}
				</Typography>
				{(withCopy || withExplorer) && (
					<Row rowGap={rowGap} justifyContent='flex-start'>
						{withCopy && (
							<TooltipWrapper title={isCopied ? 'Copied' : 'Copy address'}>
								<IconButton
									color='info'
									type='button'
									size='sm'
									icon={<CopyIcon />}
									onClick={(e) => {
										e.stopPropagation();
										copyFn(address);
									}}
								/>
							</TooltipWrapper>
						)}
						{withExplorer && chainId && (
							<ExternalLink
								href={`${getExplorerForChain(chainId)}/address/${address}`}
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<TooltipWrapper title='View on explorer'>
									<IconButton color='info' size='sm' icon={<LinkIcon />} />
								</TooltipWrapper>
							</ExternalLink>
						)}
					</Row>
				)}
			</Row>
			{hint && <FieldHint hint={hint} />}
			{children}
		</>
	);
};

export { Address };
