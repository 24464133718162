export const colors = {
	primary: {
		50: '#F7F9FE',
		100: '#E7EEFE',
		300: '#A1BAFB',
		500: '#1452F5',
		900: '#1452F5',
	},
	secondary: {
		100: '#FEF3E9',
		500: '#F58520',
		900: '#F58520',
	},
	yellow: {
		100: '#FEFCE6',
		500: '#F5E107',
		900: '#F5E107',
	},
	green: {
		100: '#EBF7F0',
		500: '#3EA867',
		900: '#3EA867',
	},
	grey: {
		100: '#F8F8F8',
		500: '#A7A7A7',
		600: '#989898',
		900: '#373737',
	},
	white: {
		100: '#FFFFFF',
		500: '#FFFFFF',
		900: '#FFFFFF',
	},
	info: {
		100: '#E8E7E6',
		500: '#A7A7A7',
		900: '#373737',
	},
	success: {
		100: '#EBF7F0',
		500: '#3EA867',
		900: '#3EA867',
	},
	warning: {
		100: '#FEF3E9',
		500: '#F58520',
		900: '#F58520',
	},
	error: {
		100: '#FDEDEB',
		500: '#EF4839',
		900: '#EF4839',
	},
};

export type ThemeColors = typeof colors;
