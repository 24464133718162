import styled, { css } from 'styled-components';
import { rem } from 'styles';
import { TextInputRootProps } from '.';

const StyledInputRoot = styled.input.attrs((props) => ({
	type: props.type || 'text',
}))<TextInputRootProps & { withPrefixer: boolean; withSuffix: boolean }>(
	({ theme, error, fieldSize, width, withPrefixer, withSuffix }) => {
		const primaryColor = theme.colors.primary;
		const greyColor = theme.colors.grey;
		const errorColor = theme.colors.error;
		const { spacings } = theme;
		const getStyles = () => {
			if (fieldSize === 'md') {
				return css`
					font-size: ${spacings[14]};
					height: ${spacings[40]};
					padding-left: ${withPrefixer ? spacings[48] : spacings[12]};
					padding-right: ${withSuffix ? spacings[48] : spacings[12]};
				`;
			}
			return css`
				height: ${spacings[50]};
				min-width: ${width || rem(440)};
			`;
		};

		return css`
			width: 100%;
			font-size: ${spacings[16]};
			outline: none;
			border-radius: ${spacings[4]};
			padding-left: ${withPrefixer ? spacings[48] : spacings[20]};
			padding-right: ${withSuffix ? spacings[48] : spacings[20]};
			color: ${greyColor[900]};
			transition: 0.1s ease-out;
			&::placeholder {
				color: ${greyColor[500]};
			}
			border: ${spacings[1]} solid
				${error ? errorColor[900] : primaryColor[100]};
			&:focus,
			&:not(:disabled):hover {
				border-color: ${error ? errorColor[900] : primaryColor[900]};
			}
			&:disabled {
				cursor: not-allowed;
				color: ${greyColor[500]};
				background-color: ${greyColor[100]};
			}
			${getStyles()}
		`;
	}
);

const StyledInputAddition = styled.div`
	position: absolute;
	height: 100%;
	display: flex;
	align-items: center;
`;
export { StyledInputRoot, StyledInputAddition };
