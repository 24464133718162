import { rem } from 'styles';
import { ColorMapType } from 'styles/tokens';
import { getInitials } from 'utils/strings';
import { StyledAvatar } from './Avatar.styles';

export const avatarSizes = {
	xxs: rem(10),
	xs: rem(16),
	sm: rem(20),
	md: rem(28),
	lg: rem(36),
};

export interface AvatarProps {
	title?: string;
	color: ColorMapType;
	size: keyof typeof avatarSizes;
	/**
	 * Max length of initials to present
	 */
	initialsLength?: number;
}

const Avatar = ({
	size = 'md',
	color = 'primary',
	title,
	initialsLength = 2,
	...otherProps
}: AvatarProps) => {
	return (
		<StyledAvatar title={title} color={color} size={size} {...otherProps}>
			{title && getInitials(title, initialsLength)}
		</StyledAvatar>
	);
};

export { Avatar };
