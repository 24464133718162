import {
	SideDrawer,
	SideDrawerBody,
	SideDrawerFooter,
	SideDrawerFooterInfo,
	SideDrawerHeader,
} from './SideDrawer';

import { withSteps } from './withSteps';

export enum FooterInfoColorEnum {
	'info' = 'info',
	'error' = 'error',
	'success' = 'success',
	'warning' = 'warning',
}

export {
	SideDrawer,
	SideDrawerHeader,
	SideDrawerBody,
	SideDrawerFooter,
	SideDrawerFooterInfo,
	withSteps,
};
