import Typography from 'components/Typography';
import React from 'react';

type FieldSuggestionProps = {
	message: string;
};

const FieldSuggestion = ({ message }: FieldSuggestionProps) => {
	return (
		<Typography color='textSecondary' variant='subtitle2'>
			{message}
		</Typography>
	);
};

export default FieldSuggestion;
