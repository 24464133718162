import { Dialog } from '@headlessui/react';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Row from 'components/Row';
import Stack from 'components/Stack';
import Typography from 'components/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import ArrowLeftIcon from 'icons/ArrowLeftIcon';
import SideDrawerCrossIcon from 'icons/SideDrawerCrossIcon';
import React, { CSSProperties, ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { spacings } from 'styles';
import {
	StyledBody,
	StyledContainer,
	StyledFooter,
	StyledFooterInfo,
	StyledHeader,
} from './SideDrawer.styles';
import { ModalSizeType, getModalSize } from './SideDrawer.utils';

type SideDrawerProps = {
	isOpen: boolean;
	closeOnEscOrClickOutside?: boolean;
	onClose: () => void;
	children: ReactNode;
	style?: CSSProperties;
	initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
	zIndex?: number;
	size?: ModalSizeType;
};

type SideDrawerHeaderProps = {
	onClose: () => void;
	children: ReactNode;
	currentStep?: number;
	steps?: number;
	goBack?: () => void;
};

type SideDrawerBodyProps = {
	children: ReactNode;
	style?: React.CSSProperties;
};

type SideDrawerFooterInfoProps = {
	children: ReactNode;
	color: 'info' | 'warning' | 'error' | 'success';
};

const dialogStyles: CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'fixed',
	flexWrap: 'wrap',
	flexDirection: 'row',
	top: '0',
	right: '0',
	bottom: '0',
	margin: 0,
	zIndex: 10,
	height: '100vh',
};

const SideDrawer = (props: SideDrawerProps) => {
	const {
		children,
		size = 'md',
		onClose,
		isOpen,
		zIndex = 10,
		...rest
	} = props;

	return (
		<>
			<AnimatePresence>
				{isOpen && (
					<Dialog
						as='div'
						open={isOpen}
						onClose={onClose}
						style={{
							...dialogStyles,
							zIndex,
						}}
						{...rest}
					>
						<Dialog.Overlay
							style={{
								position: 'fixed',
								inset: '0',
								opacity: '1',
								background: 'rgba(0, 0, 0, .2)',
								WebkitBackdropFilter: `blur(${spacings[4]})`,
								backdropFilter: `blur(${spacings[4]})`,
							}}
						/>

						<motion.div
							transition={{ type: 'linear', duration: 0.2 }}
							initial={{ width: 0 }}
							animate={{ width: getModalSize(size) }}
							exit={{
								width: 0,
								transition: { type: 'linear', duration: 0.2 },
							}}
						>
							<StyledContainer size={size}>{children}</StyledContainer>
						</motion.div>
					</Dialog>
				)}
			</AnimatePresence>
		</>
	);
};

const SideDrawerHeader = ({
	goBack,
	onClose,
	steps = 1,
	currentStep = 1,
	children,
	...rest
}: SideDrawerHeaderProps) => {
	const { spacings } = useTheme();
	return (
		<StyledHeader {...rest}>
			<Stack gutter={spacings[20]}>
				<Row
					alignItems='center'
					justifyContent={steps > 1 ? 'space-between' : 'flex-end'}
				>
					{steps > 1 && currentStep > 1 && goBack ? (
						<IconButton icon={<ArrowLeftIcon />} onClick={goBack} />
					) : (
						<div />
					)}
					{steps > 1 && (
						<Typography color='textSecondary'>
							Step {currentStep} of {steps}
						</Typography>
					)}
					<IconButton icon={<SideDrawerCrossIcon />} onClick={onClose} />
				</Row>
				{children}
				<Divider />
			</Stack>
		</StyledHeader>
	);
};

const SideDrawerBody = ({ children, ...rest }: SideDrawerBodyProps) => {
	return <StyledBody {...rest}>{children}</StyledBody>;
};

const SideDrawerFooterInfo = ({
	children,
	color,
	...rest
}: SideDrawerFooterInfoProps) => {
	return (
		<StyledFooterInfo color={color} {...rest}>
			{children}
		</StyledFooterInfo>
	);
};

const SideDrawerFooter = ({ children, ...rest }: SideDrawerBodyProps) => {
	return <StyledFooter {...rest}>{children}</StyledFooter>;
};

export {
	SideDrawer,
	SideDrawerHeader,
	SideDrawerBody,
	SideDrawerFooter,
	SideDrawerFooterInfo,
};
