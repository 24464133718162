import React from 'react';
import styled from 'styled-components';

type StackProps = {
	gutter?: string;
};

const Stack = styled.div<StackProps & React.CSSProperties>`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.gutter ?? '0'};
	flex: ${({ flex }) => flex};
	padding: ${({ padding }) => padding || '0'};
	margin: ${({ margin }) => margin || '0'};
	justify-items: ${({ justifyItems }) => justifyItems || 'initial'};
	align-items: ${({ alignItems }) => alignItems || 'initial'};
	justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
	align-items: ${({ alignItems }) => alignItems || 'initial'};
	flex-grow: ${({ flexGrow }) => flexGrow || 'auto'};
	width: ${({ width }) => width || '100%'};
	max-width: ${({ maxWidth }) => maxWidth || 'auto'};
	height: ${({ height }) => height || 'auto'};
`;

export default Stack;
