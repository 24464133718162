import { Popover } from '@headlessui/react';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'styles';

const StyledWalletPicker = styled(Popover.Button)<{
	children: ReactNode;
	ref?: any;
}>(({ theme }) => {
	const { colors, spacings } = theme;
	return css`
		background-color: ${colors.white[900]};
		border: ${spacings[1]} solid ${colors.primary[100]};
		&:hover,
		&:focus {
			border-color: ${colors.primary[900]};
			cursor: pointer;
		}
		width: 100%;
		max-width: ${rem(280)};
		height: ${spacings[50]};
		border-radius: ${spacings[4]};
		transition: all 0.1s ease;
		padding: 0 ${spacings[20]};
		z-index: 1000;
	`;
});

const DetailsRow = styled.div<{ background?: string }>(
	({ theme, background }) => {
		const { spacings } = theme;
		return css`
			display: flex;
			gap: ${spacings[10]};
			align-items: center;
			background: ${background || theme.colors.white[900]};
		`;
	}
);

const StyledWalletPickerPanel = styled(Popover.Panel)<{
	children: ReactNode;
	ref?: any;
	style?: React.CSSProperties;
}>(({ theme }) => {
	const { colors, spacings } = theme;
	return css`
		min-width: ${rem(280)};
		background-color: ${colors.white[900]};
		border: ${spacings[1]} solid ${colors.primary[100]};
		border-radius: ${spacings[4]};
		display: flex;
		flex-direction: column;
		box-shadow: ${spacings[20]} ${spacings[0]} ${spacings[40]}
			rgba(3, 153, 186, 0.1);
	`;
});

export { StyledWalletPicker, StyledWalletPickerPanel, DetailsRow };
