import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenOption } from 'components/Form/SelectToken';
import { cloneDeep } from 'lodash';
import { AppState } from 'store';

export type StreamTransferSummary = {
	bufferAmount: string;
	count: number;
	currentFiatBalance: string | number;
	currentTokenBalance: string | number;
	id: number;
	isInsufficientBalance: boolean;
	minimumBalanceRequired: string;
	token: TokenOption;
	tokenAddress: string;
	tokenSymbol: string;
	// flow rate in wei per second
	totalFlowRateInSeconds: string | number;
	// flow rate in Eth per month
	totalFlowRatePerMonth: string | number;
};

type StreamPayoutState = {
	transferSummary: StreamTransferSummary[];
	isResolving: boolean;
};

const initialState: StreamPayoutState = {
	transferSummary: [],
	isResolving: false,
};

const slice = createSlice({
	name: 'organisation/create-new-transfer/stream-payout',
	initialState,
	reducers: {
		setSummary(state, action: PayloadAction<StreamTransferSummary[]>) {
			const newTransferSummary = cloneDeep(action.payload);
			state.transferSummary = newTransferSummary;
		},
		setIsResolving(state, action: PayloadAction<boolean>) {
			state.isResolving = action.payload;
		},
		reset() {
			return initialState;
		},
	},
});
export const { actions: streamPayoutActions, reducer: streamPayoutReducer } =
	slice;

export const selectStreamPayout = (state: AppState) => state.streamPayout;
