import { DefaultFormFieldProps, FieldSizes } from 'components/Form/types';
import Select from 'components/Select';
import React from 'react';
import { ActionMeta, MenuPlacement, Props } from 'react-select';
import FormElementContainer from '../FormElementContainer';

export type Option =
	| {
			label: any;
			value: any;
	  }
	| unknown;
export interface CustomSelectProps extends DefaultFormFieldProps {
	onChange?: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
	options: Option[];
	value: Option;
	width?: string;
	menuPlacement?: MenuPlacement;
	style?: React.CSSProperties;
	sizeContainer?: FieldSizes;
}

const SelectInput = React.forwardRef(
	(props: CustomSelectProps & Props, ref) => {
		const {
			placeholder,
			label,
			error,
			isDisabled = false,
			value,
			options = [],
			suggestion = '',
			onChange = () => {
				return;
			},
			name = 'select',
			hint = '',
			width,
			menuPlacement,
			size = 'md',
			sizeContainer,
			style,
			...rest
		} = props;

		return (
			<FormElementContainer
				label={label}
				error={error}
				suggestion={suggestion}
				name={name}
				hint={hint}
				width={width}
				size={sizeContainer}
				style={style}
			>
				<Select
					isDisabled={isDisabled}
					onChange={onChange}
					options={options}
					placeholder={placeholder}
					value={value}
					instanceId={name}
					ref={ref}
					menuPlacement={menuPlacement}
					width={width}
					size={size}
					{...rest}
				/>
			</FormElementContainer>
		);
	}
);

export default SelectInput;
