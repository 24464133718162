import { configureStore } from '@reduxjs/toolkit';
import { createRouterMiddleware } from 'connected-next-router';

import createReducer from './reducers';

const routerMiddleware = createRouterMiddleware();

export function makeStore() {
	return configureStore({
		reducer: createReducer(),
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({ thunk: false }).concat(routerMiddleware),
	});
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
