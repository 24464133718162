import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LabelOption } from 'components/Form/types';
import { AppState } from 'store';
import { Account } from '../Dashboard.types';
import { TransferFormData } from './mass-payout/MassPayout.utils';
import { StreamTransferFormData } from './stream-payout/StreamPayout.utils';

type CreateNewTransferState = {
	selectedSafe: Account | undefined;
	txnType: string | undefined;
	currentStep: number;
	transferData?: TransferFormData | StreamTransferFormData;
	txnNotes: string | undefined;
	txnTags: Array<LabelOption>;
};

const initialState: CreateNewTransferState = {
	selectedSafe: undefined,
	txnType: undefined,
	currentStep: 0,
	transferData: undefined,
	txnNotes: undefined,
	txnTags: [],
};

const slice = createSlice({
	name: 'organisation/create-new-transfer',
	initialState,
	reducers: {
		setSelectedSafe(state, action: PayloadAction<Account | undefined>) {
			state.selectedSafe = action.payload;
		},

		setTxnType(state, action: PayloadAction<string | undefined>) {
			state.txnType = action.payload;
		},

		nextStep(state) {
			state.currentStep = state.currentStep + 1;
		},
		prevStep(state) {
			state.currentStep = state.currentStep - 1;
		},
		setCurrentStep(state, action: PayloadAction<number>) {
			state.currentStep = action.payload;
		},

		reset() {
			return initialState;
		},

		// Transfer Data State
		setTransferData(
			state,
			action: PayloadAction<
				TransferFormData | StreamTransferFormData | undefined
			>
		) {
			state.transferData = action.payload;
		},

		setTxnAdditionalData(
			state,
			action: PayloadAction<{ notes?: string; tags?: Array<LabelOption> }>
		) {
			state.txnNotes = action.payload.notes || state.txnNotes;
			state.txnTags = action.payload.tags || state.txnTags;
		},
	},
});

export const {
	actions: createNewTransferActions,
	reducer: createNewTransferReducer,
} = slice;

export const selectCreateNewTransfer = (state: AppState) =>
	state.createNewTransfer;

export const selectTxnAdditionalData = (state: AppState) => {
	return {
		tags: state.createNewTransfer.txnTags,
		notes: state.createNewTransfer.txnNotes,
	};
};
