import React from 'react';
import { IconProps } from 'utils/types';

const CopyIcon = ({
	color = 'currentColor',
	height = '20',
	width = '20',
	size,
}: IconProps) => {
	return (
		<svg
			width={size || width}
			height={size || height}
			viewBox='0 0 20 20'
			fill={color}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<path
					d='M10.8334 16.6667C11.9381 16.6654 12.9971 16.226 13.7782 15.4449C14.5593 14.6638 14.9988 13.6047 15.0001 12.5001V5.20258C15.0014 4.76454 14.9157 4.33061 14.7481 3.92592C14.5804 3.52124 14.3341 3.15386 14.0234 2.84508L12.1551 0.976742C11.8463 0.666054 11.4789 0.419745 11.0742 0.252092C10.6695 0.0844401 10.2356 -0.00122059 9.79758 7.55265e-05H5.83342C4.72875 0.00139874 3.66971 0.440811 2.8886 1.22193C2.10748 2.00304 1.66807 3.06208 1.66675 4.16674V12.5001C1.66807 13.6047 2.10748 14.6638 2.8886 15.4449C3.66971 16.226 4.72875 16.6654 5.83342 16.6667H10.8334ZM3.33341 12.5001V4.16674C3.33341 3.5037 3.59681 2.86782 4.06565 2.39898C4.53449 1.93013 5.17037 1.66674 5.83342 1.66674C5.83342 1.66674 9.93258 1.67841 10.0001 1.68674V3.33341C10.0001 3.77544 10.1757 4.19936 10.4882 4.51192C10.8008 4.82448 11.2247 5.00008 11.6667 5.00008H13.3134C13.3217 5.06758 13.3334 12.5001 13.3334 12.5001C13.3334 13.1631 13.07 13.799 12.6012 14.2678C12.1323 14.7367 11.4965 15.0001 10.8334 15.0001H5.83342C5.17037 15.0001 4.53449 14.7367 4.06565 14.2678C3.59681 13.799 3.33341 13.1631 3.33341 12.5001ZM18.3334 6.66674V15.8334C18.3321 16.9381 17.8927 17.9971 17.1116 18.7782C16.3305 19.5593 15.2714 19.9988 14.1667 20.0001H6.66675C6.44573 20.0001 6.23377 19.9123 6.07749 19.756C5.92121 19.5997 5.83342 19.3878 5.83342 19.1667C5.83342 18.9457 5.92121 18.7338 6.07749 18.5775C6.23377 18.4212 6.44573 18.3334 6.66675 18.3334H14.1667C14.8298 18.3334 15.4657 18.07 15.9345 17.6012C16.4034 17.1323 16.6667 16.4965 16.6667 15.8334V6.66674C16.6667 6.44573 16.7545 6.23377 16.9108 6.07749C17.0671 5.92121 17.2791 5.83341 17.5001 5.83341C17.7211 5.83341 17.9331 5.92121 18.0893 6.07749C18.2456 6.23377 18.3334 6.44573 18.3334 6.66674Z'
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default CopyIcon;
