import { IconProps } from 'utils/types';

const SideDrawerCrossIcon = ({ color }: IconProps) => {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill={'none'}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M17.8048 2.19519C17.6798 2.07021 17.5102 2 17.3335 2C17.1567 2 16.9871 2.07021 16.8621 2.19519L10 9.05732L3.13788 2.19519C3.01286 2.07021 2.84332 2 2.66654 2C2.48976 2 2.32022 2.07021 2.19519 2.19519C2.07021 2.32022 2 2.48976 2 2.66654C2 2.84332 2.07021 3.01286 2.19519 3.13788L9.05732 10L2.19519 16.8621C2.07021 16.9871 2 17.1567 2 17.3335C2 17.5102 2.07021 17.6798 2.19519 17.8048C2.32022 17.9298 2.48976 18 2.66654 18C2.84332 18 3.01286 17.9298 3.13788 17.8048L10 10.9427L16.8621 17.8048C16.9871 17.9298 17.1567 18 17.3335 18C17.5102 18 17.6798 17.9298 17.8048 17.8048C17.9298 17.6798 18 17.5102 18 17.3335C18 17.1567 17.9298 16.9871 17.8048 16.8621L10.9427 10L17.8048 3.13788C17.9298 3.01286 18 2.84332 18 2.66654C18 2.48976 17.9298 2.32022 17.8048 2.19519Z'
				fill={color || '#373737'}
			/>
		</svg>
	);
};

export default SideDrawerCrossIcon;
