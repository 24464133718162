import { useRouter } from 'next/router';
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';

type SidebarContextType = {
	collapsed?: boolean;
	setCollapsed?: any;
	currentPath: string | undefined;
};

type Props = {
	children: ReactNode;
};

export const SidebarContext = createContext({} as SidebarContextType);

export const SidebarContextProvider = ({ children }: Props) => {
	const [collapsed, setCollapsed] = useState(false);

	const { asPath } = useRouter();
	useEffect(() => {
		try {
			const sidebarPref = localStorage.getItem('sidebarCollapsed');
			if (sidebarPref) {
				setCollapsed(sidebarPref === 'true');
			}
		} catch (e) {
			// console.log(e);
		}
	}, []);

	useEffect(() => {
		try {
			localStorage.setItem('sidebarCollapsed', '' + collapsed);
		} catch (e) {
			// console.log(e);
		}
	}, [collapsed]);

	return (
		<SidebarContext.Provider
			value={{
				currentPath: asPath,
				collapsed,
				setCollapsed,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
};

export const useSidebarCtx = () => {
	return useContext(SidebarContext);
};
