import { useRouter } from 'next/router';
import { useEffect } from 'react';

const APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
const load = () => {
	(function () {
		const w: any = window;
		const ic: any = w.Intercom;
		if (typeof ic === 'function') {
			ic('reattach_activator');
			ic('update', w.intercomSettings);
		} else {
			const d: Document = document;
			const i: any = function () {
				// eslint-disable-next-line prefer-rest-params
				i.c(arguments);
			};
			i.q = [];
			i.c = function (args: any) {
				i.q.push(args);
			};
			w.Intercom = i;
			const l: any = function () {
				const s: HTMLScriptElement = d.createElement('script');
				s.type = 'text/javascript';
				s.async = true;
				s.src = 'https://widget.intercom.io/widget/frl68b8o';
				const x: HTMLScriptElement = d.getElementsByTagName('script')[0];
				x.parentNode?.insertBefore(s, x);
			};
			if (document.readyState === 'complete') {
				l();
			} else if (w.attachEvent) {
				w.attachEvent('onload', l);
			} else {
				w.addEventListener('load', l, false);
			}
		}
	})();
};

// Initializes Intercom
const boot = (options = {}) => {
	window &&
		window.Intercom &&
		window.Intercom('boot', { app_id: APP_ID, ...options });
};

const update = () => {
	window && window.Intercom && window.Intercom('update');
};

type Props = {
	children: JSX.Element;
};

export const IntercomProvider = ({ children }: Props): JSX.Element => {
	const router = useRouter();

	if (typeof window !== 'undefined') {
		load();
		boot();
	}

	useEffect(() => {
		const handleRouteChange = () => {
			if (typeof window !== 'undefined') {
				update();
			}
		};

		router.events.on('routeChangeStart', handleRouteChange);

		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, [router.events]);

	return children;
};
