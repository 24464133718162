import * as Sentry from '@sentry/browser';
import { queryKeys } from 'constants/queryKeys';
import { useWeb3Onboard } from 'hooks/useWeb3Onboard';
import { useEffect } from 'react';
import { Organisation } from 'screens/organisations/Organisations.queries';
import useQueryData from './useQueryData';

export const useSentry = () => {
	const { account } = useWeb3Onboard();

	const organisation = useQueryData<Organisation>(
		queryKeys.organisations.bySlug()
	);

	useEffect(() => {
		Sentry.setTag('organisationId', organisation?.id ?? null);
		Sentry.setTag('organisationSlug', organisation?.slug ?? null);
		Sentry.setContext('organisation', organisation ?? null);
	}, [organisation]);

	useEffect(() => {
		Sentry.setTag('userAddress', account ?? null);
	}, [account]);
};

export const SentryController = () => {
	useSentry();

	return null;
};
