import { rem } from './spacings';

const devices = {
	tablet: rem(768),
	laptop: rem(1280),
	monitor: rem(1920),
	inch13: rem(1280),
	inch14: rem(1512),
	inch15: rem(1600),
	inch16: rem(1792),
	inch17: rem(1900),
	inch18plus: rem(2000),
};

const breakpoints = {
	mobile: `(max-width: ${devices.tablet})`,
	tablet: `(min-width: ${devices.tablet})`,
	laptop: `(min-width: ${devices.laptop})`,
	monitor: `(min-width: ${devices.monitor})`,
	inch13: `(min-width: ${devices.inch13})`,
	inch14: `(min-width: ${devices.inch14})`,
	inch15: `(min-width: ${devices.inch15})`,
	inch16: `(min-width: ${devices.inch16})`,
	inch17: `(min-width: ${devices.inch17})`,
	inch18plus: `(min-width: ${devices.inch18plus})`,
};

export { devices, breakpoints };
