import { rem } from './spacings';

export const fonts = {
	primary: "'Inter', 'system-ui'",
	systemUI: 'system-ui',
};

export const fontSizes = {
	'3xs': rem(8),
	'2xs': rem(10),
	xs: rem(12),
	sm: rem(14),
	md: rem(16),
	lg: rem(18),
	xl: rem(20),
	'2xl': rem(24),
	'2.5xl': rem(28),
	'3xl': rem(30),
	'4xl': rem(36),
	'5xl': rem(48),
	'6xl': rem(60),
};

export const fontWeights = {
	hairline: 100,
	thin: 200,
	light: 300,
	normal: 400,
	medium: 500,
	semibold: 600,
	bold: 700,
	extrabold: 800,
	black: 900,
};

export type ThemeFonts = typeof fonts;
export type ThemeFontSizes = typeof fontSizes;
export type ThemeFontWeights = typeof fontWeights;
