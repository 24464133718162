import { Dialog } from '@headlessui/react';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'styles';
import { FooterInfoColorEnum } from './index';
import { getModalSize, ModalSizeType } from './SideDrawer.utils';

const StyledContainer = styled(Dialog.Panel)<{
	children: ReactNode;
	size: ModalSizeType;
}>(({ size = 'md' }) => {
	return css`
		display: inline-block;
		position: relative;
		width: ${getModalSize(size)};
		height: 100vh;
		background-color: white;
	`;
});
const StyledHeader = styled.div(
	({ theme: { spacings } }) =>
		css`
			width: 100%;
			padding: ${spacings[20]} ${spacings[20]} 0;
			position: relative;
		`
);

const StyledBody = styled.div(
	({ theme: { spacings } }) =>
		css`
			padding: ${spacings[20]} ${spacings[20]} ${rem(100)};
			height: 0;
			min-height: 75vh;
			overflow-y: auto;
		`
);

const StyledFooter = styled.div(
	({ theme: { spacings, colors } }) =>
		css`
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			border-top: ${spacings[1]} solid ${colors.primary[100]};
			padding: ${spacings[24]} 0;
			min-height: ${rem(100)};
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: ${colors.white[500]};
		`
);

const StyledFooterInfo = styled.div<{
	color: 'info' | 'warning' | 'error' | 'success';
}>(({ theme: { spacings, colors }, color }) => {
	const colorMap = {
		info: {
			backgroundColor: colors.grey[100],
			textColor: colors.grey[600],
		},
		error: {
			backgroundColor: colors.error[100],
			textColor: colors.error[500],
		},
		warning: {
			backgroundColor: colors.yellow[100],
			textColor: colors.yellow[500],
		},
		success: {
			backgroundColor: colors.green[100],
			textColor: colors.green[500],
		},
	};

	const { backgroundColor, textColor } =
		colorMap[color || FooterInfoColorEnum.info];

	return css`
		position: absolute;
		bottom: ${rem(100)};
		left: 0;
		right: 0;
		width: 100%;
		padding: ${spacings[12]} 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${backgroundColor};
		font-size: ${spacings[12]};
		color: ${textColor};
	`;
});

export {
	StyledContainer,
	StyledHeader,
	StyledBody,
	StyledFooter,
	StyledFooterInfo,
};
