import PrimaryButton from 'components/Button/PrimaryButton';
import Container from 'components/Container';
import { CoinshiftLogo } from 'components/Header';
import Img from 'components/Img';
import { InternalLink } from 'components/Link';
import Stack from 'components/Stack';
import Subtitle1 from 'components/Typography/Subtitle1';
import Subtitle2 from 'components/Typography/Subtitle2';
import Title from 'components/Typography/Title';
import paths from 'constants/paths';
import React from 'react';
import { useTheme } from 'styled-components';

export const ErrorComponent = () => {
	const { spacings } = useTheme();

	return (
		<Stack gutter={spacings[64]} alignItems='center' padding={spacings[40]}>
			<Img
				src='/assets/500.svg'
				width={456}
				alt='something went wrong illustration'
			/>

			<Stack gutter={spacings[16]} alignItems='center'>
				<Title size='5xl'>Oops</Title>
				<Subtitle1>Something went wrong</Subtitle1>
				<Subtitle2 style={{ marginBottom: spacings[16] }}>
					Looks like something is broken, our team is working to fix this ASAP.
				</Subtitle2>
				<InternalLink href={paths.organisations.root}>
					<PrimaryButton>Go to Dashboard</PrimaryButton>
				</InternalLink>
			</Stack>
		</Stack>
	);
};

const ErrorScreen = () => {
	const { spacings } = useTheme();
	return (
		<Container>
			<Stack gutter={spacings[64]} alignItems='center' padding={spacings[40]}>
				<CoinshiftLogo />
				<ErrorComponent />
			</Stack>
		</Container>
	);
};

export default ErrorScreen;
