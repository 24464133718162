import React from 'react';
import { IconProps } from 'utils/types';

const CrossIconSmall = ({
	color = 'currentColor',
	height = '20',
	width = '20',
	size,
}: IconProps) => {
	return (
		<svg
			width={size || width}
			height={size || height}
			viewBox='0 0 20 20'
			fill={color}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<path
					d='M15 4.99997C14.8437 4.84374 14.6318 4.75598 14.4108 4.75598C14.1898 4.75598 13.9779 4.84374 13.8217 4.99997L9.99999 8.82164L6.17832 4.99997C6.02205 4.84374 5.81012 4.75598 5.58915 4.75598C5.36818 4.75598 5.15626 4.84374 4.99999 4.99997C4.84376 5.15624 4.756 5.36817 4.756 5.58914C4.756 5.81011 4.84376 6.02203 4.99999 6.1783L8.82165 9.99997L4.99999 13.8216C4.84376 13.9779 4.756 14.1898 4.756 14.4108C4.756 14.6318 4.84376 14.8437 4.99999 15C5.15626 15.1562 5.36818 15.244 5.58915 15.244C5.81012 15.244 6.02205 15.1562 6.17832 15L9.99999 11.1783L13.8217 15C13.9779 15.1562 14.1898 15.244 14.4108 15.244C14.6318 15.244 14.8437 15.1562 15 15C15.1562 14.8437 15.244 14.6318 15.244 14.4108C15.244 14.1898 15.1562 13.9779 15 13.8216L11.1783 9.99997L15 6.1783C15.1562 6.02203 15.244 5.81011 15.244 5.58914C15.244 5.36817 15.1562 5.15624 15 4.99997V4.99997Z'
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default CrossIconSmall;
