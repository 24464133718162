import { createContext, ReactNode, useContext, useState } from 'react';

type StepperContextType = {
	step: number;
	setStep: (step: number) => void;
	goNext: () => void;
	goBack: () => void;
};

type Props = {
	children: ReactNode;
};

export const StepperContext = createContext({} as StepperContextType);

export const StepperProvider = ({ children }: Props) => {
	const [step, setStep] = useState(1);

	const goNext = () => setStep(step + 1);
	const goBack = () => step > 0 && setStep(step - 1);

	return (
		<StepperContext.Provider
			value={{
				step,
				setStep,
				goBack,
				goNext,
			}}
		>
			{children}
		</StepperContext.Provider>
	);
};

function useStepper() {
	return useContext(StepperContext);
}

export default useStepper;
