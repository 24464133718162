export type LabelBreakdownData = {
	name: string;
	value: string;
	valueInPercent: string;
	fill: string;
};

export type CashflowQueryParams = {
	accountAddresses?: string[];
	startDate: string;
	endDate: string;
};

export enum CashflowDirectionEnum {
	ERC20_INCOMING = 'ERC20_INCOMING',
	ERC20_OUTGOING = 'ERC20_OUTGOING',
}

export type CashflowLabelType = {
	id: string;
	name: string;
};

export type CashFlowFiatCsvType = {
	Date: string;
	'Incoming (in USD)': string;
	'Outgoing (in USD)': string;
};

export type CashFlowTokensCsvType = {
	Date: string;
	Token: string;
	Network: string;
	'Incoming (in tokens)': string;
	'Incoming (in USD)': string;
	'Outgoing (in tokens)': string;
	'Outgoing (in USD)': string;
};

export type CashFlowLabelsCsvType = {
	Date: string;
	Label: string;
	'Incoming (in USD)': string;
	'Outgoing (in USD)': string;
};

export type CashFlowIncomingLabelsCsvType = {
	Label: string;
	'Incoming (in USD)': string;
};

export type CashFlowOutgoingLabelsCsvType = {
	Label: string;
	'Outgoing (in USD)': string;
};

export type CashflowAPIDataType = {
	orgName: string;
	networkId: number;
	txnLabels: CashflowLabelType[];
	txnNotes: string;
	accountAddress: string;
	receiverAddress: string;
	direction: CashflowDirectionEnum;
	transactionId: string;
	transactionOperationId: string;
	transactionHash: string;
	operationType: CashflowDirectionEnum;
	sourceAccountAddress: string;
	from: string;
	to: string;
	fiatAmount: number;
	fiatConversion: number;
	tokenAmount: number;
	tokenAddress: string;
	tokenSymbol: string;
	tokenLogoUrl: string;
	timestamp: string;
};

export type IntervalType =
	| 'DAILY'
	| 'WEEKLY'
	| 'MONTHLY'
	| 'QUARTERLY'
	| 'YEARLY';
