import { IconProps } from 'utils/types';

const ArrowLeftIcon = ({
	color = 'currentColor',
	height = '20',
	width = '20',
	size,
}: IconProps) => {
	return (
		<svg
			width={size || width}
			height={size || height}
			viewBox='0 0 20 20'
			fill={color}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_3131_15291)'>
				<path
					d='M0.733331 11.7416L3.95833 14.9999C4.0358 15.078 4.12797 15.14 4.22952 15.1823C4.33107 15.2246 4.43999 15.2464 4.55 15.2464C4.66001 15.2464 4.76893 15.2246 4.87048 15.1823C4.97203 15.14 5.0642 15.078 5.14166 14.9999C5.21977 14.9224 5.28177 14.8303 5.32407 14.7287C5.36638 14.6272 5.38816 14.5183 5.38816 14.4083C5.38816 14.2982 5.36638 14.1893 5.32407 14.0878C5.28177 13.9862 5.21977 13.8941 5.14166 13.8166L2.175 10.8333H19.1667C19.3877 10.8333 19.5996 10.7455 19.7559 10.5892C19.9122 10.4329 20 10.2209 20 9.99992C20 9.7789 19.9122 9.56694 19.7559 9.41066C19.5996 9.25438 19.3877 9.16658 19.1667 9.16658H2.125L5.14166 6.14992C5.28941 5.99494 5.37182 5.78903 5.37182 5.57492C5.37182 5.3608 5.28941 5.1549 5.14166 4.99992C5.0642 4.92181 4.97203 4.85981 4.87048 4.81751C4.76893 4.7752 4.66001 4.75342 4.55 4.75342C4.43999 4.75342 4.33107 4.7752 4.22952 4.81751C4.12797 4.85981 4.0358 4.92181 3.95833 4.99992L0.733331 8.20825C0.265162 8.677 0.00219727 9.31241 0.00219727 9.97492C0.00219727 10.6374 0.265162 11.2728 0.733331 11.7416Z'
					fill='#373737'
				/>
			</g>
			<defs>
				<clipPath id='clip0_3131_15291'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default ArrowLeftIcon;
