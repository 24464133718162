import FieldError from 'components/Form/FieldError';
import FieldHint from 'components/Form/FieldHint';
import FieldSuggestion from 'components/Form/FieldSuggestion';
import {
	StyledFieldContainer,
	StyledFieldLabel,
	StyledFieldRow,
} from 'components/Form/FormField.styles';
import Stack from 'components/Stack';
import React from 'react';
import { spacings } from 'styles';
import { getErrorMessage } from 'utils/error';
import { FieldSizes } from './types';

const FormElementContainer = ({
	children,
	label,
	error,
	suggestion,
	name,
	hint,
	width,
	size,
	style,
}: {
	children: React.ReactNode;
	label?: string;
	error?: string | { message: string } | { message: string }[];
	suggestion?: string;
	name?: string;
	hint?: string;
	width?: string | number;
	size?: FieldSizes;
	style?: React.CSSProperties;
}) => {
	return (
		<StyledFieldContainer size={size} width={width} style={style}>
			<Stack gutter={spacings[10]}>
				{(label || suggestion) && (
					<StyledFieldRow>
						{label && (
							<StyledFieldLabel htmlFor={name} size={size}>
								{label}
							</StyledFieldLabel>
						)}
						{suggestion && <FieldSuggestion message={suggestion} />}
					</StyledFieldRow>
				)}
				{children}
				{error && <FieldError error={getErrorMessage(error)} />}
				{hint && <FieldHint hint={hint} />}
			</Stack>
		</StyledFieldContainer>
	);
};

export default FormElementContainer;
