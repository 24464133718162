import Row from 'components/Row';
import SpinnerIcon from 'icons/SpinnerIcon';
import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';
import { BUTTON_SIZES } from 'styles/tokens';
import { IconContainer, LoadingIconContainer } from './Button.styles';
import ButtonBase, { ButtonBaseProps } from './ButtonBase';

export interface ButtonProps extends ButtonBaseProps {
	leftIcon?: ReactNode;
	rightIcon?: ReactNode;
}

const StyledButton = styled(ButtonBase)<ButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		opacity: 0.8;
	}
`;

const Button = React.forwardRef<
	HTMLButtonElement,
	ButtonProps & React.ComponentPropsWithoutRef<'button'>
>((props, ref) => {
	const {
		children,
		leftIcon,
		rightIcon,
		type = 'button',
		onClick,
		isLoading = false,
		size = BUTTON_SIZES.lg,
		...otherButtonProps
	} = props;
	const { spacings } = useTheme();

	return (
		<StyledButton
			ref={ref}
			isLoading={isLoading}
			onClick={onClick}
			type={type}
			size={size}
			{...otherButtonProps}
		>
			<Row rowGap={spacings[0]} style={{ whiteSpace: 'nowrap' }}>
				{leftIcon && <IconContainer>{leftIcon}</IconContainer>}
				{children}
				{!isLoading && rightIcon && <IconContainer>{rightIcon}</IconContainer>}
				{isLoading && (
					<LoadingIconContainer>
						<SpinnerIcon size={size} />
					</LoadingIconContainer>
				)}
			</Row>
		</StyledButton>
	);
});

export { Button };
