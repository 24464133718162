import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { colors, fonts, rem } from 'styles';

const StyledToastContainer = styled(ToastContainer).attrs({
	className: 'toast-container',
	toastClassName: 'toast',
	bodyClassName: 'body',
	progressClassName: 'progress',
})`
	margin-top: ${rem(80)};
	height: fit-content;
	--toastify-font-family: ${fonts.primary};
	word-break: break-word;
	/* word-wrap: break-word; */

	.toast {
	}
	.body {
	}
	.Toastify__toast--default {
	}
	.Toastify__toast--info {
	}
	.Toastify__toast--success {
	}
	.Toastify__toast--warning {
	}
	.Toastify__toast--error {
		color: ${colors.grey[900]};
		background-color: ${colors.error[100]};
	}

	button[aria-label='close'] {
		display: none;
	}
`;

const Toast = () => (
	<StyledToastContainer
		position={toast.POSITION.TOP_RIGHT}
		autoClose={5000}
		hideProgressBar={true}
		newestOnTop={false}
		closeOnClick
		rtl={false}
		pauseOnFocusLoss={false}
		draggable={false}
		pauseOnHover
	/>
);

export default Toast;
