import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Option } from 'components/Select';
import { AppState } from 'store';

export type TransactionFiltersFormData = {
	assets: Option[] | null;
	tags: Option[] | null;
	transactionType: Option[] | null;
	transactionDirection?: Option[] | null;
	status: Option[] | null;
};

export type TransactionFiltersQueryParams = {
	assets?: string[];
	tags?: string[];
	transactionType?: string[];
	startDate?: string;
	endDate?: string;
	transactionStatus?: string[];
};

type DateRange = { startDate?: number; endDate?: number; key?: string };
export type TransactionsState = {
	appliedFiltersData?: TransactionFiltersFormData;
	dateRangeFilter?: DateRange[];
};

export const initialState: TransactionsState = {
	appliedFiltersData: undefined,
	dateRangeFilter: undefined,
};

const slice = createSlice({
	name: 'transactions/view',
	initialState,
	reducers: {
		setFiltersFormData(
			state,
			action: PayloadAction<{
				formData: TransactionFiltersFormData;
				ranges?: DateRange[];
			}>
		) {
			state.appliedFiltersData = action.payload.formData;
			state.dateRangeFilter = action.payload.ranges;
		},
		resetFilters(state) {
			state.appliedFiltersData = undefined;
			state.dateRangeFilter = undefined;
		},

		reset() {
			return initialState;
		},
	},
});

export const { actions: transactionsActions, reducer: transactionsReducer } =
	slice;

export const selectTransactions = (state: AppState) => state.transactions;
