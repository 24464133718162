import React, { MouseEventHandler, ReactNode } from 'react';
import {
	ButtonSizeType,
	ButtonVariantsType,
	BUTTON_SIZES,
	BUTTON_VARIANTS,
	ColorMapType,
	COLOR_MAP,
	IconButtonSizeType,
} from 'styles/tokens';
import { StyledButtonBase } from './Button.styles';

export interface ButtonBaseProps extends React.ComponentPropsWithRef<'button'> {
	children: ReactNode;
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
	variant?: ButtonVariantsType;
	color?: ColorMapType;
	size?: ButtonSizeType | IconButtonSizeType;
	disabled?: boolean;
	isLoading?: boolean;
}

const ButtonBase = React.forwardRef<
	HTMLButtonElement,
	ButtonBaseProps & React.ComponentPropsWithoutRef<'button'>
>(
	(
		{
			children,
			onClick,
			color,
			variant,
			disabled,
			isLoading = false,
			...otherProps
		},
		ref
	) => {
		return (
			<StyledButtonBase
				ref={ref}
				onClick={onClick}
				color={color}
				variant={variant}
				disabled={disabled}
				isLoading={isLoading}
				{...otherProps}
			>
				{children}
			</StyledButtonBase>
		);
	}
);

ButtonBase.defaultProps = {
	disabled: false,
	onClick: () => null,
	color: COLOR_MAP.primary,
	variant: BUTTON_VARIANTS.filled,
	size: BUTTON_SIZES.lg,
	isLoading: false,
};

export default ButtonBase;
