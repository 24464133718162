import React from 'react';
import { IconProps } from 'utils/types';

const DisconnectIcon = ({ height = '14', width = '14', size }: IconProps) => {
	return (
		<svg
			width={size || width}
			height={size || height}
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_2427_20172)'>
				<path
					d='M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32121C0.003033 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.39971 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73784 14 8.38447 14 7C13.998 5.1441 13.2599 3.36479 11.9475 2.05247C10.6352 0.74015 8.8559 0.0020073 7 0V0ZM7 1.16667C8.34545 1.16559 9.64946 1.63202 10.689 2.48617L2.48617 10.689C1.7865 9.8349 1.3438 8.79965 1.20961 7.70375C1.07542 6.60784 1.25525 5.49636 1.72818 4.49869C2.2011 3.50101 2.94767 2.65818 3.88097 2.06829C4.81427 1.4784 5.89591 1.16573 7 1.16667V1.16667ZM7 12.8333C5.65456 12.8344 4.35055 12.368 3.311 11.5138L11.5138 3.311C12.2135 4.16509 12.6562 5.20034 12.7904 6.29625C12.9246 7.39215 12.7448 8.50363 12.2718 9.50131C11.7989 10.499 11.0523 11.3418 10.119 11.9317C9.18574 12.5216 8.10409 12.8343 7 12.8333Z'
					fill='#EF4839'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2427_20172'>
					<rect width='14' height='14' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default DisconnectIcon;
