import Checkbox from 'components/Checkbox';
import Row from 'components/Row';
import React from 'react';
import { useTheme } from 'styled-components';
import FormElementContainer from '../FormElementContainer';
import { DefaultFormFieldProps } from '../types';

export type CheckboxInputProps = React.ComponentPropsWithoutRef<'input'> &
	DefaultFormFieldProps;

const CheckboxInput = React.forwardRef<HTMLInputElement, CheckboxInputProps>(
	(props, ref) => {
		const { spacings } = useTheme();
		const {
			name,
			error,
			label,
			suggestion,
			hint,
			style,
			width,
			children,
			...rest
		} = props;

		return (
			<>
				<FormElementContainer
					label={label}
					error={error}
					suggestion={suggestion}
					name={name}
					hint={hint}
					width={width}
					style={
						style
							? style
							: { width: 'auto', minWidth: 'auto', maxWidth: 'auto' }
					}
				>
					<Row rowGap={spacings[10]}>
						<Checkbox ref={ref} name={name} {...rest} />
						{children}
					</Row>
				</FormElementContainer>
			</>
		);
	}
);

export default CheckboxInput;
