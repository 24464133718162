import { ModalControlProps } from 'common/modal';
import { StepperProvider } from 'components/Stepper/useStepper';

export const withSteps =
	<T extends object>(
		WrappedComponent: React.ComponentType<T>
	): React.FC<T & ModalControlProps> =>
	(props) =>
		(
			<StepperProvider>
				<WrappedComponent {...(props as T)} />
			</StepperProvider>
		);
