import { rem } from 'styles';
import { MODAL_SIZES } from 'styles/tokens';

export type ModalSizeType = keyof typeof MODAL_SIZES;

export const getModalSize = (size: ModalSizeType) => {
	switch (size) {
		case MODAL_SIZES.sm:
			return rem(480);
		case MODAL_SIZES.md:
			return rem(660);
		case MODAL_SIZES.lg:
			return rem(1000);
		default:
			return rem(660);
	}
};
