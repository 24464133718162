import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import coinbaseWalletModule from '@web3-onboard/coinbase';
import type {
	ConnectOptions,
	ConnectedChain,
	DisconnectOptions,
	WalletState,
} from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import ledgerModule from '@web3-onboard/ledger';
import { init, useConnectWallet, useSetChain } from '@web3-onboard/react';
import trezorModule from '@web3-onboard/trezor';
import walletConnectModule from '@web3-onboard/walletconnect';
import { NETWORK_DETAILS_BY_ID } from 'constants/networks';
import web3Onboardi18n from 'constants/web3Onboardi18n';
import { ReactNode, createContext, useContext, useEffect } from 'react';

type Web3OnboardContextType = {
	connectWallet: (options: ConnectOptions) => Promise<void>;
	disconnectWallet: (wallet: DisconnectOptions) => Promise<void>;
	provider: Web3Provider | undefined;
	account: string | undefined;
	connectedChain: ConnectedChain | null;
	chainId: string | undefined;
	connectedWallet: WalletState | null;
	connecting: boolean;
	settingChain: boolean;
	setChain: (options: { chainId: string }) => Promise<boolean>;
};

export const Web3OnboardContext = createContext({} as Web3OnboardContextType);

type Props = {
	children: ReactNode;
};
const injected = injectedModule();
const ledger = ledgerModule();
const trezorOptions = {
	email: 'support@multisafe.finance',
	appUrl: 'https://www.coinshift.xyz',
};
const trezor = trezorModule(trezorOptions);
const walletConnect = walletConnectModule();
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: false });

const initChains = Object.values(NETWORK_DETAILS_BY_ID).map(
	({ name, chainId, rpc, chain }) => ({
		id: `0x${Number(chainId).toString(16)}`,
		token: chain,
		label: name,
		rpcUrl: rpc[0],
	})
);

init({
	wallets: [injected, walletConnect, ledger, trezor, coinbaseWalletSdk],
	chains: initChains,
	accountCenter: { desktop: { enabled: false } },
	appMetadata: {
		name: 'Coinshift',
		icon: `<svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_8_29)">
		<path d="M10.0817 15C10.0817 16.7771 10.8011 18.4814 12.0818 19.7381C13.3624 20.9948 15.0994 21.7009 16.9107 21.7012V29.2402C16.9108 29.427 16.8382 29.6069 16.7078 29.743C16.5774 29.8791 16.399 29.9614 16.2089 29.9729C15.9058 29.991 15.5981 30 15.287 30C11.2322 29.9995 7.3436 28.4188 4.47674 25.6054C1.60987 22.792 -0.00045835 18.9766 9.78606e-08 14.9983C0.000458546 11.0201 1.61167 7.20493 4.47918 4.3922C7.34669 1.57947 11.2356 -0.000449695 15.2904 9.60127e-08C15.5981 9.60127e-08 15.9058 0.00904509 16.2123 0.0271351C16.4024 0.0386498 16.5808 0.120859 16.7112 0.256998C16.8417 0.393137 16.9142 0.572954 16.9142 0.75978V8.29879C16.017 8.29849 15.1286 8.4716 14.2996 8.80824C13.4707 9.14487 12.7175 9.63843 12.083 10.2607C11.4485 10.883 10.9452 11.6219 10.6018 12.435C10.2584 13.2482 10.0817 14.1198 10.0817 15Z" fill="#1452F5"/>
		<path d="M26.3142 23.7053C26.4076 23.573 26.4998 23.4384 26.5897 23.3028C26.6768 23.1643 26.722 23.0042 26.7199 22.8415V7.15631C26.7219 6.9939 26.6767 6.8343 26.5897 6.69614C26.4998 6.56047 26.4076 6.42592 26.3142 6.29251C25.9524 5.78373 25.2771 6.09691 25.2771 6.77529V23.2236C25.2759 23.902 25.9524 24.2152 26.3142 23.7053Z" fill="#1452F5"/>
		<path d="M28.6616 11.5326V18.4769C28.6616 19.3181 29.5178 19.4945 29.6988 18.694C30.2412 16.2633 30.2412 13.7462 29.6988 11.3155C29.5178 10.5071 28.6616 10.6869 28.6616 11.5326Z" fill="#1452F5"/>
		<path d="M21.8899 2.73325V27.2678C21.8899 27.8083 22.3451 28.1644 22.738 27.9304L23.0077 27.7653C23.1131 27.6907 23.1981 27.5917 23.255 27.4771C23.3119 27.3625 23.339 27.2358 23.3338 27.1084V2.89267C23.339 2.76527 23.3119 2.63861 23.255 2.52398C23.1981 2.40936 23.1131 2.31035 23.0077 2.23578C22.919 2.17925 22.8279 2.12271 22.738 2.07071C22.3451 1.83667 21.8899 2.19281 21.8899 2.73325Z" fill="#1452F5"/>
		<path d="M18.5044 1.03265V28.9681C18.5044 29.4204 18.834 29.7675 19.1958 29.6929L19.4678 29.6329C19.7478 29.5696 19.9495 29.2644 19.9495 28.9116V1.08918C19.9495 0.736422 19.7478 0.431153 19.4678 0.367838C19.3768 0.346356 19.2869 0.327136 19.1958 0.307915C18.834 0.233294 18.5044 0.578135 18.5044 1.03265Z" fill="#1452F5"/>
		</g>
		<defs>
		<clipPath id="clip0_8_29">
		<rect width="31" height="30" fill="white"/>
		</clipPath>
		</defs>
		</svg>
		`,
		description: 'Treasury Management for Crypto Organisations',
		recommendedInjectedWallets: [
			{ name: 'MetaMask', url: 'https://metamask.io' },
			{ name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
		],
	},
	i18n: web3Onboardi18n,
});

export const Web3OnboardProvider = ({ children }: Props) => {
	const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
	const [{ connectedChain, settingChain }, setChain] = useSetChain();
	// const connectedWallets = useWallets();
	useEffect(() => {
		if (wallet) window.localStorage.setItem('connectedWallet', wallet.label);
	}, [wallet]);

	useEffect(() => {
		const previouslyConnectedWallet =
			window.localStorage.getItem('connectedWallet');

		if (previouslyConnectedWallet && previouslyConnectedWallet === 'MetaMask') {
			connect({
				autoSelect: { label: previouslyConnectedWallet, disableModals: true },
			});
		}
	}, [connect]);

	return (
		<Web3OnboardContext.Provider
			value={{
				connectWallet: connect,
				disconnectWallet: disconnect,
				provider:
					wallet?.provider &&
					new Web3Provider(wallet.provider as ExternalProvider, 'any'),
				account: wallet?.accounts[0]?.address,
				connectedWallet: wallet,
				connectedChain,
				connecting,
				setChain,
				settingChain,
				chainId: connectedChain ? String(parseInt(connectedChain.id, 16)) : '',
			}}
		>
			{children}
		</Web3OnboardContext.Provider>
	);
};

export const useWeb3Onboard = () => {
	return useContext(Web3OnboardContext);
};
