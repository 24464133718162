import { FieldSizes } from 'components/Form/types';
import ChevronDownIcon from 'icons/ChevronDownIcon';
import CrossIconSmall from 'icons/CrossIconSmall';
import React from 'react';
import Select, { ClearIndicatorProps, GroupBase, Props } from 'react-select';
import { useTheme } from 'styled-components';
import { rem } from 'styles';

declare module 'react-select/dist/declarations/src/Select' {
	export interface Props<
		Option,
		IsMulti extends boolean = false,
		Group extends GroupBase<Option> = GroupBase<Option>
	> {
		width?: string;
		minWidth?: string;
		minOptionWidth?: string;
		size?: FieldSizes;
		ignore?: IsMulti | Group;
	}
}

const DropdownIndicator = () => {
	const { colors } = useTheme();
	return <ChevronDownIcon color={colors.grey[900]} />;
};

const ClearIndicator = (props: ClearIndicatorProps) => {
	const { colors, spacings } = useTheme();

	const {
		innerProps: { ref, ...restInnerProps },
	} = props;
	return (
		<div {...restInnerProps} ref={ref} style={{ padding: `0 ${spacings[8]}` }}>
			<CrossIconSmall color={colors.grey[500]} size={spacings[18]} />
		</div>
	);
};
export type SelectOption = {
	value: any;
	label: any;
};

const CustomSelect = React.forwardRef<any, Props>((props, ref) => {
	const {
		components,
		minOptionWidth,
		styles,
		size,
		isDisabled,
		...otherProps
	} = props;
	const { fonts, spacings, colors } = useTheme();

	return (
		<Select
			isDisabled={isDisabled}
			components={{
				IndicatorSeparator: () => null,
				DropdownIndicator,
				ClearIndicator,
				...components,
			}}
			ref={ref}
			styles={{
				menu: (provided, { selectProps }) => {
					return {
						...provided,
						fontFamily: fonts.primary,
						boxSizing: 'border-box',
						border: `${spacings[1]} solid ${colors.primary[100]}`,
						boxShadow: `${spacings[20]} ${spacings[0]} ${spacings[40]} rgba(3,153,186,0.1)`,
						maxWidth: rem(440),
						minWidth: selectProps.width || rem(440),
						width: selectProps.width || '100%',
						margin: `${spacings[10]} 0`,
						...styles?.menu,
						zIndex: 9999,
					};
				},
				indicatorsContainer: (provided) => {
					return {
						...provided,
						...styles?.indicatorsContainer,
					};
				},
				menuList: (provided, { selectProps }) => {
					return {
						...provided,
						fontFamily: fonts.primary,
						paddingTop: 0,
						paddingBottom: 0,
						paddingRight: 0,
						maxHeight: selectProps.maxMenuHeight || rem(250),
						overflowX: 'hidden',
						...styles?.menuList,
					};
				},
				option: (provided) => {
					return {
						...provided,
						fontFamily: fonts.primary,
						paddingLeft: spacings[20],
						paddingRight: spacings[20],
						display: 'flex',
						alignItems: 'center',
						height: spacings[size === 'md' ? 40 : 50],
						fontSize: spacings[size === 'md' ? 14 : 16],
						backgroundColor: colors.white[100],
						color: colors.grey[900],
						':hover,:focus': {
							backgroundColor: colors.primary[100],
							cursor: 'pointer',
						},
						textAlign: 'initial',
						'&:last-of-type': {
							marginBottom: 0,
						},
						minWidth: minOptionWidth || rem(440),
						width: 'auto',
						...styles?.option,
					};
				},
				valueContainer: (provided) => {
					return {
						...provided,
						padding: 0,
						textAlign: 'initial',
						...styles?.valueContainer,
					};
				},
				control: (provided, { selectProps }) => {
					return {
						...provided,
						boxSizing: 'border-box',
						':hover,:focus-within': {
							boxShadow: 'none',
							border: `${spacings[1]} solid ${colors.primary[900]}`,
							cursor: 'pointer',
						},
						minHeight: spacings[size === 'md' ? 40 : 50],
						padding: `${spacings[size === 'md' ? 2 : 4]} ${
							spacings[size === 'md' ? 16 : 20]
						}`,
						width: selectProps.width || '100%',
						border: `${spacings[1]} solid ${colors.primary[100]}`,
						fontSize: spacings[size === 'md' ? 14 : 16],
						...styles?.control,
						backgroundColor: isDisabled ? colors.grey[100] : colors.white[100],
					};
				},
				input: (provided) => {
					return { ...provided, padding: 0, margin: 0, ...styles?.input };
				},
				placeholder: (provided) => {
					return {
						...provided,
						color: colors.grey[500],
						...styles?.placeholder,
						fontSize: size === 'md' ? spacings[14] : spacings[16],
					};
				},
				menuPortal: (provided) => {
					return { ...provided, zIndex: 9999 };
				},
				noOptionsMessage: (provided) => {
					return {
						...provided,
						minHeight: spacings[50],
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					};
				},
				loadingMessage: (provided) => {
					return {
						...provided,
						minHeight: spacings[50],
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					};
				},
				...styles,
			}}
			{...otherProps}
		/>
	);
});

export default CustomSelect;
