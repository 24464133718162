import { useAppDispatch, useAppSelector } from 'hooks';
import React from 'react';
import { modalActions, selectModal } from './Modal.slice';

const connectModal =
	(modal: string) =>
	(
		WrappedComponent: React.ComponentType<{
			isOpen: boolean;
			hideModal: () => void;
		}>
	) =>
	(props: any) => {
		const modalProps = useAppSelector(selectModal);
		const dispatch = useAppDispatch();
		const { children, ...otherProps } = props;
		const isOpen = modalProps[modal]?.show || false;
		const injectedProps = modalProps[modal]?.props || {};

		return (
			<WrappedComponent
				isOpen={isOpen}
				hideModal={() => dispatch(modalActions.hide(modal))}
				{...otherProps}
				{...injectedProps}
			>
				{children}
			</WrappedComponent>
		);
	};

export default connectModal;
